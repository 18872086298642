<template>
  <v-card
    class="mx-auto"
    style="border:2px solid #f4c020"
    width="300"
    dark
  >
    <v-card-text class="title-card">
      {{ title }}
      <v-icon
        :color="color"
        :size="iconClass"
        class="status-icon"
      >
        {{ icon }}
      </v-icon>
    </v-card-text>

    <v-card-text class="value-card">
      {{ value }}
    </v-card-text>
    <v-row>
      <v-col cols="10">
        <div v-if="assignedClass !== '-'">
          <v-checkbox
            v-model="check"
            hide-details
            class="yellow-check"
            @click="clickCheck(assignedClass,check)"
          />
        </div>
        <div v-else>
          <v-checkbox
            v-model="check"
            hide-details
            class="hide-check"
          />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TIAMapKpisCard',

  components: {

  },
  props: {
    title: {
      type: Text,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    color: {
      type: Text,
      default: ''
    },
    assignedClass: {
      type: Text,
      default: '-'
    },
    iconClass: {
      type: Text,
      default: '-'
    },
    icon: {
      type: Text,
      default: '-'
    }
  },
  data: () => ({
    check: true
  }),

  computed: {
    ...mapState({
      activatedStations: (state) => state.TIA.TIAMap.activatedStations
    })
  },

  watch: {
    // whenever question changes, this function will run
    activatedStations () {
      this.check = this.activatedStations
    }
  },
  methods: {
    clickIcon (station) {
    },
    clickCheck (assignedClass, value) {
      console.log(assignedClass, value)

      if (assignedClass === 'circle-status-all') {
        const classNamesHS = [
          'icon-green-green', 'icon-green-yellow', 'icon-green-red',
          'icon-yellow-green', 'icon-yellow-yellow', 'icon-yellow-red',
          'icon-red-green', 'icon-red-yellow', 'icon-red-red'
        ]
        this.hideElementsByClasses(classNamesHS, value, true)
      } else if (assignedClass === 'circle-status-forecast-green') {
        const classNamesHS = ['icon-green-green', 'icon-yellow-green', 'icon-red-green']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-forecast-yellow') {
        const classNamesHS = ['icon-green-yellow', 'icon-yellow-yellow', 'icon-red-yellow']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-forecast-red') {
        const classNamesHS = ['icon-green-red', 'icon-yellow-red', 'icon-red-red']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-green') {
        const classNamesHS = ['icon-green-green', 'icon-green-yellow', 'icon-green-red']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-yellow') {
        const classNamesHS = ['icon-yellow-green', 'icon-yellow-yellow', 'icon-yellow-red']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-red') {
        const classNamesHS = ['icon-red-green', 'icon-red-yellow', 'icon-red-red']
        this.hideElementsByClasses(classNamesHS, value)
      }
    },

    hideElementsByClass (className, value) {
      const elements = document.querySelectorAll(`.${className}`)
      if (value) {
        elements.forEach(element => {
          element.style.display = ''
        })
      } else {
        elements.forEach(element => {
          element.style.display = 'none'
        })
      }
    },
    hideElementsByClasses (classNames, value, allStations) {
      classNames.forEach(className => {
        const elements = document.querySelectorAll(`.${className}`)
        if (value) {
          if (allStations) {
            this.$store.dispatch('setElement', { path: 'TIA.TIAMap.activatedStations', value: true })
          }

          elements.forEach(element => {
            element.style.display = ''
          })
        } else {
          if (allStations) {
            this.$store.dispatch('setElement', { path: 'TIA.TIAMap.activatedStations', value: false })
          }
          elements.forEach(element => {
            element.style.display = 'none'
          })
        }
      })
    }
  }
}
</script>

<style >
.custom-icon {
  font-size: 32px;
  color: red;
  /* O cualquier otro estilo que desees */
}
.title-card{
  margin-top:15px;
  font-size:17px;
  font-weight: bold;
  color:#f4c020!important;
}
.value-card{
  font-size:50px;
  font-weight: bold;
  display: flex;
  justify-content: center; /* Alineación horizontal al centro */
  align-items: center; /* Alineación vertical al centro */
  height: 8vh;
}
.status-icon{
  margin-left: 10px;
}
.yellow-check{
   color:#f4c020!important;
   margin-right: 10px;
}
.hide-check{
   color:#f4c020!important;
   margin-left: -50px;
}
</style>
