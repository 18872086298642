<template>
  <div>
    <LPolyline
      v-for="fuse in fuses"
      :key="fuse.ID"
      :lat-lngs="fuse.latLngs"
      :color="setFusesColor(fuse.NETWORK, fuseColor)"
      :class-name="'fuse' + fuse.ID + ' polyline'"
      @mouseover="mouseover($event.target, fuse.ID, 'fuse')"
      @mouseleave="mouseleave($event.target, fuse.ID, 'fuse')"
      @click="click(level, undefined, fuse.ID, 'fuse', false, hostingCapacityMap, multiselect)"
      @contextmenu="handleContextmenu($event, fuse)"
    />
  </div>
</template>

<script>
import { LPolyline } from 'vue2-leaflet'
import proj4 from 'proj4'
import * as turf from '@turf/turf'
import click from '@/mixins/map/mapElementClickMixin'
import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import mouseover from '@/mixins/map/mapElementMouseoverMixin'
import mouseleave from '@/mixins/map/mapElementMouseleaveMixin'
import arraysMixin from '@/mixins/arraysMixin'
import mapElementOpacityMixin from '@/mixins/map/mapElementOpacityMixin'
import mapElementColorMixin from '@/mixins/map/mapElementColorMixin'

export default {
  name: 'FuseLayer',
  components: {
    LPolyline
  },
  mixins: [
    click,
    clickElements,
    unclickElements,
    mouseover,
    mouseleave,
    arraysMixin,
    mapElementOpacityMixin,
    mapElementColorMixin
  ],
  props: {
    fusesData: {
      type: Array,
      required: true
    },
    colorStation: {
      type: String,
      required: false,
      default: '#fff'
    },
    color: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    contextMenu: {
      type: Boolean,
      default: false
    },
    level: {
      type: String,
      default: ''
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      fuseColor: this.color,
      blackColor: '#000000',
      whiteColor: '#ffffff'
    }
  },
  computed: {
    fuses () {
      const FusesUpdated = this.fusesData
      // Initializing proj4 parameters
      this.fusesData.forEach((element, index) => {
        const punto1 = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [element.X1, element.Y1])
        const punto2 = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [element.X2, element.Y2])
        const punto1Turf = turf.point([punto1[0], punto1[1]])
        const punto2Turf = turf.point([punto2[0], punto2[1]])
        const bearing = turf.bearing(punto1Turf, punto2Turf)
        const perpendicular = bearing + 90
        const distance = 0.2
        const distanceNegative = -0.2
        const point1 = turf.destination(
          punto1,
          distanceNegative,
          perpendicular,
          {
            units: 'meters'
          }
        )
        const point2 = turf.destination(punto1, distance, perpendicular, {
          units: 'meters'
        })
        const point3 = turf.destination(punto2, distance, perpendicular, {
          units: 'meters'
        })
        const point4 = turf.destination(
          punto2,
          distanceNegative,
          perpendicular,
          {
            units: 'meters'
          }
        )
        const latLngs = [
          [punto1[1], punto1[0]],
          [point1.geometry.coordinates[1], point1.geometry.coordinates[0]],
          [point4.geometry.coordinates[1], point4.geometry.coordinates[0]],
          [punto2[1], punto2[0]],
          [punto1[1], punto1[0]],
          [point2.geometry.coordinates[1], point2.geometry.coordinates[0]],
          [point3.geometry.coordinates[1], point3.geometry.coordinates[0]],
          [punto2[1], punto2[0]]
        ]
        FusesUpdated[index].latLngs = latLngs
      })
      return this.removeDuplicates(FusesUpdated, 'ID')
    }
  },
  watch: {
    colorStation (newColor) {
      if (newColor === '#f4c020') {
        this.fuseColor = '#f4c020'
      } else {
        this.fuseColor = this.color
      }
    }
  },
  methods: {
    handleContextmenu (e, fuse) {
      if (this.contextMenu) {
        const contextMenuPayload = {
          x: e.containerPoint.x + 75,
          y: e.containerPoint.y + 75,
          toggle: 'contextMenu'
        }

        const fusePayload = {
          path: 'contextMenuElement',
          value: { type: 'contextMenuFuse', info: fuse }
        }

        this.$store.dispatch('setClick', { lat: e.latlng.lat, lng: e.latlng.lng, idElement: fuse.ID  })
        this.$store.dispatch('setNewPosition', contextMenuPayload)
        this.$store.dispatch('setElement', fusePayload)
      }
    }
  }
}
</script>
