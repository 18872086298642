<template>
  <v-form
    v-if="headers"
    ref="newProjectForm"
    v-model="valid"
    lazy-validation
  >
    <div v-if="!sent">
      <v-row
        align="center"
        align-content="center"
        justify="center"
        style="padding: 0px 20px"
      >
        <v-col
          cols="12"
          self-align="center"
          style="margin-top:30px"
          class="form-text"
        >
          <v-autocomplete
            v-model="refValue"
            dark
            dense
            :items="refHeaders"
            :label="$t('importer.reference_column')"
          />
        </v-col>
        <v-col
          cols="12"
          self-align="center"
          class="form-text"
        >
          <v-autocomplete
            v-model="timeValue"
            dark
            dense
            :items="timeHeaders"
            :label="$t('importer.time_column')"
          />
        </v-col>
        <div v-if="refValue !== null && timeValue !== null">
          <v-row
            v-for="(measurement,index) in measurementsHeadersAvailable"
            :key="index"
          >
            <v-col
              cols="6"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="signalsValues[index]"
                dark
                dense
                :items="signalsHeaders[index]"
                :label="$t('importer.signal') "
                item-text="text"
                item-value="value"
              />
            </v-col>
            <v-col
              cols="6"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="measurementsValues[index]"
                dark
                dense
                :items="measurementsHeaders[index]"
                :label="$t('importer.measurement_column')"
              />
            </v-col>
          </v-row>
        </div>
        <v-col
          cols="12"
          self-align="center"
        >
          <CMLoader
            v-if="uploading"
            :loading="uploading"
            :message="$t('importer.parsing_file')"
            :overlay="false"
          />
          <v-btn
            v-else
            color="#f4c020"
            text
            style="margin:20px"
            :disabled="!formReady"
            @click="uploadMeasurementsFile"
          >
            {{ $t('importer.load_file') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <CMError
        v-if="hasError"
        :title="error.title"
        :text="error.text"
        :code="error.code"
        @hideError="hideError"
      />
      <v-row
        align="center"
        align-content="center"
        justify="center"
      >
        <v-col
          cols="12"
          self-align="center"
          style="margin:30px 10px 0px 10px"
          class="form-text"
        >
          <h5>{{ $t('importer.measurement_file_sent') }}</h5>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>

import { getMeasurements } from '@/services/measurements'
import { post } from '@/assets/queries'

export default {
  name: 'MeasurementsDataUpload',

  components: {
    CMLoader: () => import('@/components/Common/CMLoader.vue'),
    CMError: () => import('@/components/Common/CMError.vue')
  },

  props: {
    headers: {
      type: Array,
      default: null
    },
    content: {
      type: Object,
      default: null
    },
    parsed: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: File,
      default: null
    },
    fileTitle: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      valid: true,
      sent: false,
      refValue: null,
      timeValue: null,
      measurementsValues: [this.headers.length - 2],
      signals: null,
      signalsValues: [this.headers.length - 2],
      signalsReady: false,
      formReady: false,
      responsetype: null,
      responsecolor: null,
      responsemessage: null,
      error: {
        title: '',
        status: '',
        code: null
      },
      hasError: false,
      uploading: false
    }
  },

  computed: {
    refHeaders () {
      return this.headers.filter(header => header !== this.timeValue && !this.measurementsValues.includes(header))
    },
    timeHeaders () {
      return this.headers.filter(header => header !== this.refValue && !this.measurementsValues.includes(header))
    },
    measurementsHeadersAvailable () {
      return this.headers.filter(header => header !== this.refValue && header !== this.timeValue)
    },
    measurementsHeaders () {
      const measurements = new Array(this.headers.length - 2)
      for (let i = 0; i < this.headers.length - 2; i += 1) {
        measurements[i] = this.measurementsHeadersAvailable
      }
      this.measurementsValues.forEach((value, vindex) => {
        if (value !== null) {
          for (let i = 0; i < this.headers.length - 2; i += 1) {
            if (vindex !== i) {
              measurements[i] = measurements[i].filter(header => header !== value)
            }
          }
        }
      })
      return measurements
    },
    signalsHeaders () {
      const signals = new Array(this.headers.length - 2)
      for (let i = 0; i < this.headers.length - 2; i += 1) {
        signals[i] = this.signals
      }
      this.signalsValues.forEach((value, vindex) => {
        if (value !== null) {
          for (let i = 0; i < this.headers.length - 2; i += 1) {
            if (vindex !== i) {
              signals[i] = signals[i].filter(header => header.value !== value)
            }
          }
        }
      })
      return signals
    }
  },

  watch: {
    refValue () {
      this.checkFormReady()
    },
    timeValue () {
      this.checkFormReady()
    },
    measurementsValues () {
      this.checkFormReady()
    }
  },

  async mounted () {
    this.signals = await getMeasurements()
    this.signalsReady = true
    this.signalsValues[0] = null
  },

  methods: {
    checkFormReady () {
      const measurementisSelected = this.measurementsValues.filter(measurement => measurement !== null)
      const signalisSelected = this.signalsValues.filter(signal => signal !== null)
      if (this.refValue !== null && this.timeValue !== null && measurementisSelected.length > 0 && signalisSelected.length > 0) {
        this.formReady = true
      } else {
        this.formReady = false
      }
    },
    async uploadMeasurementsFile () {
      this.uploading = true
      const formdata = new FormData()
      formdata.append('file', this.fileData, this.fileTitle)
      formdata.append('timestamp', this.timeValue)
      formdata.append('reference', this.refValue)
      formdata.append('measurement', this.measurementsValues.toString())
      formdata.append('codes', this.signalsValues.toString())

      const myHeaders = new Headers()

      const response = await post('https://labs.plexigrid.app/importer/measurement', myHeaders, formdata)
      this.sent = true

      if (!response.ok) {
        this.hasError = true
        this.error.title = 'Error'
        this.error.text = response.detail
        this.error.code = response.status
      }
      this.uploading = false
    },
    hideError () {
      this.hasError = false
      location.reload()
    }
  }
}
</script>
<style scoped>
.form-text{
  color:#cccccc; font-family:'Faktum Light', sans-serif;
}
</style>
