import { plexiRequest } from '.'

export const getStations = async () =>
  plexiRequest({ url: 'tia/map/stations/' })

export const getNetwork = async () =>
  plexiRequest({ url: 'tia/map/network/' })

export const getMonitorStation = async (uuid) =>
  plexiRequest({ url: `tia/monitor/stations/${uuid}` })

export const getMonitorTransformerPlot = async (uuid, timeStart, timeEnd) =>
  // plexiRequest({ url: `tia/monitor/transformer/series/${uuid}?TIME_START=${timeStart}&TIME_END=${timeEnd}` })
  plexiRequest({ url: `tia/monitor/transformer/series/${uuid}?TIME_START=${timeStart}&TIME_END=${timeEnd}` })

export const getMonitorFSPPlot = async (uuid, timeStart, timeEnd, FSP) =>
  plexiRequest({ url: `tia/monitor/fsp/series/${uuid}?TIME_START=${timeStart}&TIME_END=${timeEnd}&FSP=${FSP}` })

export const getMonitorLogs = async (uuid, period) =>
  plexiRequest({ url: `tia/monitor/transformer/logs/${uuid}?PERIOD=${period}` })

export const getMonitorSolutions = async (uuid, period, FSP, id) =>
  plexiRequest({ url: `tia/monitor/fsp/logs/${uuid}?PERIOD=${period}&FSP=${FSP}&ID=${id}` })

export const changeStationFlex = async (uuid, value) =>
  plexiRequest({
    url: `tia/map/stations/update_flex_status/${uuid}?is_flex=${value}`,
    method: 'get'
  })
