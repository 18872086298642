import { plexiRequest } from '.'

/* export const loadTopologyFile = async (method, headers, body, redirect) =>
  (method && headers && body && redirect) &&
  plexiRequest({ url: `/ari/grid?elementType=${element.type}&elementId=${element.id}&r=${element.radius}` }) */

export const getDDBB = () =>
  plexiRequest({ url: '/measurements/db' })
    .then(data => data.map(DB => ({
      text: DB.dbname,
      value: DB.id
    })))

export const getDBFields = async (DB) =>
  DB && plexiRequest({ url: `/importer/event/fields?db=${DB}` })
