<template>
  <div>
    <!--Medium voltage allways enters here-->
    <div v-if="level > 400">
      <LineLayer
        :lines-data="lines"
        :color-station="color"
        :digital-twin-map="digitalTwinMap"
        :context-menu="lineContextMenu"
        :hosting-capacity-map="hostingCapacityMap"
        :mode="mode"
        :multiselect="multiselect"
        :is-hovered="isHovered"
        :level="level"
        :tooltip-text="tooltipText"
        @hover="hover"
        @leave="leave"
        @click="click"
      />
      <ConnectionPointLayer
        :connection-points-data="connection_points"
        :color-station="color"
        :mode="mode"
        :multiselect="multiselect"
        :context-menu="connectionPointContextMenu"
        :hcm-context-menu="connectionPointHCMContextMenu"
        :hc-phase-index="hcPhaseIndex"
        :hosting-capacity-map="hostingCapacityMap"
        :hc-zoom="hcZoom"
        :selected-type-view-map="selectedTypeViewMap"
        :tooltip-text="tooltipText"
        :not-available-h-c="notAvailableHC"
        @hover="hover"
        @leave="leave"
        @click="click"
        @onHCReservationClick="onHCReservationClick"
      />
      <StationFuseLayer
        :fuses-data="station_closed_fuses"
        :color-station="color"
        :color="colorClosedFuse"
        :mode="mode"
        :multiselect="multiselect"
        :context-menu="fuseContextMenu"
        :hosting-capacity-map="hostingCapacityMap"
        :tooltip-text="tooltipText"
        @hover="hover"
        @leave="leave"
        @click="click"
      />
    </div>
    <!--Low valtage enters here-->
    <div v-else>
      <div v-if="zoom >= 10">
        <LineLayer
          :lines-data="lines"
          :color-station="color"
          :digital-twin-map="digitalTwinMap"
          :context-menu="lineContextMenu"
          :hcm-context-menu="connectionPointHCMContextMenu"
          :hosting-capacity-map="hostingCapacityMap"
          :hc-zoom="hcZoom"
          :selected-type-view-map="selectedTypeViewMap"
          :tooltip-text="tooltipText"
          :mode="mode"
          :multiselect="multiselect"
          :is-hovered="isHovered"
          :level="level"
          @hover="hover"
          @leave="leave"
          @click="click"
        />
        <ConnectionPointLayer
          :connection-points-data="connection_points"
          :color-station="color"
          :hcm-context-menu="connectionPointHCMContextMenu"
          :hc-phase-index="hcPhaseIndex"
          :hosting-capacity-map="hostingCapacityMap"
          :hc-zoom="hcZoom"
          :selected-type-view-map="selectedTypeViewMap"
          :tooltip-text="tooltipText"
          :not-available-h-c="notAvailableHC"
          :mode="mode"
          :multiselect="multiselect"
          :context-menu="connectionPointContextMenu"
          :level="level"
          @hover="hover"
          @leave="leave"
          @click="click"
          @onHCReservationClick="onHCReservationClick"
        />
        <StationFuseLayer
          v-if="drawStationFuse"
          :fuses-data="station_closed_fuses"
          :color-station="color"
          :color="colorClosedFuse"
          :mode="mode"
          :multiselect="multiselect"
          :context-menu="fuseContextMenu"
          :level="level"
          @hover="hover"
          @leave="leave"
          @click="click"
        />
        <FuseLayer
          :fuses-data="network_closed_fuses"
          :color-station="color"
          :color="colorClosedFuse"
          :mode="mode"
          :multiselect="multiselect"
          :context-menu="fuseContextMenu"
          :level="level"
          @hover="hover"
          @leave="leave"
          @click="click"
        />
      </div>
    </div>
  </div>
</template>

<script>

import LineLayer from '@/components/Map/LineLayer.vue'
import ConnectionPointLayer from '@/components/Map/ConnectionPointLayer.vue'
import StationFuseLayer from '@/components/Map/StationFuseLayer.vue'
import FuseLayer from '@/components/Map/FuseLayer.vue'

export default {
  name: 'FullNetwork',
  components: {
    LineLayer,
    ConnectionPointLayer,
    StationFuseLayer,
    FuseLayer
  },
  props: {
    update: {
      type: Number,
      default: 0
    },
    networkData: {
      type: Object,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    connectionPointContextMenu: {
      type: Boolean,
      default: false
    },
    connectionPointHCMContextMenu: {
      type: Boolean,
      default: false
    },
    hcPhaseIndex: {
      type: Number,
      default: null
    },
    fuseContextMenu: {
      type: Boolean,
      default: false
    },
    stationContextMenu: {
      type: Boolean,
      default: false
    },
    lineContextMenu: {
      type: Boolean,
      default: false
    },
    digitalTwinMap: {
      type: Boolean,
      default: false
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    zoom: {
      type: Number,
      default: 0
    },
    level: {
      type: String,
      default: ''
    },
    hcZoom: {
      type: Number,
      default: 0
    },
    selectedTypeViewMap: {
      type: [Array, Number, String],
      default: () => []
    }
  },
  data () {
    return {
      colorClosedFuse: '#d40000',
      colorOpenFuse: '#00d400',
      colorCP: '#F7FF00',
      isHovered: false,
      drawStationFuse: false
    }
  },
  computed: {
    station () {
      return this.networkData.network
    },
    lines () {
      return this.networkData.lines
    },
    connection_points () {
      return this.networkData.connection_points
    },
    network_closed_fuses () {
      return this.networkData.closed_fuses
    },
    color () {
      return this.networkData.color
    },
    tooltipText () {
      return this.networkData.tooltipText
    },
    notAvailableHC () {
      return this.networkData.notAvailableHC
    }
  },

  methods: {
    hover (level, network) {
      // only reaches when network mode is on
      if (this.digitalTwinMap || this.hostingCapacityMap) {
        this.isHovered = true
      } else {
        level && this.$emit('hover', level, network)
      }
    },
    leave (level, network) {
      if (this.digitalTwinMap || this.hostingCapacityMap) {
        this.isHovered = false
      } else {
        level && this.$emit('leave', level, network)
      }
    },
    click (level, network, elementId, elementType) {
      this.$emit('click', level, network, elementId, elementType)
    },
    onHCReservationClick (network) {
      this.$emit('onHCReservationClick', network)
    }
  }
}
</script>
<style>
.polyline-selected{
    stroke:#f4c020
}
.circle-selected{
    stroke:#f4c020;
    fill:#f4c020;
}
.polylineDigitalTwin-selected{
    stroke-width: 6px;
}
</style>
