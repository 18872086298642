import proj4 from 'proj4'
import * as turf from '@turf/turf'

const getNetworksObject = {

  methods: {
    getNetworksObject (response) {
      const networks = []
      const list = {
        networks: [], stations: [], lines: [], fuses: [], connectionPoints: []
      }
      const stationColors = [
        '#b4d8e5',
        '#d4bdc5',
        '#ef968f',
        '#e8b293',
        '#e791f0',
        '#2df4c2',
        '#da7fe4',
        '#8de8d1',
        '#889bd7',
        '#7f94f1',
        '#e4e6f9',
        '#62d5be',
        '#8fa3e0',
        '#e472f0',
        '#38f5ca',
        '#959dc1',
        '#ea9ef2',
        '#b4fae8',
        '#7685b8',
        '#84cce9',
        '#d4bdc5',
        '#ddcad0',
        '#f3aea8',
        '#f6c1be',
        '#ebbea4',
        '#efccb8',
        '#a4e6dc',
        '#b2eae1',
        '#a3b1e0',
        '#dadff3'
      ]

      const colorStations = '#2E6FBF'
      let colorIndex = 0

      // For Each level voltage
      for (const level in response.Grid) {
        // Isolated Lines
        const levelIsolatedLines = response.Grid[level].IsolatedLines
        // Opened fuses
        const levelOpenedFuses = response.Grid[level].Open_Fuses
        // Switch boxes
        const linesToModifyList = []
        const levelSwitchBoxes = response.Grid[level].Switch_Boxes
        levelSwitchBoxes?.forEach((switchbox) => {
          const fusesPoints = []
          let bboxPolygon
          switchbox.fuses?.forEach(fuse => {
            const line = response.Grid[level].Lines.find(l => l.ID === fuse.L)
            if (line === undefined) {
              console.warn('no line', fuse.ID, fuse.L, line?.X, line?.Y)
            } else {
              fuse.line = line
              const index = !fuse.FORWARD ? line.X.length - 1 : 0
              const coord = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [line.X[index], line.Y[index]])
              fusesPoints.push([coord[1], coord[0]])
            }
          })
          if (switchbox.fuses) {
            if (switchbox.fuses?.length > 1) { // SwitchBox with several fuses
            /* Get the coordinates of the polygon of the bounding box that contains all last/first point of the lines
          connected to the switchbox fuses */
              const line = turf.lineString(fusesPoints)
              const bbox = turf.bbox(line)
              bboxPolygon = turf.bboxPolygon(bbox)
            } else { // SwitchBox with only one fuse can't be
              const point = turf.point(fusesPoints[0])
              const buffered = turf.buffer(point, 20, { units: 'meters' })
              const bbox = turf.bbox(buffered)
              bboxPolygon = turf.bboxPolygon(bbox)
            }
            switchbox.latLngs = bboxPolygon.geometry.coordinates[0]
          }
          switchbox.fuses?.forEach((fuse) => {
            fuse.color = fuse.CLOSED ? 'red' : 'green'

            /* Get intersection point between the polygon that represents the switchbox
            and the line connected to the switchbox fuse */
            const index = !fuse.FORWARD ? fuse.line.X.length - 1 : 0
            const indexNext = !fuse.FORWARD ? index - 1 : index + 1
            const coord1 = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [fuse.line.X[index], fuse.line.Y[index]])
            const coord2 = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [fuse.line.X[indexNext], fuse.line.Y[indexNext]])
            const lineString = turf.lineString([[coord1[1], coord1[0]], [coord2[1], coord2[0]]])
            const intersect = turf.lineIntersect(lineString, bboxPolygon)
            let intersectPoint
            if (intersect.features[0]?.geometry) {
              intersectPoint = intersect.features[0].geometry.coordinates
            } else {
              intersectPoint = [coord1[1], coord1[0]]
            }
            const distance = 1
            const options = { units: 'meters' }
            const middleTop = turf.destination(intersectPoint, 0.3, 90, options)
            const topRight = turf.destination(middleTop, distance / 2, 0, options)
            const topLeft = turf.destination(topRight, (distance / 3) * 2, -90, options)
            const bottomLeft = turf.destination(topLeft, distance, 180, options)
            const bottomRight = turf.destination(bottomLeft, (distance / 3) * 2, 90, options)

            fuse.latLngs = [
              [topRight.geometry.coordinates[0], topRight.geometry.coordinates[1]],
              [topLeft.geometry.coordinates[0], topLeft.geometry.coordinates[1]],
              [bottomLeft.geometry.coordinates[0], bottomLeft.geometry.coordinates[1]],
              [bottomRight.geometry.coordinates[0], bottomRight.geometry.coordinates[1]],
              [topRight.geometry.coordinates[0], topRight.geometry.coordinates[1]]
            ]

            linesToModifyList.push({ line: fuse.L, fuseForward: fuse.FORWARD, intersectPoint }) // Store the fuses with its lines and intersect points in a different list to modify the lines later
          })
        })

        // Stations
        const stations = []
        response.STATIONS.forEach((station) => {
          if (station.VOLTAGE_LEVELS.includes(parseFloat(level))) {
            station.color = colorStations
            stations.push(station)
            list.stations.push([level, station.ID])
          }
        })

        // Each network
        const levelNetwork = []
        response.NETWORKS.forEach((network, index) => {
          list.networks.push({ NAME: network.NAME, LEVEL: network.VOLTAGE_LEVEL })
          if (parseFloat(level) === (network.VOLTAGE_LEVEL)) {
            const elements = {}
            const networkLines = []
            const networkConnPoints = []
            const networkClosedFuses = []

            elements.network = network

            // Lines
            response.Grid[level].Lines.forEach((line) => {
              if (network.NAME === line.NETWORK) {
                const lineToModify = linesToModifyList.find(lines => lines.line === line.ID)
                if (lineToModify) {
                  line.initPoint = lineToModify.intersectPoint
                  line.fuseForward = lineToModify.fuseForward
                }
                networkLines.push(line)
                list.lines.push([level, line.NETWORK, line.ID, line.latLngs])
              }
            })
            elements.lines = networkLines

            // Connection points
            response.Grid[level].Conn_Points.forEach((connPoint) => {
              if (network.NAME === connPoint.NETWORK) {
                networkConnPoints.push(connPoint)
                list.connectionPoints.push([level, connPoint.NETWORK, connPoint.ID, connPoint.latLngs])
              }
            })
            elements.connection_points = networkConnPoints

            // Closed fuses
            response.Grid[level].Closed_Fuses.forEach((closedFuse) => {
              if (network.NAME === closedFuse.NETWORK) {
                networkClosedFuses.push(closedFuse)
                list.fuses.push([level, closedFuse.NETWORK, closedFuse.ID, closedFuse.latLngs])
              }
            })
            elements.closed_fuses = networkClosedFuses

            // Assign color to network (MOVE TO )
            colorIndex = colorIndex === stationColors.length - 1 ? 0 : (colorIndex += 1)
            elements.color = stationColors[colorIndex]
            levelNetwork.push(elements)
          }
        })

        networks.push({
          level,
          networks: levelNetwork,
          stations,
          switchBoxes: levelSwitchBoxes,
          openFuses: levelOpenedFuses,
          isolatedLines: levelIsolatedLines
        })
      }
      return { network: networks, list }
    }
  }
}

export {
  getNetworksObject
}
