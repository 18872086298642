<template>
  <v-card
    class="mx-auto"
    style="border:2px solid #f4c020"
    width="300"
    dark
  >
    <v-card-text class="title-card">
      {{ title }}
    </v-card-text>

    <v-card-text class="value-card">
      {{ value }}
      <span class="real-time"> {{ valueRealTime }}</span>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <form @submit.prevent="changePower">
          <v-card-title>
            <span class="text-h5">Change Power</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="power"
                    type="number"
                    step="0.01"
                    label="Introduce new Power (KW) *"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>
            <small>*{{ $t('admin.indication') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              {{ $t('admin.close') }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              type="submit"
            >
              {{ $t('admin.add') }}
            </v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
    <v-card-text
      class="value-subtitle"
      @click="clickLocked"
    >
      <v-icon
        :color="colorLocked"
        :size="sizeLocked"
        class="status-icon"
      >
        mdi-circle
      </v-icon>
      {{ subtitle }}
      <v-icon
        :size="sizeLocked"
        class="status-icon"
      >
        mdi-lock
      </v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'TIAMapKpisCard',

  components: {

  },
  props: {
    title: {
      type: Text,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    valueRealTime: {
      type: Text,
      default: ''
    },
    subtitle: {
      type: Text,
      default: '-'
    },
    color: {
      type: Text,
      default: ''
    },
    assignedClass: {
      type: Text,
      default: '-'
    },
    iconClass: {
      type: Text,
      default: '-'
    },
    icon: {
      type: Text,
      default: '-'
    },
    colorLocked: {
      type: Text,
      default: '-'
    },
    sizeLocked: {
      type: Text,
      default: '-'
    }
  },
  data: () => ({
    check: true,
    dialog: false,
    power: null
  }),
  computed: {
    ...mapState({
      activatedStations: (state) => state.TIA.TIAMap.activatedStations
    })
  },

  watch: {
    // whenever question changes, this function will run
    activatedStations () {
      this.check = this.activatedStations
    }
  },
  methods: {
    clickLocked () {
      // HERE WE HAVE TO CHECK IF THE USER HAVE PERMISIONS
      this.dialog = true
    },
    changePower () {
      this.dialog = false
    },
    clickCheck (assignedClass, value) {
      if (assignedClass === 'circle-status-all') {
        const classNamesHS = [
          'icon-green-green', 'icon-green-yellow', 'icon-green-red',
          'icon-yellow-green', 'icon-yellow-yellow', 'icon-yellow-red',
          'icon-red-green', 'icon-red-yellow', 'icon-red-red'
        ]
        this.hideElementsByClasses(classNamesHS, value, true)
      } else if (assignedClass === 'circle-status-forecast-green') {
        const classNamesHS = ['icon-green-green', 'icon-yellow-green', 'icon-red-green']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-forecast-yellow') {
        const classNamesHS = ['icon-green-yellow', 'icon-yellow-yellow', 'icon-red-yellow']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-forecast-red') {
        const classNamesHS = ['icon-green-red', 'icon-yellow-red', 'icon-red-red']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-green') {
        const classNamesHS = ['icon-green-green', 'icon-green-yellow', 'icon-green-red']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-yellow') {
        const classNamesHS = ['icon-yellow-green', 'icon-yellow-yellow', 'icon-yellow-red']
        this.hideElementsByClasses(classNamesHS, value)
      } else if (assignedClass === 'circle-status-red') {
        const classNamesHS = ['icon-red-green', 'icon-red-yellow', 'icon-red-red']
        this.hideElementsByClasses(classNamesHS, value)
      }
    },

    hideElementsByClass (className, value) {
      const elements = document.querySelectorAll(`.${className}`)
      if (value) {
        elements.forEach(element => {
          element.style.display = ''
        })
      } else {
        elements.forEach(element => {
          element.style.display = 'none'
        })
      }
    },
    hideElementsByClasses (classNames, value, allStations) {
      classNames.forEach(className => {
        const elements = document.querySelectorAll(`.${className}`)
        if (value) {
          if (allStations) {
            this.$store.dispatch('setElement', { path: 'TIA.TIAMap.activatedStations', value: true })
          }

          elements.forEach(element => {
            element.style.display = ''
          })
        } else {
          if (allStations) {
            this.$store.dispatch('setElement', { path: 'TIA.TIAMap.activatedStations', value: false })
          }
          elements.forEach(element => {
            element.style.display = 'none'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.custom-icon {
  font-size: 32px;
  color: red;
  /* O cualquier otro estilo que desees */
}
.title-card{
  margin-top:10px;
  font-size:17px;
  font-weight: bold;
  color:#f4c020!important;
}
.value-card{
  font-size:25px;
  font-weight: bold;
  display: flex;
  justify-content: center; /* Alineación horizontal al centro */
  align-items: center; /* Alineación vertical al centro */
  height: 3vh;
}
.status-icon{
  margin-left: 10px;
}
.yellow-check{
   color:#f4c020!important;
   margin-right: 10px;
}
.hide-check{
   color:#f4c020!important;
   margin-left: -50px;
}
.value-subtitle{
  font-size:15px;
  text-align :right;
  cursor: pointer;
}
.real-time{
  color:#34AE60
}
</style>
