<template>
  <div>
    <v-expand-transition>
      <v-app-bar
        v-if="hidden"
        color="#272727"
        elevation="2"
        absolute
        height="2"
        width="100%"
      >
        <v-tooltip
          bottom
          open-delay="800"
        >
          <template #activator="{ on }">
            <div
              class="arrow-down"
              v-on="on"
            >
              <v-icon
                class="chevron-down"
                @click="toggleNavbar(false)"
              >
                {{ ChevronDown }}
              </v-icon>
            </div>
          </template>
          <span>Show Bar</span>
        </v-tooltip>
      </v-app-bar>
    </v-expand-transition>
    <v-expand-transition>
      <v-app-bar
        v-if="!hidden"
        color="#27272777"
        elevation="2"
        absolute
        height="60"
        width="100%"
      >
        <v-row class="nav-bar-map-tia">
          <v-col cols="1" />
          <v-col cols="8">
            <TIAMonitoringDatesSelector />
          </v-col>

          <v-spacer />
          <v-col class="m-top-10">
            <TIAMonitoringSearchBox
              v-if="listElements"
              :stations="getStations()"
              @selectElement="selectElement"
            />
          </v-col>
          <v-col
            cols="auto"
            class="p-right-0"
          >
            <v-tooltip
              bottom
              open-delay="800"
            >
              <template #activator="{ on }">
                <div
                  v-if="show"
                  class="arrow-up"
                  v-on="on"
                >
                  <v-icon
                    class="chevron-up"
                    @click="toggleNavbar(true)"
                  >
                    {{ ChevronUp }}
                  </v-icon>
                </div>
              </template>
              <span>Hide Bar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-app-bar>
    </v-expand-transition>
  </div>
</template>

<script>
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js'
import TIAMonitoringSearchBox from '@/components/TIA/TIAMonitoring/TIAMonitoringSearchBox.vue'
import TIAMonitoringDatesSelector from '@/components/TIA/TIAMonitoring/TIAMonitoringDatesSelector.vue'
export default {
  name: 'TIAMonitoringNavBarMap',
  components: {
    TIAMonitoringSearchBox,
    TIAMonitoringDatesSelector
  },
  props: {
    listElements: {
      type: Object,
      default: () => {}
    }

  },
  data: () => ({
    ChevronUp: mdiChevronDoubleUp,
    ChevronDown: mdiChevronDoubleDown,
    show: true,
    hidden: false
  }),

  watch: {
  },
  mounted () {
  },

  methods: {

    selectElement (station) {
      this.$emit('selectElement', station)
    },

    toggleNavbar (value) {
      this.hidden = value
      this.$emit('toggleNavbar')
    },

    getStations () {
      try {
        const stationsArray = []
        this.listElements.forEach(station => {
          if (typeof station.uuid === 'string') {
            stationsArray.push({ uuid: station.uuid, name: station.name })
          }
        })
        return stationsArray
      } catch (error) {
        console.log(error)
        this.error = true
      }
    }
  }
}
</script>

<style>

</style>
