import axios from 'axios'
import { refreshToken } from './auth'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // baseURL: 'https://plexilite.app',
  method: 'get'
})

function NetWorkException (message) {
  this.text = message
  this.title = 'Please try again later'
  this.type = 'NetWorkException'
  this.code = 504
}

function UserException (data) {
  this.text = data.data.detail
  this.title = 'Invalid Credentials'
  this.type = 'UserException'
  this.code = data.status
}

const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64)
    .split('')
    .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
    .join(''))

  return JSON.parse(jsonPayload)
}

export const plexiRequest = async config => {
  try {
    if (!config.url) throw new Error('Url is not defined')

    const finalConfig = {
      method: instance.defaults.method,
      ...config,
      headers: {
        Authorization: `Bearer ${window.sessionStorage.access}`,
        ...config.headers
      }
    }

    const response = await instance.request(finalConfig) || {}
    return response.data
  } catch (err) {
    const parsedToken = parseJwt(window.sessionStorage.access)
    const { response = {} } = err
    const isUnauthorized = response.status === 401
    const errorServer = response.status === 500 || response.status === 504 || response.status === 503 || response.status === 404
    const refresh = window.sessionStorage.getItem('refresh')
    const logged = window.sessionStorage.getItem('logged')

    if (logged === true || logged === 'true') {
      if (parsedToken.exp < Date.now() / 1000) {
        return await refreshToken(config)
      }

      if (isUnauthorized) {
        if (refresh !== '') { return await refreshToken(config) }
        if (refresh === '') { return window.open('/') }
      }
    }

    if (errorServer) {
      return response.status
    }

    if (isUnauthorized) {
      throw new UserException(response)
    }

    throw new NetWorkException('No internet connection')
  }
}

export default instance
