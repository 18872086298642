<template>
  <div class="map-info">
    <v-footer
      dark
      padless
      class="px-5"
    >
      <v-row class="map-info-row-container justify-space-between my-2">
        <div class="map-info-row-container-item">
          <v-row class="text-no-wrap">
            <div>
              {{ $t('map_info.area') }}:
              <strong v-if="$sessionStorage.zoneId !== null">{{ areaMapName }}</strong>
              <strong v-else>{{ elementSelected.type }} {{ elementSelected.id }} ({{ elementSelected.radius }} m)</strong>
            </div>
            <div class="ml-4">
              {{ $t('map_info.volt_level') }}: <strong>{{ $t('map_info.low') }}</strong>
            </div>
          </v-row>
        </div>
        <v-col class="map-col-container">
          <v-row class="text-no-wrap justify-end">
            <div class="mode-item d-flex m-right-200">
              {{ $t('map_info.coordinates') }}:
              <strong class="ml-1">
                {{ coordinatesText }}
              </strong>
            </div>
            <div class="mode-item d-flex">
              {{ $t('mode') }}:<strong class="ml-1">{{
                mode == 'network_mode' ? 'Network' : 'Element'
              }}</strong>
            </div>
            <div class="element-hovered-item ml-4 d-flex">
              {{ $t('map_info.element_hovered') }}:<strong class="ml-1">{{
                $store.state.elementHovered
              }}</strong>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MapInformation',
  props: {
    mode: {
      type: String,
      default: ''
    },
    coordinatesText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      elementSelected: JSON.parse(this.$sessionStorage.gridElement)
    }
  },

  computed: {
    ...mapState({
      areaMapName: ({ areaMap }) => areaMap && areaMap.at(-1)?.NAME
    })
  }
}
</script>
<style scoped lang="scss">
.map-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 500;
}
.map-info-row-container {
  width: 100%;
  &-item {
    padding: 12px;
  }
}
.mode-item {
  width: 105px;
}
.element-hovered-item {
  width: 256px;
}
.m-right-200 {
  margin-right: 200px;
}
</style>
