<template>
  <div class=" mt-16 p-4">
    <v-col
      cols="3"
    >
      <v-btn-toggle
        v-model="toogle"
        dense
        rounded
        mandatory
        @change="changeEvents"
      >
        <v-btn
          :min-width="100"
          value="past"
        >
          Last
        </v-btn>
        <v-btn
          :min-width="100"
          value="future"
        >
          Next
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="events"
      :items-per-page="20"
      class="p-4 mt-16"
      @click:row="handleClick"
    >
      <template #item.status="{ value }">
        <div
          v-if="value == 'Scheduled' "
          style="color:#F4D03F"
        >
          <strong>
            {{ value }}
          </strong>
        </div>
        <div
          v-if="value == 'Solved' "
          style="color:#2ECC71"
        >
          <strong>
            {{ value }}
          </strong>
        </div>
      </template>
      <template #item.detail="{ value }">
        <v-btn
          outlined
          small
          dense
          color="#c3c3c3"
        >
          {{ value }}
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'TIAMapEvents',

  components: {
  },
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    iconMagnify: mdiMagnify,
    selected: [],
    toogle: 'future',
    headers: [
      {
        text: 'Substation',
        align: 'start',
        sortable: true,
        value: 'substation'
      },
      { text: 'Start', value: 'interval00' },
      { text: 'End', value: 'interval01' },
      { text: 'Power (kW)', value: 'Power violation' },
      { text: 'Status', value: 'status' },
      { text: '', value: 'detail' }
    ]
  }),
  mounted () {

  },
  methods: {
    getColor (status) {
      if (status > 150) return 'red'
      else if (status > 100) return 'orange'
      else return 'green'
    },
    handleClick (value) {
      console.log(value.uuid)
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.stationSelected', value: value.uuid })
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 1 })
      this.$store.dispatch('setElement', { path: 'TIA.tabTIAMonitoring', value: false })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 1 })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.constraintSelected', value: value.id })
    },
    changeEvents (value) {
      console.log(value)
      this.$emit('getLogs', value)
    }
  }
}
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: 38px !important;
  font-family: 'Faktum SemiBold';
  color: #c3c3c3
}
.v-btn-toggle > .v-btn.v-btn{
  border-radius: 2px !important;
}
.mt-16{
  margin-top:16px;
}
.p-4{
  padding:4px;
}
</style>
