<template>
  <v-card style="margin-top:30px"
    elevation="10"
  >
    <v-row>
      <v-col self-align="center" cols="12">
        <v-btn-toggle
          v-model="toggleTypeOfDevices"
          dense
          mandatory
        >
          <v-btn>PVs</v-btn>
          <v-btn >EVs</v-btn>
          <v-btn disabled>HPs</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="12">
      <v-expansion-panels
        accordion multiple
        v-model="panelPlanning"
      >
        <v-expansion-panel v-if="showPlanningPVs">
          <v-expansion-panel-header>{{ $t('dt.pvs_settings') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model.number="percentageCostumersPV"
                  :label="$t('dt.percentage_of_customers_with_pv')"
                  :rules="[between0and100]"
                  step="1"
                  type="number"
                  suffix="%"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model="initialPVPowerPenetration"
                  :label="$t('dt.initial_pv')"
                  type="number"
                  suffix="Kw"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model="PVPowerStepsIncrements"
                  :label="$t('dt.pv_power_steps')"
                  type="number"
                  suffix="Kw"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="showPlanningEVs">
          <v-expansion-panel-header>{{ $t('dt.evs_settings') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model="EVChargerNominalPower"
                  :label="$t('dt.ev_chargers')"
                  type="number"
                  suffix="kW"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model="EVBatteryCapacity"
                  :label="$t('dt.ev_mean_battery_cap')"
                  type="number"
                  suffix="kWh"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" >
                <v-text-field
                  dense
                  v-model="EVDailyDistanceMean"
                  :label="$t('dt.ev_daily_distance')"
                  type="number"
                  suffix="km"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4" >
                <v-text-field
                  dense
                  v-model="EVDailyDistanceSTD"
                  label="STD"
                  type="number"
                  suffix="%"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model="EVChargerPowerFactor"
                  :label="$t('dt.EV charger power factor')"
                  type="number"
                  suffix="p.u."
                  :rules="[between0and1]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="showPlanningHPs">
          <v-expansion-panel-header>{{ $t('dt.hps_settings') }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model.number="HPnumber"
                  :label="$t('dt.number_of_heatpumps')"
                  :rules="[between1and100]"
                  step="1"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model.number="HPNominalPower"
                  :label="$t('dt.hp_nominal_power')"
                  :rules="[between1and100]"
                  step="1"
                  type="number"
                  suffix="kVA"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model.number="HPPowerFactor"
                  :label="$t('dt.hp_nominal_factor')"
                  :rules="[between0and1]"
                  step="1"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" >
                <v-text-field
                  dense
                  v-model.number="HPCOP"
                  :label="$t('dt.cop')"
                  :rules="[between0and4]"
                  step="1"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8">
                <v-select
                v-model="HPPhase"
                :items="phases"
                :label="$t('dt.connection_phase')"
              ></v-select>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: 'PlanningForm',
  data() {
    return {
      toggleTypeOfDevices: 1,
      showPlanningPVs: false,
      showPlanningEVs: true,
      showPlanningHPs: false,
      panelPlanning: [0, 1, 2],
      percentageCostumersPV: 70,
      initialPVPowerPenetration: 100,
      PVPowerStepsIncrements: 200,
      EVChargerNominalPower: 5,
      EVBatteryCapacity: 40,
      EVDailyDistanceMean: 40,
      EVDailyDistanceSTD: 5,
      EVChargerPowerFactor: 0.95,
      HPNumber: 1,
      HPNominalPower: 5,
      HPPowerFactor: 1,
      HPCOP: 2,
      HPPhase: 'ABC',
      phases: ['A', 'B', 'C', 'ABC'],
      between0and1: (v) => {
        if (!Number.isNaN(parseFloat(v)) && v >= 0 && v <= 1) return true;
        return 'Number has to be between 0 and 1';
      },
      between0and4: (v) => {
        if (!Number.isNaN(parseFloat(v)) && v >= 0 && v <= 14) return true;
        return 'Number has to be between 0 and 4';
      },
      between0and100: (v) => {
        if (!Number.isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
        return 'Number has to be between 0 and 100';
      },
      between1and500: (v) => {
        if (!Number.isNaN(parseFloat(v)) && v >= 1 && v <= 100) return true;
        return 'Number has to be between 1 and 500';
      },
    };
  },
  methods: {
    saveConfigEV() {
      const planningConfig = {
        rcp: parseFloat(this.EVChargerNominalPower),
        c: parseFloat(this.EVBatteryCapacity),
        kmd: parseFloat(this.EVDailyDistanceMean),
        stdkmd: parseFloat(this.EVDailyDistanceSTD),
        pf: parseFloat(this.EVChargerPowerFactor),
      };
      this.$store.dispatch('setElement', { path: 'planningConfigEV', value: planningConfig });
    },
    saveConfigPV() {
      const planningConfig = {
        percentage: parseFloat(this.percentageCostumersPV),
        initialPower: parseFloat(this.initialPVPowerPenetration),
        powerSteps: parseFloat(this.PVPowerStepsIncrements),
      };
      this.$store.dispatch('setElement', { path: 'planningConfigPV', value: planningConfig });
    },
  },
  watch: {
    EVChargerNominalPower() {
      this.saveConfigEV();
    },
    EVBatteryCapacity() {
      this.saveConfigEV();
    },
    EVDailyDistanceMean() {
      this.saveConfigEV();
    },
    EVDailyDistanceSTD() {
      this.saveConfigEV();
    },
    EVChargerPowerFactor() {
      this.saveConfigEV();
    },
    percentageCostumersPV() {
      this.saveConfigPV();
    },
    initialPVPowerPenetration() {
      this.saveConfigPV();
    },
    PVPowerStepsIncrements() {
      this.saveConfigPV();
    },
    toggleTypeOfDevices(typeOfDevice) {
      if (typeOfDevice === 0) {
        this.showPlanningPVs = true;
        this.showPlanningEVs = false;
        this.showPlanningHPs = false;
        this.$store.dispatch('setElement', { path: 'planningType', value: 'PV' });
        this.saveConfigPV();
      } else if (typeOfDevice === 1) {
        this.showPlanningPVs = false;
        this.showPlanningEVs = true;
        this.showPlanningHPs = false;
        this.$store.dispatch('setElement', { path: 'planningType', value: 'EV' });
        this.saveConfigEV();
      } else {
        this.showPlanningPVs = false;
        this.showPlanningEVs = false;
        this.showPlanningHPs = true;
      }
    },
  },
  created() {
    this.$store.dispatch('setElement', { path: 'planningType', value: 'EV' });
    this.saveConfigEV();
    this.saveConfigPV();
  },
};
</script>
