<template>
  <v-app
    id="inspire"
    :style="`height:${tableHeight}`"
  >
    <v-container
      id="newConnectionsDataContainer"
    >
      <v-data-iterator
        v-if="list"
        class="faktum overflow"
        :items="list"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
        :single-expand="singleExpand"
      >
        <template #header>
          <v-toolbar>
            <v-text-field
              v-model="search"
              class="faktum overflow"
              clearable
              flat
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
            />
          </v-toolbar>
        </template>
        <template
          v-if="list"
          #default="{ items, isExpanded }"
        >
          <v-row>
            <v-col
              v-for="item in items"
              :key="item.name"
              cols="12"
            >
              <v-card>
                <v-card-title class="card-title subheading font-weight-bold">
                  <span class="card-title-name">{{ item.name }}</span>
                  <v-switch
                    :input-value="isExpanded(item)"
                    :label="item.isVisible ? 'Hide details' : 'See details'"
                    class="card-title-switch"
                    inset
                    @change="item.isVisible = !item.isVisible"
                  />
                </v-card-title>
                <v-divider />
                <v-list
                  v-if="item.isVisible"
                  dense
                  class="max-v-list-height"
                >
                  <v-list-item
                    v-for="(key, index) in filteredKeys"
                    :key="index"
                  >
                    <v-list-item-content :class="{ 'blue--text': sortBy === key }">
                      {{ customNames[key] || key }}:
                    </v-list-item-content>
                    <v-list-item-content
                      class="align-end"
                      :class="{ 'blue--text': sortBy === key }"
                    >
                      {{ item[key.toLowerCase()] }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template
          v-if="list.length > 0"
          #footer
        >
          <v-row
            id="paginate"
            class="mt-2 px-3"
            align="center"
            justify="center"
          >
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  text
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>{{ ChevronDown }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer />
            <span
              class="mr-4
              grey--text"
            >
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              x-small
              fab
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>{{ ChevronLeft }}</v-icon>
            </v-btn>
            <v-btn
              x-small
              fab
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>{{ ChevronRight }}</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </v-app>
</template>

<script>
import { mdiChevronLeftCircle, mdiChevronDownCircle, mdiChevronRightCircle } from '@mdi/js'

export default {
  name: 'DtPanelKpisNewConnectionsData',
  props: {
    list: {
      type: Array,
      default: null
    },
    customNames: {
      type: Object,
      default: () => {}
    },
    keys: {
      type: Array,
      default: () => []
    },
    tableHeight: {
      type: String,
      default: '274px'
    }
  },
  data () {
    return {
      ChevronLeft: mdiChevronLeftCircle,
      ChevronDown: mdiChevronDownCircle,
      ChevronRight: mdiChevronRightCircle,
      itemsPerPageArray: [4, 8, 12],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: 'name',
      items: this.list,
      singleExpand: false
    }
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    filteredKeys () {
      return this.keys.filter((key) => key !== 'Name')
    }
  },
  methods: {
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.faktum {
  font-family:'Faktum Regular', sans-serif;
}
.overflow {
  overflow-y:auto;
  overflow-x: hidden;
}
.max-v-list-height {
    max-height: 400px;
    overflow-y: auto;
}
.card-title {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
.card-title-name {
  align-self: center;
}

#newConnectionsDataContainer {
  display: flex;
  flex: 1;
}

#newConnectionsDataContainer > div {
  flex: 1;
}
</style>
