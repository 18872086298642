<template>
  <v-row class="plot-container">
    <v-container
      id="hcm-plot-container"
      class="p-0"
    >
      <v-col>
        {{ title }}
      </v-col>
      <div id="hcp">
        <Plotly
          :data="getPlotData"
          :layout="getLayoutPlot"
          :responsive="true"
          :displaylogo="false"
          class="plotly-height"
        />
      </div>
    </v-container>
  </v-row>
</template>

<script>
import { Plotly } from 'vue-plotly'

export default {
  components: { Plotly },

  props: {
    dataPlotHC: {
      type: Array,
      default: () => []
    },
    elementHCM: {
      type: Object,
      default: () => {}
    },
    hcmTime: {
      type: Object,
      default: () => {}
    },
    selectedTypeViewMap: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      types: {
        connPoint: 'connection point',
        line: 'line'
      }
    }
  },
  computed: {
    getPlotData () {
      const arrayX = []
      const steps = this.hcmTime.StepCount
      const date = new Date(this.hcmTime.StartTime).getTime()
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
      for (let i = 0; i < steps; i++) {
        const d = new Date()
        d.setTime(date + i * (60 * 60 * 1000))
        arrayX.push(d.toLocaleDateString('en-UK', options))
      }

      const PhaseA = {
        x: arrayX,
        y: this.dataPlotHC[0],
        type: 'scatter',
        mode: 'lines',
        name: 'PhaseA',
        line: {
          shape: 'hvh'
        },
        visible: true
      }
      const PhaseB = {
        x: arrayX,
        y: this.dataPlotHC[1],
        type: 'scatter',
        mode: 'lines',
        name: 'PhaseB',
        line: {
          shape: 'hvh'
        },
        visible: true
      }
      const PhaseC = {
        x: arrayX,
        y: this.dataPlotHC[2],
        type: 'scatter',
        mode: 'lines',
        name: 'PhaseC',
        line: {
          shape: 'hvh'
        },
        visible: true
      }

      const threePhase = {
        x: arrayX,
        y: this.dataPlotHC[3],
        type: 'scatter',
        mode: 'lines',
        name: '3ph',
        line: {
          shape: 'hvh'
        },
        visible: true
      }
      const data = [PhaseA, PhaseB, PhaseC, threePhase]
      return data
    },
    getLayoutPlot () {
      const layout = {
        title: this.plotTitle,
        legend: {
          orientation: 'h',
          valign: 'top',
          yanchor: 'top',
          bgcolor: '#1E1E1E',
          font: {
            size: '10',
            family: 'Faktum Light'
          },
          y: -0.25
        },
        margin: {
          l: 60,
          r: 20,
          b: 70,
          t: 40,
          pad: 5
        },
        xaxis: {
          title: {
            text: this.$t('plot.time_h')
          },
          x: 1,
          y: -0.25,
          linecolor: '#fff',
          nticks: 5,
          gridcolor: '#2c2c2c',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 10
          }
        },
        yaxis: {
          title: 'kW',
          linecolor: '#fff',
          gridcolor: '#2c2c2c'
        },
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: '#1E1E1E',
        bgcolor: 'rgba(0,0,0,0)',
        font: {
          color: '#fff',
          size: '10',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }
      return layout
    },
    title () {
      const titleText = this.selectedTypeViewMap === 'consumption' ? this.$t('hcm.consumption_hc') : this.$t('hcm.generation_hc')
      return titleText
    },
    plotTitle () {
      const title = this.elementHCM.type === 'line' ? this.$t('grid.line') : this.$t('grid.connection_point')
      return title + ' ' + this.elementHCM.ID
    }
  }
}
</script>

<style lang="scss" scoped>
.row.plot-container {
  margin: 0;
  width: 100%;
  background-color: #1e1e1e;

  #hcm-plot-container {
    width: inherit;
    .col {
      color: #f4c01f;
    }
    #hcp {
      background: '#1e1e1e';
      .plotly-height {
        min-height: 400px !important;
      }
    }
  }
}
.title {
  padding-top: 1rem;
  font-size: 1rem !important;
  background-color: black;
}
</style>
