<template>
  <div v-if="loadingSelf.isVisible">
    <CMLoader
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
  </div>
  <v-card
    v-else-if="loadingSelf.isVisible == false"
    width="100%"
  >
    <splitpanes>
      <pane
        min-size="60"
        max-size="60"
        size="60"
      >
        <TIAMapBase
          :stations="stations"
          :networks="networks"
          @showLoading="showLoading"
        />
      </pane>
      <pane
        min-size="50"
        max-size="50"
        size="50"
      >
        <v-tabs
          v-model="tab"
          :height="30"
          dark
          fixed-tabs
          class="yellowPlexi"
        >
          <v-tab
            :height="25"
            :value="0"
          >
            KPIS
          </v-tab>
          <v-tab
            :height="25"
            :value="1"
          >
            EVENTS
          </v-tab>

          <v-tab-item :value="0">
            <TIAMapKpis
              :network="KPIsNetwork"
            />
          </v-tab-item>
          <v-tab-item :value="1">
            <TIAMapEvents
              :events="events"
              @getLogs="getLogs"
            />
          </v-tab-item>
        </v-tabs>
      </pane>
    </splitpanes>
  </v-card>
</template>

<script>

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import CMLoader from '@/components/Common/CMLoader.vue'
import TIAMapBase from '@/components/TIA/TIAMap/TIAMapBase.vue'
import TIAMapKpis from '@/components/TIA/TIAMap/TIAMapKpis.vue'
import TIAMapEvents from '@/components/TIA/TIAMap/TIAMapEvents.vue'
import { getStations, getNetwork, getMonitorLogs } from '@/services/TIA'
import { getNetworksObject } from '@/mixins/map'

export default {
  name: 'TIAMap',
  components: {
    Splitpanes,
    Pane,
    CMLoader,
    TIAMapBase,
    TIAMapKpis,
    TIAMapEvents
  },
  mixins: [
    getNetworksObject
  ],
  data: () => ({
    coordinates: [40.40048958037617, -3.6783988212195466],
    allPlants: null,
    stations: [],
    KPIsNetwork: [],
    networks: [],
    events: [],
    error: false,
    loadingSelf: {
      isVisible: null,
      message: 'Loading TIA Map'
    },
    tab: 0
  }),

  mounted () {
    this.showLoading(true)
    this.getData()
    setTimeout(() => { this.showLoading(false) }, 1000)
  },

  methods: {
    async getData () {
      try {
        this.stations = await getStations()
        this.KPIsNetwork = await getNetwork()
        this.getLogs('future')
        const networkObject = this.getNetworksObject(this.$store.state.areaGrid)
        this.networks = networkObject.network
        this.updateNetworkColor('#C3C3C3', this.networks)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    updateNetworkColor (color, tempNetwork = []) {
      tempNetwork.forEach((voltageLevel, indexLevel) => {
        voltageLevel.networks.forEach((network, index) => {
          tempNetwork[indexLevel].networks[index].color = color
        })
      })
    },
    async getLogs (period) {
      this.events = await getMonitorLogs('', period)
    },

    showLoading (show) {
      this.loadingSelf.isVisible = show
    }
  }
}
</script>

<style scoped>
.splitpanes--vertical > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  width: 2px;
  border-right: 2px solid rgba(238, 238, 238, 0.2);
}
.splitpanes--horizontal > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  height:2px;
  border-top:2px solid rgba(238, 238, 238, 0.2);
  margin-top:-1px;
  }
  .v-tab {
    min-width: 59px !important;
    max-width: 1059px !important;
    margin-right: -14px !important;
    margin-left: -5px !important;
}
.v-card-text{
    line-height: 1.25rem;
    padding-top: 16px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
}
.v-tab.v-tab {
    height: 25px;
}
div.v-card__text {
    padding: 0px;
}
</style>
