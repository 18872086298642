<template>
  <div
    :id="name"
    class="sld-container"
    :class="{scrollable: isScrollable}"
    :nodeLegend="nodeLegend"
    :legendSegmentTop="legendSegmentTop"
  />
</template>

<script>
import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'

import { SLD } from '@/assets/singleLineDiagram/orthogonal'

export default {
  name: 'SingleLineDiagram',
  mixins: [clickElements, unclickElements],
  props: {
    name: { type: String },
    ctname: { type: String },
    nodeLegend: { type: Function },
    interval: { type: Number },
    legendSegmentTop: { type: Function },
    legendSegmentBottom: { type: Function },
    scrollable: { type: String },
    segmentColor: { type: Function },
    busColor: { type: Function },
    plotFlow: { type: Boolean },
    devices: { type: Object },
    level: {
      type: String,
      default: ''
    }
  },
  data () {
    // In case the user didn't defined scrollable characteristic: define it automatically
    let isScrollable = this.scrollable === 'true'

    if (this.scrollable === undefined) {
      if (this.nodeLegend) {
        isScrollable = true
      } else {
        isScrollable = false
      }
    }

    // const mySLD = new SLD(this.name, this.isScrollable, this.nodeLegend, this.legendSegmentTop,
    //   this.legendSegmentBottom, this.segmentColor, this.busColor);

    return {
      isScrollable,
      mySLD: {}
    }
  },

  watch: {
    ctname (newVal, oldVal) { // watch it
      this.mySLD.draw(newVal)
    }
  },
  mounted () {
    if (this.plotFlow === undefined) {
      this.plotFlow = true
    }
    this.mySLD = new SLD(this.$HEADER_HTTP, this.$API_HOST, this.$API_PORT, this.name,
      this.isScrollable, this.nodeLegend, this.legendSegmentTop, this.legendSegmentBottom,
      this.segmentColor, this.busColor, this.plotFlow, this.devices)
    this.mySLD.leftClickFunc = (id, type) => {
      if (type === 'line') {
        this.clickElements(this.ctname, id, 'Line')
        this.click(this.ctname, id, 'Line')
      } else if (type === 'load') {
        this.clickElements(this.ctname, id, 'cgp')
        this.click(this.ctname, id, 'cgp')
      } else if (type === 'transformer') {
        this.clickElements(this.ctname, id, 'station')
        this.click(this.ctname, this.ctname, 'station')
      } else {
        this.clickElements(this.ctname, id, 'Fuse')
        this.click(this.ctname, id, 'Fuse')
      }
    }
    this.mySLD.draw(this.ctname)

    if (this.interval) {
      setInterval(() => { this.mySLD.refresh() }, this.interval)
    }
  },
  methods: {
    refreshSLD () {
      this.mySLD.refresh()
    },
    click (station, elementId, elementType) {
      this.$emit('click', this.level, station, elementId, elementType)

      if (this.isContextMenu) {
        this.$store.dispatch('toggleElement', 'contextMenu')
      }
    }
  }
}
</script>

<style scoped>
  @import '../../assets/icons/style.css';
  @import '../../assets/singleLineDiagram/orthogonal.css';
</style>
