<template>
  <VDialog
    v-model="showSetAreaForm"
    max-width="450"
    overlay-opacity="0.6"
  >
    <div v-if="loading">
      <VCard
        elevation="20"
        height="200"
      >
        <CMLoader
          :loading="loader.isVisible"
          :message="loader.message"
        />
      </VCard>
    </div>
    <div v-else>
      <VCard
        elevation="10"
        class="select-container"
      >
        <ASForm
          is-draw-map
          :source="source"
          @getArea="getArea"
          @getElement="getElement"
        />
      </VCard>
    </div>
  </VDialog>
</template>

<script>
import { mapState } from 'vuex'
import ASForm from '@/components/AreaSelector/ASForm.vue'
import CMLoader from '@/components/Common/CMLoader.vue'

export default {
  name: 'ASDialog',

  components: {
    ASForm,
    CMLoader
  },

  props: {
    source: {
      type: Object,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loader: {
        isVisible: true,
        message: this.$t('getting_grid_msg')
      }
    }
  },

  computed: {
    ...mapState(['showSetAreaForm'])
  },

  methods: {
    getArea () {
      this.$emit('getArea')
    },

    getElement () {
      this.$emit('getElement')
    }
  }

}
</script>

<style>
.v-dialog__content {
  z-index: 501 !important;
}

.v-overlay {
  z-index: 501 !important;
}

.v-btn.v-item--active {
  background-color: #f4c020 !important;
  color: #000 !important;
}

.v-card.select-container {
  padding: 2rem;
  background: #1e1e1e;
  border-radius: 5px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
</style>
