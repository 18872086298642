<template>
  <div>
    <v-expand-transition>
      <v-app-bar
        v-if="hidden"
        color="#272727"
        elevation="2"
        absolute
        height="2"
        width="100%"
      >
        <v-tooltip
          bottom
          open-delay="800"
        >
          <template #activator="{ on }">
            <div
              class="arrow-down"
              v-on="on"
            >
              <v-icon
                class="chevron-down"
                @click="toggleNavbar(false)"
              >
                {{ ChevronDown }}
              </v-icon>
            </div>
          </template>
          <span>Show Bar</span>
        </v-tooltip>
      </v-app-bar>
    </v-expand-transition>
    <v-expand-transition>
      <v-app-bar
        v-if="!hidden"
        color="#27272777"
        elevation="2"
        absolute
        height="60"
        width="100%"
      >
        <v-row class="nav-bar-map-tia">
          <v-col />
          <v-col />
          <v-spacer />
          <v-col class="m-top-10">
            <TIAMapSearchBox
              v-if="listElements"
              :networks="networks"
              :stations="getStations()"
              @selectElement="selectElement"
            />
          </v-col>
          <v-col
            cols="auto"
            class="p-right-0"
          >
            <v-tooltip
              bottom
              open-delay="800"
            >
              <template #activator="{ on }">
                <div
                  v-if="show"
                  class="arrow-up"
                  v-on="on"
                >
                  <v-icon
                    class="chevron-up"
                    @click="toggleNavbar(true)"
                  >
                    {{ ChevronUp }}
                  </v-icon>
                </div>
              </template>
              <span>Hide Bar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-app-bar>
    </v-expand-transition>
  </div>
</template>

<script>
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js'
import TIAMapSearchBox from '@/components/TIA/TIAMap/TIAMapSearchBox.vue'

export default {
  name: 'NavBarMap',
  components: {
    TIAMapSearchBox
  },
  props: {
    listElements: {
      type: Object,
      default: () => {}
    }

  },
  data: () => ({
    ChevronUp: mdiChevronDoubleUp,
    ChevronDown: mdiChevronDoubleDown,
    show: true,
    hidden: false
  }),

  watch: {
  },
  mounted () {
  },

  methods: {

    selectElement (station) {
      this.$emit('selectElement', station)
    },

    toggleNavbar (value) {
      this.hidden = value
      this.$emit('toggleNavbar')
    },

    getStations () {
      const stationsArray = []

      this.listElements.forEach(station => {
        stationsArray.push(station.name)
      })
      return stationsArray
    }
  }
}
</script>

<style>

</style>
