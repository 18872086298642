<template>
  <v-dialog
    v-model="display"
    style="z-index:510"
    max-width="450"
    overlay-opacity="0.6"
    hide-overlay
  >
    <v-card elevation="10">
      <v-form
        ref="PVForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('grid.smart_meters') }}
        </v-card-title>
        <v-card-text>
          <v-expansion-panels
            v-if="meters.length"
            v-model="panel"
            multiple
          >
            <v-expansion-panel
              v-for="(meter, index) in meters"
              :key="meter.id"
            >
              <v-expansion-panel-header>{{ meter.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <template v-if="isRemoveClient">
                  <v-btn
                    :disabled="verifyRemoved(meter.id)"
                    color="#f4c020"
                    large
                    @click="removeClient(meter.id)"
                  >
                    {{ $t('dt.remove_client') }}
                  </v-btn>
                  <v-btn
                    :disabled="!verifyRemoved(meter.id)"
                    class="ml-2"
                    color="#f4c020"
                    large
                    @click="restoreClient(meter.id)"
                  >
                    {{ $t('reset') }}
                  </v-btn>
                  <v-alert
                    v-if="verifyRemoved(meter.id)"
                    class="mb-0"
                  >
                    {{ $t('dt.client_removed') }}
                  </v-alert>
                </template>
                <template v-if="isChangePhase">
                  <v-select
                    v-model="meter.phase"
                    :value="meter.phase"
                    :items="phItems"
                    :label="$t('phase_connection')"
                    required
                    @change="setChange(meter, index)"
                  />
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <span v-else> {{ $t('dt.no_meters_in_connection_point') }}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            text
            @click="closeForm"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="meters.length && !isRemoveClient"
            color="#f4c020"
            text
            @click="saveChanges"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { get } from 'lodash'
import { ICONS } from '@/store/constants'

export default {
  name: 'SmartMetersForm',

  props: {
    showForm: {
      type: Boolean,
      default: false
    },

    payload: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      valid: true,
      meters: [],
      metersChanged: [],
      panel: [],
      phItems: [
        { text: 'A', value: 1 },
        { text: 'B', value: 2 },
        { text: 'C', value: 3 },
        { text: 'ABC', value: 4 }
      ]
    }
  },
  computed: {
    ...mapState([
      'connectionPoint',
      'currentCase',
      'DTChanges',
      'DTResults',
      'markers',
      'referenceCase'
    ]),

    display () {
      return this.showForm
    },

    metersChangedStore () {
      return this.DTChanges.Phase.Meter
    },

    isRemoveClient () {
      return this.payload.actionType === 'Remove Client'
    },

    isChangePhase () {
      return this.payload.actionType === 'Change Phase'
    }
  },

  created () {
    this.meters = this.DTResults.getMeters(this.connectionPoint.ID, this.referenceCase)
    const previousChanges = get(this, 'DTChanges.Phase.Meter', [])

    previousChanges && previousChanges.forEach((previous, previousIndex) => {
      const meterIndex = this.meters.findIndex((m) => m.id === previous)

      if (meterIndex >= 0) {
        this.meters[meterIndex].phase = this.DTChanges.Phase.ph[previousIndex]
        this.panel.push(meterIndex)
      }
    })
  },

  methods: {
    closeForm () {
      this.panel = []
      this.meters = []
      this.$emit('closeForm')
    },

    removeClient (meterId) {
      if (!this.DTChanges.Phase) {
        this.$store.dispatch('setElement', {
          path: 'DTChanges.Phase',
          value: {
            CGP: [this.connectionPoint.ID],
            Meter: [meterId],
            ph: [-1]
          },
          root: 'DTChanges'
        })
      } else {
        const isMeterExist = this.DTChanges.Phase.Meter.findIndex((m) => m === meterId)
        const meterIndex = isMeterExist > 0 ? isMeterExist : this.DTChanges.Phase.Meter.length

        const options = {
          CGP: this.connectionPoint.ID,
          Meter: meterId,
          ph: -1
        }

        Object
          .keys(options)
          .forEach((key) => {
            this.$store.dispatch('setElement', {
              path: `DTChanges.Phase[${key}][${meterIndex}]`,
              value: options[key],
              root: 'DTChanges'
            })
          })
      }

      this.$store.dispatch('setElement', {
        path: 'markers',
        value: [
          ...this.markers,
          {
            id: meterId,
            icon: ICONS.RemoveUser,
            latLng: this.connectionPoint.latLng,
            element: this.connectionPoint,
            type: 'removeUser',
            typeElement: 'contextMenuConn'
          }
        ]
      })
    },

    verifyRemoved (meterId) {
      const meterIndex = this.metersChangedStore.findIndex((m) => m === meterId)
      const meterPhase = this.DTChanges.Phase.ph[meterIndex]

      return meterPhase < 0
    },

    restoreClient (meterId) {
      const meterIndex = this.metersChangedStore.findIndex((m) => m === meterId)
      const keys = ['CGP', 'Meter', 'ph']

      keys.forEach((key) => {
        this.DTChanges.Phase[key] = this.DTChanges.Phase[key]
          .filter((_, index) => index !== meterIndex)
      })

      this.$store.dispatch('setElement', {
        path: 'markers',
        value: this.markers.filter((m) => m.id !== meterId)
      })
    },

    saveChanges () {
      const filtered = this.metersChanged.filter(Boolean)
      const keys = filtered.length && Object.keys(filtered[0])

      if (keys) {
        this.metersChanged = Object
          .fromEntries(keys
            .map((key) => [key, filtered.map((meter) => meter[key])]))

        this.metersChanged.Meter.forEach((currentMeter, index) => {
          const isMeterExist = this.DTChanges.Phase.Meter.findIndex((m) => m === currentMeter)
          const meterIndex = isMeterExist >= 0 ? isMeterExist : this.DTChanges.Phase.Meter.length

          keys.forEach((key) => {
            this.$store.dispatch('setElement', {
              path: `DTChanges.Phase.${key}[${meterIndex}]`,
              value: this.metersChanged[key][index],
              root: 'DTChanges'
            })
          })
        })

        const newMeters = this.metersChanged.Meter.filter(
          (m) => !this.markers.map((mk) => mk.meter).includes(m.Meter)
        )

        newMeters.forEach((meter) => {
          this.$store.dispatch('setElement', {
            path: 'markers',
            value: [
              ...this.markers,
              {
                id: meter,
                icon: ICONS.Phase,
                latLng: this.connectionPoint.latLng,
                element: this.connectionPoint,
                type: 'Phase',
                typeElement: 'contextMenuConn',
                meter
              }
            ]
          })
        })
      }

      this.closeForm()
    },

    setChange (meter, index) {
      this.metersChanged[index] = {
        CGP: this.connectionPoint.ID,
        Meter: meter.id,
        ph: meter.phase
      }
    }
  }
}
</script>

<style scoped>
.ml-2 {
  margin-left: 20px;
}
.mb-0 {
  margin-bottom: 0;
}
</style>
