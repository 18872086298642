<template>
  <div>
    <div v-if="digitalTwinMap || hostingCapacityMap">
      <l-polyline
        v-for="line in lines"
        :ref="line.ID"
        :key="line.ID"
        :name="'line'+line.ID"
        :lat-lngs="line.latLng"
        :color="linesColor(line.color)"
        :weight="weight"
        :class-name="'network'+line.NETWORK+' line'+line.ID+' polyline'"
        @mouseover="mouseover($event.target, line.ID, level, line.NETWORK)"
        @mouseleave="mouseleave($event.target,line.ID, level,line.NETWORK)"
        @click="click(level,line.NETWORK,line.ID,'line', digitalTwinMap, hostingCapacityMap, multiselect)"
        @contextmenu="handleContextmenu($event, line)"
      >
        <l-tooltip v-if="line.tooltip || tooltipText">
          <strong>{{ line.tooltip || tooltipText }}</strong>
          <div
            v-if="line.tooltipAbsolute"
            style="font-size:9px;"
          >
            {{ line.tooltipAbsolute }}
          </div>
        </l-tooltip>
      </l-polyline>
      <div v-if="powerFlow">
        <l-polyline
          v-for="line in lines"
          :ref="line.ID + 'bis'"
          :key="line.ID + 'bis'"
          :name="'line'+line.ID +'bis'"
          :lat-lngs="line.latLng"
          :color="line.color"
          :opacity="0.7"
          :weight="weight"
          :class-name="'network'+line.NETWORK+' line'+line.ID + ' animated'
            +' polyline ' + animationClass(line.ID, line.positive) "
          @ready="animationClass(line.ID, line.positive)"
          @mouseover="mouseover($event.target, line.ID, level, line.NETWORK)"
          @mouseleave="mouseleave($event.target,level,line.NETWORK)"
          @click="click(level,line.NETWORK,line.ID,'line',true, false, multiselect)"
          @contextmenu="handleContextmenu($event, line)"
        >
          <l-tooltip>
            <b>{{ line.tooltip }}</b>
            <div style="font-size:9px; text-align:right">
              {{ line.tooltipAbsolute }}
            </div>
          </l-tooltip>
        </l-polyline>
      </div>
    </div>
    <div v-else-if="isolated">
      <l-polyline
        v-for="line in lines"
        :ref="line.ID"
        :key="line.ID"
        :name="'line'+line.ID"
        :lat-lngs="line.latLng"
        color="#fff"
        :class-name="'stationNone'+' line'+line.ID+' polyline'"
        @mouseover="mouseover($event.target,'None',line.ID)"
        @mouseleave="mouseleave($event.target,'None')"
        @click="click('None',line.ID,'line',false, false)"
        @contextmenu="handleContextmenu($event, line)"
      />
    </div>
    <div v-else>
      <l-polyline
        v-for="line in lines"
        :ref="line.ID"
        :key="line.ID"
        :name="'line'+line.ID"
        :opacity="setOpacity(line.ID)"
        :lat-lngs="line.latLng"
        :color="setElementColor(line.NETWORK, colorStation)"
        :class-name="'network'+line.NETWORK+' line'+line.ID+' polyline'"
        @mouseover="mouseover($event.target, line.ID, 'line', level, line.NETWORK)"
        @mouseleave="mouseleave($event.target, line.ID, 'line', level, line.NETWORK)"
        @click="click(level,line.NETWORK,line.ID,'line',false, false, multiselect)"
        @contextmenu="handleContextmenu($event, line)"
      />
    </div>
  </div>
</template>
<script>
import click from '@/mixins/map/mapElementClickMixin'
import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import mouseover from '@/mixins/map/mapElementMouseoverMixin'
import mouseleave from '@/mixins/map/mapElementMouseleaveMixin'
import { LPolyline, LTooltip } from 'vue2-leaflet'
import proj4 from 'proj4'
import { mapState } from 'vuex'
import mapElementOpacityMixin from '@/mixins/map/mapElementOpacityMixin'
import mapElementColorMixin from '@/mixins/map/mapElementColorMixin'

export default {
  name: 'LineLayer',
  components: {
    LPolyline,
    LTooltip
  },
  mixins: [unclickElements, clickElements, click, mouseover, mouseleave, mapElementOpacityMixin, mapElementColorMixin],
  props: {
    linesData: {
      type: Array,
      required: true
    },
    isolated: {
      type: Boolean,
      required: false,
      default: false
    },
    colorStation: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    digitalTwinMap: {
      type: Boolean,
      default: false
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    isHovered: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    level: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: null
    },
    contextMenu: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      powerFlow: false,
      weight: undefined
    }
  },

  computed: {
    lines () {
      const LinesUpdated = this.linesData/* this.linesData is being modified along with LinesUpdated */
      const data = this.linesData
      data.forEach((element, index) => {
        const latLngs = []
        for (let i = 0; i < element.X.length; i += 1) {
          const location = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [element.X[i], element.Y[i]])
          latLngs.push([location[1], location[0]])
        }
        if ('fuseForward' in element) {
          if (!element.fuseForward) {
            latLngs[latLngs.length - 1] = element.initPoint
          } else {
            latLngs[0] = element.initPoint
          }
        }
        LinesUpdated[index].latLng = latLngs
      })
      return LinesUpdated
    },
    ...mapState({
      measurement: (state) => state.measurement.measurement,
      elementHovered: (state) => state.elementHovered,
      HCNetworksWithLines: ({ hostingCapacity }) => hostingCapacity.HCNetworksWithLines
    })
  },
  watch: {
    measurement () {
      if (this.measurement === 'activePower' || this.measurement === 'reactivePower') {
        this.powerFlow = true
      } else {
        this.powerFlow = false
      }
    },
    isHovered () {
      if (this.isHovered) {
        this.weight = 6
      } else {
        this.weight = 3
      }
    }
  },

  methods: {
    linesColor (lineColor) {
      return lineColor || this.colorStation
    },

    animationClass (id, positive) {
      const line = document.getElementsByClassName(`line${id} animated`)
      if (positive && line && line.length) {
        line[0].classList.remove('animation-negative')
        line[0].classList.add('animation-positive')
        return ('animation-positive')
      }
      if (line && line.length > 0) {
        line[0].classList.remove('animation-positive')
        line[0].classList.add('animation-negative')
        return ('animation-negative')
      }
      return ('')
    },
    handleContextmenu (e, line) {
      if (this.contextMenu) {
        const contextMenuPayload = {
          x: e.containerPoint.x + 75,
          y: e.containerPoint.y + 75,
          toggle: 'contextMenu'
        }

        const linePayload = {
          path: 'contextMenuElement',
          value: { type: 'contextMenuLine', info: line }
        }

        this.$store.dispatch('setClick', { lat: e.latlng.lat, lng: e.latlng.lng, idElement: line.ID  })
        this.$store.dispatch('setNewPosition', contextMenuPayload)
        this.$store.dispatch('setElement', linePayload)
      }
    }
  }
}
</script>

<style>
  @keyframes flow-positive {
      0% {
          stroke-dashoffset: 16;
      }
      100% {
          stroke-dashoffset: 0;
      }
  }
  .animation-positive{
      stroke-width: 2px;
      stroke: rgb(49, 58, 68);
      /* opacity: 70%; */
      stroke-dasharray: 10;
      animation: flow-positive 1s infinite linear;
  }
  @keyframes flow-negative {
      0% {
          stroke-dashoffset: 0;
      }
      100% {
          stroke-dashoffset: 16;
      }
  }
  .animation-negative{
      stroke-width: 2px;
      stroke: rgb(49, 58, 68);
      /* opacity: 70%; */
      stroke-dasharray: 10;
      animation: flow-negative 1s infinite linear;
  }
</style>
