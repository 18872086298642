<template>
  <div v-if="loadingSelf.isVisible">
    <CMLoader
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
  </div>
  <splitpanes v-else-if="loadingSelf.isVisible == false">
    <pane
      min-size="60"
      max-size="60"
      size="60"
    >
      <div v-if="loadingPlots.isVisible">
        <CMLoader
          :loading="loadingPlots.isVisible"
          :message="loadingPlots.message"
        />
      </div>
      <splitpanes
        v-else-if="loadingPlots.isVisible == false"
        horizontal
        :push-other-panes="false"
        style="height: 90vh"
      >
        <pane
          min-size="55"
          max-size="55"
          size="55"
        >
          <TIAMonitoringPlotStation
            v-if="monitorMarketPlot"
            :data="monitorStationPlot"
          />
        </pane>
        <pane
          min-size="45"
          max-size="45"
          size="45"
        >
          <v-tabs
            v-model="tabPlot"
            :height="30"
            dark
            fixed-tabs
            class="yellowPlexi"
          >
            <v-tab
              :height="25"
              :value="0"
            >
              Market
            </v-tab>
            <v-tab
              :height="25"
              :value="1"
            >
              Bilateral Agreements
            </v-tab>
            <v-tab
              :height="25"
              :value="2"
            >
              Non-Firm Connections
            </v-tab>

            <v-tab-item :value="0">
              <TIAMonitoringPlotFSP
                v-if="monitorMarketPlot"
                :data="monitorMarketPlot"
                :fsp="1"
              />
            </v-tab-item>
            <v-tab-item :value="1">
              <TIAMonitoringPlotFSP
                v-if="monitorBilateralPlot"
                :data="monitorBilateralPlot"
                :fsp="2"
              />
            </v-tab-item>
            <v-tab-item :value="2">
              <TIAMonitoringPlotFSP
                v-if="monitorNonFirmPlot"
                :data="monitorNonFirmPlot"
                :fsp="3"
              />
            </v-tab-item>
          </v-tabs>
        </pane>
      </splitpanes>
    </pane>
    <pane
      min-size="50"
      max-size="50"
      size="50"
      class="tia-monitoring-kpis"
    >
      <v-tabs
        v-model="tab"
        :height="25"
        dark
        fixed-tabs
        class="yellowPlexi"
      >
        <v-tab
          :height="25"
          :value="0"
          @click="clickTabKPIs()"
        >
          KPIS
        </v-tab>
        <v-tab
          :height="25"
          :value="1"
          @click="clickTabEvents()"
        >
          EVENTS
        </v-tab>

        <v-tab-item :value="0">
          <TIAMonitoringKpis
            v-if="monitorMarketPlot"
            :monitor-station="monitorStation"
            :customer-info="monitorCustomerPlot"
          />
        </v-tab-item>
        <v-tab-item :value="1">
          <TIAMapEventsStation
            :events="events"
            @getLogs="getLogs"
          />
        </v-tab-item>
      </v-tabs>
    </pane>
  </splitpanes>
</template>

<script>
import CMLoader from '@/components/Common/CMLoader.vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import TIAMonitoringKpis from '@/components/TIA/TIAMonitoring/TIAMonitoringKpis.vue'
import TIAMonitoringPlotStation from '@/components/TIA/TIAMonitoring/TIAMonitoringPlotStation.vue'
import TIAMonitoringPlotFSP from '@/components/TIA/TIAMonitoring/TIAMonitoringPlotFSP.vue'
import { getMonitorFSPPlot, getMonitorLogs, getMonitorSolutions } from '@/services/TIA'
import { mapState } from 'vuex'
import getLocalDateFormated from '@/mixins/TIA/getLocalDateFormated'
import TIAMapEventsStation from '@/components/TIA/TIAMap/TIAMapEventsStation.vue'

export default {
  name: 'TIAMonitoringLayout',

  components: {
    Splitpanes,
    Pane,
    TIAMonitoringKpis,
    TIAMonitoringPlotStation,
    TIAMonitoringPlotFSP,
    CMLoader,
    TIAMapEventsStation
  },
  mixins: [getLocalDateFormated],
  props: {
    monitorStation: {
      type: Array,
      default: () => []
    },
    monitorStationPlot: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    monitorMarketPlot: null,
    monitorBilateralPlot: null,
    monitorNonFirmPlot: null,
    events: [],
    solutions: [],
    loadingSelf: {
      isVisible: null,
      message: 'Getting data TIA Monitoring'
    },
    loadingPlots: {
      isVisible: null,
      message: 'Getting data Plots'
    },
    tab: 0,
    tabPlot: null
  }),

  computed: {
    ...mapState({
      customerSelected: (state) => state.TIA.TIAMonitoring.customerSelected,
      periodSelected: (state) => state.TIA.TIAMonitoring.periodSelected,
      tabTIAMonitoring: (state) => state.TIA.TIAMonitoring.tabTIAMonitoring
    })
  },

  watch: {
    tabTIAMonitoring () {
      this.tab = this.tabTIAMonitoring
    },
    customerSelected () {
      this.getDataCustomer()
    },
    periodSelected () {
      this.showLoadingPlots(true)
      this.getDataCustomer()
      setTimeout(() => { this.showLoadingPlots(false) }, 800)
    }
  },
  mounted () {
    this.showLoading(true)
    this.getDataCustomer()
    this.tab = this.tabTIAMonitoring
    setTimeout(() => { this.showLoadingPlots(false) }, 500)
    setTimeout(() => { this.showLoading(false) }, 700)
  },
  methods: {

    async getDataCustomer () {
      try {
        const uuid = this.$store.state.TIA.TIAMap.stationSelected
        const period = this.$store.state.TIA.TIAMonitoring.periodSelected

        let dateStart
        let dateEnd
        if (period === 'today') {
          dateStart = this.getLocalDateFormated(0)
          dateEnd = this.getLocalDateFormated(0)
        } else if (period === 'tomorrow') {
          dateStart = this.getLocalDateFormated(-1)
          dateEnd = this.getLocalDateFormated(-1)
        } else if (period === 'yesterday') {
          dateStart = this.getLocalDateFormated(1)
          dateEnd = this.getLocalDateFormated(1)
        }

        const timeStart = `${dateStart}T00:00:00Z`
        const timeEnd = `${dateEnd}T23:55:00Z`
        this.monitorMarketPlot = await getMonitorFSPPlot(uuid, timeStart, timeEnd, 1)
        this.monitorBilateralPlot = await getMonitorFSPPlot(uuid, timeStart, timeEnd, 2)
        this.monitorNonFirmPlot = await getMonitorFSPPlot(uuid, timeStart, timeEnd, 3)
        this.getLogs(uuid, 'future')
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getLogs (period) {
      const uuid = this.$store.state.TIA.TIAMap.stationSelected
      this.events = await getMonitorLogs(uuid, period)
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    },
    showLoadingPlots (show) {
      this.loadingPlots.isVisible = show
    },
    clickTabKPIs () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 0 })
    },
    clickTabEvents () {
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 1 })
    }
  }
}
</script>

<style >
.tia-monitoring-kpis{
  margin-top: 60px;
}
.splitpanes--vertical > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  width: 2px;
  border-right: 2px solid rgba(238, 238, 238, 0.2);
}
.splitpanes--horizontal > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  height:2px;
  border-top:2px solid rgba(238, 238, 238, 0.2);
  margin-top:-1px;
  }
  .v-tab {
    min-width: 59px !important;
    max-width: 1059px !important;
    margin-right: -14px !important;
    margin-left: -5px !important;
}
.v-card-text{
    line-height: 1.25rem;
    padding-top: 16px;
    padding-left: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
}
.v-tab.v-tab {
    height: 25px;
}
div.v-card__text {
    padding: 0px;
}
</style>
