<template>
  <div
    id="color_scale"
    class="map-colorScale"
  >
    <div class="quantity">
      <v-text-field
        v-model="lowerInput"
        hide-details
        type="number"
        color="#f4c020"
        step="0.1"
        :min="minInput"
        :max="maxInput"
        class="minw-60"
        :prefix="prefix"
        @change="changeLowerInput"
      />
    </div>
    <span id="gradient_color_bar" />
    <div class="quantity">
      <v-text-field
        v-model="upperInput"
        hide-details
        type="number"
        color="#f4c020"
        step="0.1"
        :min="minInput"
        :max="maxInput"
        class="minw-60"
        :prefix="prefix"
        @change="changeUpperInput"
      />
    </div>
  </div>
</template>
<script>

import getColorScale from '@/mixins/map/mapColorScaleMixin'
import maths from '@/mixins/maths'

const d3 = require('d3')

export default {
  name: 'ColorScale',
  mixins: [getColorScale, maths],
  props: {
    lower: {
      type: Number,
      default: 0
    },
    upper: {
      type: Number,
      default: 1
    },
    middle: {
      type: Number,
      default: 0.5
    },
    steps: {
      type: Number,
      default: 0.1
    },
    colors: {
      type: Array,
      default () {
        return ['#8AFF63', '#FFAE1A', '#C60209']
      }
    },
    prefix: {
      type: String,
      default: null
    },
    colorScaleLowerMin: {
      type: Number,
      default: null
    },
    colorScaleUpperMax: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    upperInput: null,
    lowerInput: null,
    gradientColorBar: null,
    colorScale: null
  }),
  computed: {
    numberOfColors () {
      return this.colors.length
    },
    minInput () {
      return [undefined, null].includes(this.colorScaleLowerMin)
        ? this.lower
        : this.colorScaleLowerMin
    },
    maxInput () {
      return [undefined, null].includes(this.colorScaleUpperMax)
        ? this.upper
        : this.colorScaleUpperMax
    }
  },
  watch: {
    upper () {
      this.upperInput = this.roundToThree(this.upper)
    },
    // upperInput () {
    //   this.$emit('changeUpperLimit', this.upperInput)
    // },
    lower () {
      this.lowerInput = this.roundToThree(this.lower)
    },
    colors () {
      this.redrawGradientColorBar(this.drawGradientColorBar)
    }
  },
  mounted () {
    this.upperInput = this.upper
    this.lowerInput = this.lower
    this.drawGradientColorBar()
  },
  methods: {
    redrawGradientColorBar (callback) {
      this.gradientColorBar.remove()
      callback()
    },
    drawGradientColorBar () {
      this.gradientColorBar = d3.select('#gradient_color_bar').append('svg')
        .attr('width', 30)
        .attr('height', 200)
        .style('vertical-align', 'top')
      const arr = d3.ticks(parseFloat(this.lower), parseFloat(this.upper), 200)
      const xScale = d3.scaleLinear().domain([this.lower, this.upper]).range([0, 200])
      const colorScale = this.getColorScale(this.numberOfColors, this.colors,
        this.lower, this.middle, this.upper)
      this.gradientColorBar.selectAll('rect')
        .data(arr)
        .enter().append('rect')
        .attr('y', (d) => xScale(d))
        .attr('x', 20)
        .attr('height', 10)
        .attr('width', 20)
        .style('fill', (d) => colorScale(d))
      this.$emit('setColorScale')
    },
    changeLowerInput () {
      this.$emit('changeLowerLimit', this.lowerInput)
    },
    changeUpperInput () {
      this.$emit('changeUpperLimit', this.upperInput)
    }
  }
}
</script>

<style>
.quantity >.v-text-field > .v-input__control > .v-input__slot:before{
  border-style:none !important;
}
.quantity > .theme--dark.v-input input, .v-text-field__prefix{
  color:#f4c020 !important;
}
.quantity > .v-text-field input, .quantity > .v-text-field .v-text-field__prefix{
  font-size:13px !important;
  text-align: center !important;
}
</style>

<style scoped>
.map-colorScale{
  color:#fff;
  z-index:500;
  position:relative;
  bottom:70vh;
  float: left;
  left:10px;
  height: 30px;
  line-height: 30px;
}
.v-text-field {
  margin-top:2px;
}
.quantity {
  position: relative;
  max-width:55px;
  padding-left: 10px;
  margin-left: 20px;
}
.minw-60 {
  min-width: 60px;
}
</style>
