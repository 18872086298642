<template>
  <div
    class="map-measurementSelector"
    :class="{ mstophigh: sldView || topHigh, mstoplow: !topHigh}"
  >
    <div
      id="select_magnitude"
      class="selection-tool"
    >
      <div
        id="selected_magnitude"
        class="hexagono-xs onmap selected"
        @click="showMagnitudeVertical()"
      >
        <span
          id="selected_measurement_content"
          class="hexagono-xs-content onmap selected"
        >
          Vmin
        </span>
      </div>
    </div>
    <div
      id="select_magnitude_vertical"
      class="selection-tool-vertical dark-theme"
      style="display:none"
    >
      <v-navigation-drawer
        color="#f4c020"
      >
        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <div
              v-on="{ ...tooltip }"
            >
              <v-menu
                left
                :offset-x="true"
              >
                <template #activator="{ on, attrs }">
                  <label
                    for="voltage_magnitude"
                    class="hexagono-xs"
                  >
                    <input
                      id="voltage_magnitude"
                      type="radio"
                      name="magnitude"
                      value="voltage_magnitude"
                      v-bind="attrs"
                      v-on="on"
                    >
                    <span
                      id="voltage_magnitude_content"
                      class="hexagono-xs-content"
                    >
                      <i
                        class="fas fa-caret-left"
                        style="margin-left:-5px"
                      />
                      <span style="margin-left:5px">V</span>
                    </span>
                  </label>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="selectedItem"
                  >
                    <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      @click="measurementSelected('voltage', item.abbr)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Voltage</span>
        </v-tooltip>

        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <div
              v-on="{ ...tooltip }"
            >
              <v-menu
                left
                :offset-x="true"
              >
                <template #activator="{ on, attrs }">
                  <label
                    for="current_magnitude"
                    class="hexagono-xs"
                  >
                    <input
                      id="current_magnitude"
                      type="radio"
                      name="magnitude"
                      value="current_magnitude"
                      v-bind="attrs"
                      v-on="on"
                    >
                    <span
                      id="current_magnitude_content"
                      class="hexagono-xs-content"
                    >
                      <i
                        class="fas fa-caret-left"
                        style="margin-left:-5px"
                      />
                      <span style="margin-left:5px">I</span>
                    </span>
                  </label>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="selectedItem"
                  >
                    <v-list-item
                      v-for="(item, i) in items"
                      :key="i"
                      @click="measurementSelected('current', item.abbr)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Current</span>
        </v-tooltip>

        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <div
              v-on="{ ...tooltip }"
            >
              <v-menu
                left
                :offset-x="true"
              >
                <template #activator="{ on, attrs }">
                  <label
                    for="active_power_magnitude"
                    class="hexagono-xs"
                  >
                    <input
                      id="active_power_magnitude"
                      type="radio"
                      name="magnitude"
                      value="active_power_magnitude"
                      v-bind="attrs"
                      v-on="on"
                    >
                    <span
                      id="active_power_magnitude_content"
                      class="hexagono-xs-content"
                    >
                      <i
                        class="fas fa-caret-left"
                        style="margin-left:-5px"
                      />
                      <span style="margin-left:5px">P</span>
                    </span>
                  </label>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="selectedItem1"
                  >
                    <v-list-item
                      v-for="(item, i) in items1"
                      :key="i"
                      @click="measurementSelected('activePower', item.abbr)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Active Power</span>
        </v-tooltip>

        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <div
              v-on="{ ...tooltip }"
            >
              <v-menu
                left
                :offset-x="true"
              >
                <template #activator="{ on, attrs }">
                  <label
                    for="reactive_power_magnitude"
                    class="hexagono-xs"
                  >
                    <input
                      id="reactive_power_magnitude"
                      type="radio"
                      name="magnitude"
                      value="reactive_power_magnitude"
                      v-bind="attrs"
                      v-on="on"
                    >
                    <span
                      id="reactive_power_magnitude_content"
                      class="hexagono-xs-content"
                    >
                      <i
                        class="fas fa-caret-left"
                        style="margin-left:-5px"
                      />
                      <span style="margin-left:5px">Q</span>
                    </span>
                  </label>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="selectedItem1"
                  >
                    <v-list-item
                      v-for="(item, i) in items1"
                      :key="i"
                      @click="measurementSelected('reactivePower', item.abbr)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Reactive Power</span>
        </v-tooltip>

        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <div
              v-on="{ ...tooltip }"
            >
              <v-menu
                left
                :offset-x="true"
              >
                <template #activator="{ on, attrs }">
                  <label
                    for="losses_magnitude"
                    class="hexagono-xs"
                  >
                    <input
                      id="losses_magnitude"
                      type="radio"
                      name="magnitude"
                      value="losses_magnitude"
                      v-bind="attrs"
                      v-on="on"
                    >
                    <span
                      id="losses_magnitude_content"
                      class="hexagono-xs-content"
                    >
                      <i
                        class="fas fa-caret-left"
                        style="margin-left:-5px"
                      />
                      <span style="margin-left:5px">L</span>
                    </span>
                  </label>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-model="selectedItem1"
                  >
                    <v-list-item
                      v-for="(item, i) in items1"
                      :key="i"
                      @click="measurementSelected('losses', item.abbr)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </div>
          </template>
          <span>Losses</span>
        </v-tooltip>

        <v-tooltip left>
          <template #activator="{ on: tooltip }">
            <label
              for="unbalance_magnitude"
              class="hexagono-xs"
              v-on="{ ...tooltip }"
            >
              <input
                id="unbalance_magnitude"
                type="radio"
                name="magnitude"
                value="unbalance_magnitude"
              >
              <span
                id="unbalance_magnitude_content"
                class="hexagono-xs-content"
                @click="measurementSelected('unbalance','')"
              >
                <i
                  class="fas fa-caret-left"
                  style="margin-left:-5px"
                />
                <span style="margin-left:5px">U</span>
              </span>
            </label>
          </template>
          <span>Unbalance</span>
        </v-tooltip>
      </v-navigation-drawer>
    </div>
  </div>
</template>
<script>

export default {
  name: 'MeasurementSelector',
  props: {
    sldView: {
      type: Boolean,
      default: false
    },
    topHigh: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedItem: 1,
      items: [
        { text: 'Maximun', abbr: 'max' },
        { text: 'Minimun', abbr: 'min' },
        { text: 'Average', abbr: 'avg' },
        { text: 'PhaseA', abbr: 'phA' },
        { text: 'PhaseB', abbr: 'phB' },
        { text: 'PhaseC', abbr: 'phC' },
        { text: 'PhaseN', abbr: 'phN' }
      ],
      selectedItem1: 1,
      items1: [
        { text: 'PhaseA', abbr: 'phA' },
        { text: 'PhaseB', abbr: 'phB' },
        { text: 'PhaseC', abbr: 'phC' },
        { text: 'PhaseN', abbr: 'phN' },
        { text: 'Total', abbr: 'total' }
      ],
      measurementAbb: {
        voltage: 'V',
        current: 'I',
        activePower: 'P',
        reactivePower: 'Q',
        unbalance: 'U',
        losses: 'L'
      }
    }
  },

  methods: {
    showMagnitudeVertical () {
      const verticalBar = document.getElementById('select_magnitude_vertical')
      if (verticalBar.style.display === '') {
        verticalBar.style.display = 'none'
      } else {
        verticalBar.style.display = ''
      }
    },

    measurementSelected (measurementSelected, submeasurementSelected) {
      const measurement = {
        measurement: measurementSelected,
        submeasurement: submeasurementSelected
      }
      this.$store.dispatch('setElement', { path: 'measurement', value: measurement })

      const verticalBar = document.getElementById('select_magnitude_vertical')
      verticalBar.style.display = 'none'
      const measurementButton = document.getElementById('selected_measurement_content')
      measurementButton.innerHTML = `${this.measurementAbb[measurementSelected]}<span style="font-size:10px">${submeasurementSelected}</span>`
    }
  }

}

</script>

<style scoped>
.map-measurementSelector{
  color:#fff;
  z-index:500;
  position:relative;
  height: 30px;
  line-height: 30px;
  right:-15px
}
.mstophigh {
  top: calc(30px - 100%);
}
.mstoplow {
  top: calc(80px - 100%);
}
.magnitude-submenu {
  left: -7rem;
  float: right;
  top: 20px;
  z-index:460;
}
.selection-tool{
  z-index:500;
  position:absolute;
  top:5px;
  right:3%;
}

.selection-tool-vertical{
  padding-top:12px;
  width:41px;
  z-index:499;
  position:absolute;
  top:44px;
  /*left:40vw;*/
  right:3%;
}
.selection-tool-vertical.dark-theme{
  background:#f4c020;
}
.selection-tool-vertical.left{
  right:46px;
}
</style>
