import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      metersCodeMapTypes: ({ measurements }) => measurements.metersCodeMapTypes
    })
  },
  methods: {
    clickElements (network, elementId, elementType, digitalTwin, hostingCapacityMap, multiselect) {
      if (!multiselect) {
        // unselect the elements selected before
        this.unselectElements()
      }
      if (this.mode === 'element_mode' || network === 'None') {
        const singleElement = document.getElementsByClassName(elementType + elementId)
        if (
          singleElement[0].getAttribute('class').includes('circle') &&
          !hostingCapacityMap
        ) {
          singleElement.forEach((element) => {
            element.classList.add('circle-selected')
          })
        } else if (
          singleElement[0].getAttribute('class').includes('polyline') &&
          (digitalTwin || hostingCapacityMap)
        ) {
          singleElement[0].classList.add('polylineDigitalTwin-selected')
        } else if (
          singleElement[0].getAttribute('class').includes('polyline')
        ) {
          singleElement[0].classList.add('polyline-selected')
        } else {
          console.warn(
            'elements may include a class indicating a proper svg shape'
          )
        }
      } else if (elementType === 'fuse' && !network) {
        const singleElement = document.getElementsByClassName(elementType + elementId)
        if (singleElement[0].getAttribute('class').includes('polyline')) {
          singleElement[0].classList.add('polyline-selected')
        }
      } else {
        const stationElement = document.getElementsByClassName(
          `network${network}`
        )
        stationElement.forEach((element) => {
          if (
            element.getAttribute('class').includes('circle') &&
            !hostingCapacityMap
          ) {
            multiselect
              ? element.classList.toggle('circle-selected')
              : element.classList.add('circle-selected')
          } else if (
            element.getAttribute('class').includes('polyline') &&
            (digitalTwin || hostingCapacityMap)
          ) {
            multiselect
              ? element.classList.toggle('polylineDigitalTwin-selected')
              : element.classList.add('polylineDigitalTwin-selected')
          } else if (element.getAttribute('class').includes('polyline')) {
            multiselect
              ? element.classList.toggle('polyline-selected')
              : element.classList.add('polyline-selected')
          } else {
            console.warn(
              'elements may include a class indicating a proper svg shape'
            )
          }
        })
      }
    },

    unselectElements () {
      let selecteds = document.getElementsByClassName(
        'polylineDigitalTwin-selected'
      )
      while (selecteds.length > 0) {
        this.unclickElements('polylineDigitalTwin-selected')
      }
      selecteds = document.getElementsByClassName('polyline-selected')
      while (selecteds.length > 0) {
        this.unclickElements('polyline-selected')
      }
      selecteds = document.getElementsByClassName('circle-selected')
      while (selecteds.length > 0) {
        this.unclickElements('circle-selected')
      }
    },

    selectElementFromMap (station, elementId, elementType) {
      switch (this.$store.state.measurements.currentSelector &&
          this.$store.state.measurements.currentSelector.short) {
        case 'ST': {
          this.addNewSelectedItem(station, 'station', station, 'ID')
          break
        }
        case 'TR': {
          this.addNewSelectedItem(station, 'station', station, 'STATION')
          break
        }
        case 'SU': {
          this.addNewSelectedItem(station, 'station', station, 'STATION')
          break
        }
        case 'LI': {
          this.addNewSelectedItem(elementId, elementType, station, 'ID')
          break
        }
        case 'SP': {
          const isConnectionPoint = elementType === 'connectionPoint'
          if (isConnectionPoint) {
            this.addNewSelectedItem(elementId, elementType, station, 'CGP')
          }
          break
        }
        case 'SM': {
          const isConnectionPoint = elementType === 'connectionPoint'
          if (isConnectionPoint) {
            this.addNewSelectedItem(elementId, elementType, station, 'CGP')
          }
          break
        }
      }
    },

    addNewSelectedItem (id, type, station, elementIdOptions) {
      let isAlreadySelect = null
      if (this.elementSelected) {
        isAlreadySelect = this.elementSelected.find(
          (element) => element.elementId === id
        )
      }

      if (!isAlreadySelect) {
        const newElements = this.$store.state.measurements.inventory.filter((el) => el[elementIdOptions] === id)
        if (newElements) {
          const elementSelect = this.elementSelected || []
          newElements.forEach((newElement) => {
            elementSelect.push({
              elementId: id,
              elementType: type,
              origin: 'MS_TABLE',
              station
            })

            this.$store.dispatch('setElement', {
              path: 'measurements.selected',
              value: {
                [this.$store.state.measurements.currentSelector &&
                  this.$store.state.measurements.currentSelector.short]:
                  elementSelect
              }
            })

            if (this.$store.state.measurements.currentTab.title === 'Measurements') {
              const types = this.metersCodeMapTypes
              if (!types.includes(newElement.TYPE)) {
                types.push(newElement.TYPE)
              }
              this.$store.dispatch('setElement', {
                path: 'measurements.metersCodeMapeTypes',
                value: types
              })
            }
          })
        }
      }
    }
  }
}
