<template>
  <div>
    <v-expand-transition>
      <v-app-bar
        v-if="hidden"
        color="#272727"
        elevation="2"
        absolute
        height="2"
        width="100%"
      >
        <v-tooltip
          bottom
          open-delay="800"
        >
          <template #activator="{ on }">
            <div
              class="arrow-down"
              v-on="on"
            >
              <v-icon
                class="chevron-down"
                @click="toggleNavbar(false)"
              >
                {{ ChevronDown }}
              </v-icon>
            </div>
          </template>
          <span> <span>{{ $t('map_nav_bar.show_bar') }}</span></span>
        </v-tooltip>
      </v-app-bar>
    </v-expand-transition>
    <v-expand-transition>
      <v-app-bar
        v-if="!hidden"
        color="#27272777"
        elevation="2"
        absolute
        height="60"
        width="100%"
      >
        <v-row class="nav-bar-map">
          <v-col>
            <MapModeSelector
              :mode="mode"
              @change="changeMode"
            />
          </v-col>
          <v-col>
            <MapCaseSelector
              v-if="digitalTwinMap"
              @allowContextMenu="showContextMenu"
              @toggleLoading="toggleLoading"
            />
            <GICascader
              v-if="gridInspectionMap"
              :selected-type-view-map="selectedTypeViewMap"
              :items="typesViewGI"
              item-value="value"
              item-text="text"
              @updateTypeViewMap="updateTypeViewMap"
            />
          </v-col>
          <v-spacer />
          <v-col class="m-top-10">
            <MapSearchBox
              v-if="listElements && functionality !== 'measurements'"
              :networks="networks"
              :stations="listElements.stations"
              :lines="listElements.lines"
              :fuses="listElements.fuses"
              :connection-points="listElements.connectionPoints"
              @selectElement="selectElement"
            />
          </v-col>
          <v-col
            cols="auto"
            class="p-right-0"
          >
            <v-tooltip
              bottom
              open-delay="800"
            >
              <template #activator="{ on }">
                <div
                  v-if="show"
                  class="arrow-up"
                  v-on="on"
                >
                  <v-icon
                    class="chevron-up"
                    @click="toggleNavbar(true)"
                  >
                    {{ ChevronUp }}
                  </v-icon>
                </div>
              </template>
              <span>{{ $t('map_nav_bar.hide_bar') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-app-bar>
    </v-expand-transition>
  </div>
</template>

<script>
import { mdiChevronDoubleDown, mdiChevronDoubleUp } from '@mdi/js'

import MapModeSelector from '@/components/Map/MapModeSelector.vue'
import MapSearchBox from '@/components/Map/MapSearchBox.vue'
import MapCaseSelector from '@/components/Map/MapCaseSelector.vue'
import GICascader from '@/components/GridInspection/GICascader/GICascader.vue'

export default {
  name: 'NavBarMap',
  components: {
    MapModeSelector,
    MapSearchBox,
    MapCaseSelector,
    GICascader
  },
  props: {
    mode: {
      type: String,
      default: ''
    },
    functionality: {
      type: String,
      default: 'default'
    },
    listElements: {
      type: Object,
      default: () => {}
    },
    digitalTwinMap: {
      type: Boolean,
      default: false
    },
    gridInspectionMap: {
      type: Boolean,
      default: false
    },
    typesViewGI: {
      type: Array,
      default: () => []
    },
    selectedTypeViewMap: {
      type: [Array, Number, String],
      default: () => []
    },
    networks: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    ChevronUp: mdiChevronDoubleUp,
    ChevronDown: mdiChevronDoubleDown,
    show: true,
    hidden: false,
    absolute: true,
    modeSeleted: ''
  }),

  watch: {
    mode (val) {
      this.modeSeleted = val
    }
  },
  mounted () {
    this.modeSelected = this.mode
  },

  methods: {
    changeMode (modeSelected) {
      this.$emit('changeMode', modeSelected)
    },
    selectElement (type, id, station, level) {
      this.$emit('selectElement', type, id, station, level)
    },
    showContextMenu (allowed) {
      this.$emit('showContextMenu', allowed)
    },

    toggleLoading (message) {
      this.$emit('toggleLoading', message)
    },
    toggleNavbar (value) {
      this.hidden = value
      this.$emit('toggleNavbar')
    },
    updateTypeViewMap (value) {
      this.$emit('updateTypeViewMap', value)
    }
  }
}
</script>

<style>
.nav-bar-map {
  position: absolute !important;
  top: 0 !important;
  width: 100%;
  z-index: 500;
  height: 100px !important;
}

.v-sheet.v-app-bar.v-toolbar {
  z-index: 500;
}

.m-top-10 {
  margin-top: 10px !important;
}
.p-right-0 {
  padding-right: 0;
}

.hexagono-xs {
  position: relative;
  height: 52px;
  width: 41px;
  display: inline-block;
  margin: 0;
  background: #000;
}

.hexagono-xs-content.onmap {
  color: #f4c020;
}

.hexagono-xs-content.selected {
  color: #000;
}

.hexagono-xs.onmap.selected {
  background: #f4c020;
}
.hexagono-xs.selected:before {
  background: #f4c020;
}
.hexagono-xs:hover {
  background: #f4c020;
  cursor: pointer;
}

.hexagono-xs:before {
  background: #000;
  position: absolute;
  content: '';
  top: 2px; /* border width */
  left: 2px; /* border width */
  height: calc(100% - 4px); /* 100% - (2 * border width) */
  width: calc(100% - 4px); /* 100% - (2 * border width) */
}

.hexagono-xs,
.hexagono-xs:before {
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hexagono-xs-content {
  position: absolute;
  z-index: 1000;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  font-weight: bold;
  line-height: 25px;
  font-size: 0.95rem;
}

.hexagono-xs-icon {
  color: #f4c020;
}

.hexagono-xs-icon.selected {
  color: #000;
}
.custom-container {
  top: 24px !important;
  left: 157px !important;
}
.arrow-up {
  position: relative;
  top: 42px;
  z-index: 501;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 18px solid #000;
}
.chevron-up {
  position: relative;
  bottom: -2px;
  right: 12px;
  color: #f4c020 !important;
}

.arrow-down {
  position: absolute;
  right: 5px;
  top: 2.2px;
  z-index: 501;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 18px solid #f4c020;
}
.chevron-down {
  position: relative;
  bottom: 22px;
  right: 12px;
  color: #000 !important;
}
.chevron::before,
.chevron::after {
  border-style: solid;
  border-width: 5px 5px 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}
.gg-chevron-double-up {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
}
.gg-chevron-double-up::after,
.gg-chevron-double-up::before {
  content: '';
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  border-top: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: 7px;
  bottom: 3px;
}
.gg-chevron-double-up::after {
  bottom: 8px;
}
</style>
