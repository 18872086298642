<template>
  <div v-if="switchBoxesData">
    <l-polyline
      v-for="(switchbox,index) in switchBoxesData"
      :key="index"
      :lat-lngs="switchbox.latLngs"
      :color="'#3146A8'"
      :class-name="'networkNone switchBox' + switchbox.NAME + ' polyline'"
    >
      <l-tooltip>
        <strong>{{ switchbox.NAME }}</strong>
      </l-tooltip>
    </l-polyline>
    <switchBoxFuse
      v-if="fuses"
      :mode="mode"
      :switch-box-fuses-data="fuses"
      @click="click"
      @hover="hover"
      @leave="leave"
    />
  </div>
</template>

<script>
import { LTooltip, LPolyline } from 'vue2-leaflet'

// import StationFuseLayer from '@/components/Map/StationFuseLayer'
import mapElementOpacityMixin from '@/mixins/map/mapElementOpacityMixin'
import mapElementColorMixin from '@/mixins/map/mapElementColorMixin'
import SwitchBoxFuse from '@/components/Map/SwitchBoxFuseLayer.vue'

export default {
  name: 'SwitchBox',
  components: {
    LPolyline,
    LTooltip,
    SwitchBoxFuse
  },
  mixins: [mapElementOpacityMixin, mapElementColorMixin],
  props: {
    switchBoxesData: {
      type: Object,
      required: true
    },
    colorNetwork: {
      type: String,
      default: '#00d400'
    },
    color: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: ''
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    contextMenu: {
      type: Boolean,
      default: false
    },
    level: {
      type: String,
      default: ''
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      fuseColor: this.color,
      blackColor: '#000000',
      whiteColor: '#ffffff'
    }
  },
  computed: {
    fuses () {
      const fuseObjects = []
      this.switchBoxesData.forEach(switchbox => {
        switchbox.fuses?.forEach(fuse => {
          fuseObjects.push(fuse)
        })
      })
      return fuseObjects
    }
  },
  watch: {
    colorStation (newColor) {
      if (newColor === '#f4c020') {
        this.fuseColor = '#f4c020'
      } else {
        this.fuseColor = this.color
      }
    }
  },
  methods: {
    handleContextmenu (e, fuse) {
      if (this.contextMenu) {
        const contextMenuPayload = {
          x: e.containerPoint.x + 75,
          y: e.containerPoint.y + 75,
          toggle: 'contextMenu'
        }

        const fusePayload = {
          path: 'contextMenuElement',
          value: { type: 'contextMenuFuse', info: fuse }
        }

        this.$store.dispatch('setNewPosition', contextMenuPayload)
        this.$store.dispatch('setElement', fusePayload)
      }
    },
    click (level, network, elementId, elementType) {
      this.$emit('click', level, network, elementId, elementType)
    }
  }
}
</script>
