export default {
  appname: 'Nombre de la aplicación',
  lang: 'Idioma',
  ok: 'Ok',
  add: 'Añadir',
  cancel: 'Cancelar',
  close: 'Cerrar',
  remove: 'Eliminar',
  delete: 'Borrar',
  delete_all: 'Borrar todo',
  open: 'Abrir',
  new: 'Nuevo',
  edit: 'Editar',
  reset: 'Restablecer',
  create: 'Crear',
  confirm: 'Confirmar',
  name: 'Nombre',
  measurements: 'Medidas',
  events: 'Eventos',
  select_all: 'Seleccionar todas',
  select_by_id: 'Seleccionar por id',
  email: 'Email',
  grid_inspector_title: 'Grid Inspector',
  digital_twin: 'Digital Twin',
  grid_monitor_title: 'Grid Monitor',
  fetching: 'Obteniendo datos',
  plots: 'Gráficos',
  plot_one: 'Gráfico',
  info: 'Info',
  phase: 'Fase',
  unit: 'Unidades',
  power: 'Potencia',
  description: 'Descripción',
  optional: 'opcional',
  no_data_available: 'Sin datos disponibles',
  consumption: 'Consumo',
  generation: 'Generación',
  start_date: 'Fecha inicio',
  end_date: 'FEcha fin',
  creation_date: 'Fecha de creación',
  expiration_date: 'Fecha de expiración',
  installation_date: 'Fecha de instalación',
  mode: 'Modo',
  and: 'y',
  type: 'Tipo',
  click_to_change: 'Click para cambiar',
  voltage: 'Tensión',
  current: 'Corriente',
  apparent_power: 'Potencia aparente',
  active_power: 'Potencia Activa',
  reactive_power: 'Potencia Reactiva',
  losses: 'Pérdidas',
  heatmap: 'Mapa de calor',
  cable: 'Cable',
  length: 'Longitud',
  sync_map: 'Sincronizar mapa',
  formula: 'Fórmula',
  hours: 'Horas',

  validations: {
    item_required: 'Campo obligatorio',
    equal_or_greater: 'El valor debe ser igual o superior a',
    item_between: 'El valor debe estar entre',
    integer: 'El valor debe ser un entero',
    number: 'El valor debe ser un número',
    not_special_character: 'Caracteres especiales no permitidos'
  },

  login: {
    password: 'Contraseña',
    login: 'Iniciar sesión',
    error_message:
      'Ha introducido una dirección de correo electrónico o una contraseña incorrectas.'
  },

  grid_set: {
    title: 'Establece la red a visualizar',
    by_area: 'Por zona',
    by_element: 'Por elemento',
    set_area: 'Establecer red',
    completed_fields: 'Campos completados',
    area_level: 'nivel',
    error:
      'Se ha producido un error al acceder a los datos. Por favor, inténtelo de nuevo.',
    type_element: 'Tipo de elemento*',
    element_id: 'Id de elemento*',
    element_name: 'Nombre del elemento*',
    radius: 'Radio',
    getting_grid_msg: 'Obteniendo datos para la red seleccionada'
  },

  grid: {
    network: 'Subred',
    networks: 'Subredes',
    meter: 'Medidor',
    meters: 'Medidores',
    station: 'Estacion',
    stations: 'Estaciones',
    line: 'Linea',
    lines: 'Lineas',
    feeder: 'Alimentador',
    feeders: 'Alimentadores',
    connection_point: 'Acometida',
    connection_points: 'Acometidas',
    transformer: 'Transformador',
    transformers: 'Transformadores',
    supervisors: 'Supervisores',
    advanced_supervisors: 'Supervisores Avanzados',
    supply_points: 'Puntos de Suministro',
    smart_meters: 'Medidores inteligentes'
  },

  nav_bar: {
    title: 'Inicio',
    configure: 'Configure su red',
    change_password: 'Cambiar contraseña',
    logout: 'Cierre de sesión',
    load_data: 'Importar datos',
    set_grid: 'Establecer red',
    admin_panel: 'Panel de administrador'
  },

  admin: {
    confirm_title: 'Confirmar',
    confirm_message: 'El usuario será eliminado',
    user_deleted: 'El usuario ha sido eliminado',
    reset_password: 'Restablecer contraseña',
    delete_user: 'Borrar usuario',
    user_selected: 'Usuario seleccionado:',
    indication: 'indica un campo obligatorio',
    department: 'Departamento',
    username: 'Nombre de usuario',
    email_hint: 'Será su correo electrónico de acceso',
    last_name: 'Apellido',
    last_name_hint: 'Su apellido',
    first_name: 'Nombre',
    first_name_rule: 'El nombre es obligatorio',
    add_user: 'Añadir usuario',
    company: 'Compañía',
    last_login: 'Última conexión',
    reset_password_message: 'Restablecer contraseña. La envía al usuario',
    add_user_message:
      'Usuario creado con esta contraseña. Cópiala y entra con tu email y esta contraseña',
    rows_per_page: 'Filas por página'
  },

  grid_surveyor: {
    loading_grid_surveyor_msg: 'Cargando panel de control',
    lines: 'Líneas',
    connection_points: 'Acometidas',
    fuses: 'Fusibles',
    clients: 'Clientes'
  },
  substations: {
    station_count: 'Número de Estaciones',
    transformer_count: 'Número de transformadores',
    station_count_per_power: 'Número de estaciones por potencia (kVA)',
    station_count_per_feeder_count:
      'Número de estaciones por número de alimentadores',
    transformer_count_per_feeder_count:
      'Número de transformadores por número de alimentadores',
    transformer_count_per_power: 'Número de transformadores por potencia (kVA)',
    installed_stations_per_year: 'Estaciones instaladas por año',
    connection_per_station: 'Conexiones por estación',
    clients_per_station: 'Clientes por estación',
    km_per_station: 'Km de línea por estación'
  },
  lines: {
    km_of_line: 'Km de línea',
    km_per_station: 'Km por estación',
    m_per_connection: 'm por conexión',
    m_per_client: 'm por cliente',
    peak_current: 'Corriente de pico',
    underground: 'Bajo tierra',
    overhead: 'Sobrecarga'
  },
  connection: {
    connection_points: 'N. Acometidas',
    avg_connection_points_per_station: 'Promedio de acometidas por estación',
    avg_meters_per_connection_points:
      'Promedio de contadores por puntos de conexión'
  },
  fuses: {
    fuses_count: 'N. Fusibles',
    feeders_count: 'N. Alimentadores',
    maneuvers_count: 'N. Maniobras'
  },
  clients: {
    clients_count: 'N. Clientes'
  },

  map_nav_bar: {
    hide_bar: 'Ocultar barra de mapa',
    show_bar: 'Mostrar barra de mapa',
    network_mode: 'Modo subred',
    element_mode: 'Modo elemento',
    change_to_network_mode: 'Cambiar a modo subred',
    change_to_element_mode: 'Cambiar a modo elemento'
  },
  map: {
    sld: 'Diagrama unifilar',
    map_view: 'Mapa'
  },
  map_info: {
    area: 'Zona',
    volt_level: 'Nivel Volt.',
    low: 'Baja',
    coordinates: 'Coordenadas',
    element_hovered: 'Sobre elemento'
  },

  grid_inspector: {
    loading_msg: 'Cargando Inspector de Red',
    raw: 'Vista simple',
    connectivity: 'Vista de conectividad',
    hosting_capacity: 'Vista hosting capacity',
    hcm_generation: 'Generación',
    hcm_consumption: 'Consumo'
  },
  grid_panel: {
    general_information: 'Información general',
    meters_information: 'Información de medidores',
    transformer_information: 'Información de transformador',
    code: 'Código',
    max_current: 'Corriente max.',
    num_stations: 'N.Estaciones',
    num_transformers: 'N.Transformadores',
    num_connection_points: 'N.Acometidas',
    num_clients: 'N.Clientes',
    total_length_conductor: 'Longitud total de conductor',
    tap_max: 'Toma max.',
    tap_min: 'Toma min.',
    tap_positions: 'Posiciones de toma',
    voltage_regulator: 'Regulador de tensión',
    num_station_sup: 'N.Supervisores de estación',
    num_line_sup: 'N.Supervisores de líne',
    num_lines: 'N.Líneas',
    num_active_lines: 'N.Líneas activas'
  },

  hcm: {
    loading_msg: 'Cargando mapa de capacidades',
    consumption: 'Consumo',
    not_available: 'No disponible',
    reserves: 'Reservas',
    reserve: 'Reserva',
    consumption_hc: 'Capacidad de consumo',
    generation_hc: 'Capacidad de generación',
    consumption_reserves: 'Resevas de consumo',
    generation_reserves: 'Reservas de generación',
    full_reserve: 'Reserva completa',
    hc_id: 'Capacidad id',
    available_capacity: 'Capacidad disponible',
    reserve_period_month: 'Período de reserva (meses)',
    months: 'meses',
    description_optional: 'Descripción (opcional)',
    worst_case_txt: 'Mostrar peor caso'
  },

  dt: {
    digital_twin_project: 'Proyecto Gemelo Digital',
    my_projects: 'Mis proyectos',
    new_connections: 'Nuevas conexiones',
    planning: 'Planificación',
    loading_projects: 'Cargando proyectos',
    simulation_date: 'Fecha de simulación',
    number_of_cases: 'Número de casos',
    penetration: 'penetración',
    created_on: 'Creado el',
    basic_info: 'Información básica',
    select_area: 'Seleccionar zona',
    power_constraints: 'Limitaciones de la calidad de la energía',
    max_voltage_limit: 'Máx. Límite de tensión',
    min_voltage_limit: 'Mín. Límite de tensión',
    max_voltage_unbalance: 'Máx. Desbalanceo',
    max_transformer_loading: 'Máx. Carga del transformador',
    max_current_on_lines: 'Máx. Corriente en las líneas',
    or: 'o',
    pvs_settings: 'Ajustes de panel fotovoltaico',
    percentage_of_customers_with_pv: 'Porcentaje de clientes con PF',
    initial_pv:
      'Penetración inicial de la energía fotovoltaica en la red de baja tensión',
    pv_power_steps:
      'Incrementos de los pasos de potencia FV para la simulación',
    generating_dt: 'Generando el gemelo digital',
    delete_confirm: '¿Está seguro de que desea eliminar este proyecto?',
    back_to_form: 'Volver al formulario',
    loading_dt: 'Cargando Digital Twin',
    project: 'Proyecto',
    select_project: 'Seleccionar proyecto',
    simulate: 'Simular',
    step_time: 'Tiempo de paso',
    created_date: 'Fecha de creación',
    base_power: 'Potencia base',
    project_info: 'Información sobre el proyecto Digital Twin',
    remove_client: 'Eliminar cliente',
    client_removed: 'Cliente eliminado',
    change_phase: 'Cambiar fase',
    phase_connection: 'Conexión de fase',
    no_meters_in_connection_point: 'No hay medidores en este connection point',
    active: 'Activo',
    reactive: 'Reactivo'
  },
  new_connections: {
    editing: 'Editando',
    new_case: 'Nuevo caso',
    case_name: 'Nombre del caso',
    reference_case: 'Selecciona caso de referencia',
    uniform_load_scale: 'Escala de carga uniforme',
    add_element: 'Añadir elemento',
    add_pv: 'Añadir PF',
    add_hp: 'Añadir BC',
    add_ev: 'Añadir VE',
    add_custom_profile: 'Añadir perfil personalizado',
    add_conn_point: 'Añadir acometida',
    remove_client: 'Eliminar cliente',
    change_phase: 'Cambiar fase',
    disable: 'Deshabilitar',
    pv_conn_params: 'Parámetros de conexión de PF',
    n_pvs: 'Número de PFs',
    phase_connection: 'Fase de conexión',
    installed_peak_power: 'Potencia máxina instalada',
    max_q_injection: 'Máxima inyección de poterncia reactiva',
    ev_conn_params: 'Parámetros de conexión de VE',
    n_evs: 'Número de cargadores de VE',
    ev_nominal_power: 'Potencia nóminal de cargador de VE',
    ev_battery_capacity: 'Capacidad media de energía de batería de VE',
    ev_power_factor: 'Factor de potencia de cargador de VE',
    std: 'STD',
    ev_daily_distance: 'Distancia media diaria recorrida en VE',
    ev_charging_time: 'Rango horario de carga de VE (horas)',
    hp_conn_params: 'Parámetros de conexión de bomba de calor',
    n_hps: 'Número de bombas de calor',
    hp_nominal_power: 'Potencia nominal de la bomba de calor',
    hp_power_factor: 'Factor de poder de la bomba de calor',
    hp_nominal_factor: 'Factor nominal de la bomba de calor',
    hp_cop: 'Coeficiente de rendimiento de la bomba de calor (COP)',
    current_changes: 'Cambios actuales',
    hide_details: 'Ocultar detalles',
    see_details: 'Mostrar detalles',
    edit_element: 'Editar elemento',
    quantity: 'Cantidad',
    changes: 'Cambios',
    simulating_case_msg: 'Creando y simulando caso',
    line_full: 'Línea llena de acometidas',
    toggle_state: 'Cambiar estado',
    confirm_toggle_state__msg:
      'Se va a cambiar el estado del elemento. ¿Está seguro?',
    new_connection_point: 'Nueva acometida',
    max_real_p: 'Potencia real máxima (kW)'
  },
  planning: {
    project_name: 'Proyecto Nombre',
    violation_percentage: 'Violación Porcentaje',
    level_of_penetration: 'Nivel de penetración',
    phA_undervoltage: 'Subtensión de fase A',
    phB_undervoltage: 'Subtensión de fase B',
    phC_undervoltage: 'Subtensión de fase C',
    phA_overvoltage: 'Sobretensión de fase A',
    phB_overvoltage: 'Sobretensión de fase B',
    phC_overvoltage: 'Sobretensión de fase C',
    voltage_negative_sequence: 'Tensión de secuencia negativa',
    voltage_zero_sequence: 'Tensión de secuencia cero',
    phA_overcurrent: 'Sobrecorriente de fase A',
    phB_overcurrent: 'Sobrecorriente de fase B',
    phC_overcurrent: 'Sobrecorriente de fase C',
    phN_overcurrent: 'Sobrecorriente de fase N',
    overloading_transformer: 'Transformador de sobrecarga'
  },
  kpi: {
    apparent_power_avg: 'Potencia aparente: Avg',
    apparent_power_phase_share: 'Potencia aparente: Porcentaje de fases (Avg)',
    capacity_factor: 'Coeficiente de capacidad',
    current_avg: 'Corriente: Avg',
    current_max: 'Corriente: Max',
    current_min: 'Corriente: Min',
    current_unbalance_avg: 'Corriente: Desbalanceo (Avg)',
    current_unbalance_rate: 'Corriente: Desbalanceo (Tasa)',
    energy_losses: 'Pérdidas de energía',
    over_utilization_rate: 'Tasa de sobreutilización',
    overload_rate: 'Tasa de sobrecarga',
    ev: 'Vehiculos eléctricos',
    pv_systems: 'Sistemas fotovoltaicos',
    hps: 'Bombas de calor',
    load_factor: 'Coeficiente de carga',
    power_factor_avg: 'Factor dep potencia (Avg)',
    voltage_unbalance_avg: 'Desbalanceo de tensión (Avg)',
    voltage_unbalance_rate: 'Desbalanceo de tensión (Tasa)',
    voltage_qualified_rate: 'Tensión: Tensión noinal (Tasa)',
    voltage_overvoltage: 'Tensión: Sobretensión (Tasa)',
    voltage_undervoltage: 'Tensión: Subtensión (Tasa)',
    voltage_min: 'Tensión: Min',
    voltage_max: 'Tensión: Max',
    voltage_avg: 'Tensión: Avg',
    uniform_load_scale: 'Curva de duración de carga',
    ldc_current: 'Curva de duración de carga: Corriente',
    ldc_voltage: 'Curva de duración de carga: Potencia',
    unbalanced_factor: 'Factor de desbalanceo',
    unbalance_factor_current: 'Factor de desbalanceo de corriente',
    unbalance_factor_Voltage: 'Factor de desbalanceo de tensión',
    vl: 'Longitud de tensión',
    energy_supplied_station: 'Energy supplied (by Station)',
    energy_supplied_pv: 'Energy supplied (by PVs)',
    duration_p_out_max: 'Duración: P Out Max',
    duration_p_in_max: 'Duración: P In Max',
    duration_q_out_max: 'Duración: Q Out Max',
    duration_q_in_max: 'Duración: Q In Max',
    power_p_out_max: 'Potencia: P Out Max',
    power_p_in_max: 'Potencia: P In Max',
    power_q_out_max: 'Potencia: Q Out Max',
    power_q_in_max: 'Potencia: Q In Max'
  },
  plot: {
    phase_a: 'FaseA',
    phase_b: 'FaseB',
    phase_c: 'FaseC',
    phase_n: 'FaseN',
    power_kw: 'Potencia(kW)',
    voltage_v: 'Tensión(V)',
    current_a: 'Corriente(A)',
    time_h: 'Tiempo(h)',
    subscription_limit: 'Límites de suscripción',
    consumption_limit: 'Consumo al límite de la red',
    injection_limit: 'Inyección al límite de red'
  },

  importer: {
    title: 'Importador de archivos',
    reference_column: 'Columna Referencia',
    time_column: 'Columna Instante',
    signal: 'Señal',
    measurement_column: 'Columna Medida',
    load_file: 'Cargar archivo',
    measurement_file_sent: 'Archivo de medidas enviado',
    topology: 'Topología',
    custom_profile: 'Perfil personalizado',
    parsing_file: 'Analizando archivo',
    uploading_file: 'Cargando archivo',
    only_csv_files_allowed: 'Sólo se admiten archivos .csv',
    at_least_two_columns: 'Al menos dos columnas (activo y reactivo)',
    unit_expected: 'unidad esperada'
  },

  grid_monitor: {
    select_all_stations: 'Seleccionar todas las estaciones',
    empty_tree:
      'No hay datos disponibles. Seleccione una opción válida para ver la información aquí',
    search: 'Buscar',
    assets: 'Activos',
    forecast: 'Previsión',
    date_start: 'Fecha de inicio',
    date_end: 'Fecha fin',
    aggregation: 'Agregación',
    aggregation_name: 'Nombre de agregación',
    enter_name: 'Introduzca un nombre',
    operator: 'Operador',
    send_to_plot: 'Enviar gráfico',
    sending: 'Enviando...',
    total: 'Total',
    variables: 'Variables',
    expression_calculations: 'Cálculo de expresiones',
    operation_between_factor: 'Operación entre factor',
    operation: 'Operación',
    addition: 'Summa',
    subtraction: 'Resta',
    multiplication: 'Multiplicación',
    division: 'División',
    factor: 'Factor',
    actions: 'Acciones',
    draw: 'Dibujar',
    expression: 'Expresión',
    depth: 'Profundidad',
    sampling_time: 'Tiempo de muestreo'
  }
}
