<template>
  <div class="plotStation">
    <Plotly
      :data="getDataPlot"
      :layout="getLayoutPlot"
      :display-mode-bar="false"
      :displaylogo="false"
    />
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
export default {
  name: 'TIAMonitoringPlotStation',

  components: {
    Plotly
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }

  },
  data: () => ({
    heightPlot: null,
    nonFlexValue: [],
    flexValue: [],
    outLimitsValue: [],
    DOEValue: []
  }),

  computed: {
    getDataPlot () {
      // Variables for Plot

      const datos = []
      let measurement
      const proxyData = new Proxy(this.data, {
        // Handler del proxyData
        get (target, prop) {
          return target[prop]
        }
      })
      // Obtener las claves del proxyData
      const measurements = Object.keys(proxyData) // También puedes usar Reflect.ownKeys(proxyData)
      // Recorrer las claves y acceder a los valores correspondientes
      // const maxPwr = proxyData.max_pwr.value
      // const nonFlex = proxyData.nonflex_pwr.value
      // const flex = this.minusArrays(proxyData.flex_pwr.value, proxyData.nonflex_pwr.value)
      measurements.forEach((measur) => {
        let valueY
        let nameSerie
        let typeSerie
        let lineType

        if (proxyData[measur].frontend_name === 'real full baseline') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          nameSerie = proxyData[measur].frontend_name
          typeSerie = 'lines'
          lineType = {
            color: 'rgba(171, 235, 198 , 0.2)',
            dash: 'solid',
            width: 3
          }
        } else if (proxyData[measur].frontend_name === 'real flex baseline') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          typeSerie = 'lines'
          lineType = {
            color: 'rgba(158,202,225, 0.4)',
            dash: 'solid',
            width: 3
          }
        } else if (proxyData[measur].frontend_name === 'forecasted full baseline') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          typeSerie = 'lines'
          lineType = {
            color: 'rgba(171, 235, 198 , 1)',
            dash: 'solid',
            width: 3
          }
        } else if (proxyData[measur].frontend_name === 'forecasted flex baseline') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          typeSerie = 'lines'
          lineType = {
            color: 'rgba(46, 134, 193, 1)',
            dash: 'longdash',
            width: 3
          }
        } else if (proxyData[measur].frontend_name === 'forecasted non-flex baseline') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          typeSerie = 'lines'
          lineType = {
            color: 'rgba(244, 208, 63 , 1)',
            dash: 'longdash',
            width: 3
          }
        } else if (proxyData[measur].frontend_name === 'LimitXones(size(Available_flex))') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          typeSerie = 'lines'
          lineType = {
            color: 'red',
            dash: 'dot',
            width: 3
          }
        } else if (proxyData[measur].frontend_name === 'power limit') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          typeSerie = 'lines'
          lineType = {
            color: 'red',
            dash: 'line',
            width: 3
          }
        } else if (proxyData[measur].frontend_name === 'Power limit') {
          nameSerie = proxyData[measur].frontend_name
          valueY = proxyData[measur].value
          typeSerie = 'lines'
          lineType = {
            color: 'red',
            dash: 'line',
            width: 3
          }
        }

        measurement = {
          x: proxyData[measur].time,
          y: valueY,
          type: typeSerie,
          mode: 'lines',
          name: nameSerie,
          line: lineType,
          visible: true
        }

        datos.push(measurement)
      })

      return datos
    },

    getLayoutPlot () {
      const layout = {
        title: '',
        legend: {
          orientation: 'h',
          entrywidth: 200,
          valign: 'top',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
          bgcolor: '#272727',
          font: {
            size: '12',
            family: 'Faktum Light'
          }

        },
        barmode: 'bar',
        height: this.heightPlot,
        margin: {
          l: 60,
          r: 10,
          b: 65,
          t: 20,
          pad: 5
        },
        xaxis: {
          title: 'Time (h)',
          linecolor: '#fff',
          nticks: 10,
          gridcolor: '#595959',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 12
          }
        },
        yaxis: {
          title: '(KW)',
          linecolor: '#fff',
          gridcolor: '#595959'
        },
        paper_bgcolor: '#272727',
        plot_bgcolor: '#272727',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }
  },

  mounted () {
    this.heightPlot = window.innerHeight * 0.40
  },
  methods: {

    minusArrays (array1, array2) {
      if (array1.length !== array2.length) {
        throw new Error('Arrays must have the same lenght.')
      }
      const resultado = []
      for (let i = 0; i < array1.length; i++) {
        const resta = array1[i] - array2[i]
        resultado.push(resta)
      }

      return resultado
    },
    calculateFlexAndOutLimits (nonFlex, flex, maxPwr) {
      const flexValues = []
      const DOEValues = []
      const outLimitsValues = []

      for (let i = 0; i < nonFlex.length; i++) {
        const totalPower = nonFlex[i] + flex[i]

        if (totalPower <= maxPwr[i]) {
          // If the sum of nonFlex and flex is less than or equal to maxPwr, the value is equal to the value of flex.
          flexValues.push(flex[i])
          DOEValues.push(maxPwr[i] - totalPower)
          outLimitsValues.push(0)
        } else {
          // If the sum of nonFlex and flex is greater than maxPwr, we calculate the value of flexValues and outLimitsValues
          flexValues.push(maxPwr[i] - nonFlex[i])
          DOEValues.push(0)
          outLimitsValues.push(totalPower - maxPwr[i])
        }
      }
      this.outLimitsValue = outLimitsValues
      this.DOEValue = DOEValues
      return flexValues
    }

  }
}
</script>

<style scoped>
.plotStation{
  margin-top: 65px!important;
}

</style>
