import mapElementDisableMixin from '@/mixins/map/mapElementDisableMixin'

export default {
  mixins: [mapElementDisableMixin],
  methods: {
    mouseleave (target, elementHovered, elementType, level, network, isStationElement = false) {
      const isElementDisabled = this.disabledElement(network)
      if (isElementDisabled) return null

      let isCPElementDisabled = false
      if (
        isStationElement &&
        this.$store.state.measurements.currentTab?.title === 'Measurements' &&
        this.$store.state.measurements.metersCodeMapTypes.length
      ) {
        const element = this.$store.state.measurements.inventory.find(
          (el) => el.CGP === elementHovered
        )
        if (element) {
          isCPElementDisabled = this.isItemDisabled(element.TYPE)
        }
      }
      if (isCPElementDisabled) return null

      if (
        this.mode === 'element_mode' &&
        !this.digitalTwinMap &&
        !this.hostingCapacityMap &&
        this.color === undefined
      ) {
        target.setStyle({
          color: this.colorStation
        })
      } else if (
        (this.mode === 'element_mode' && !this.digitalTwinMap && !this.hostingCapacityMap) ||
        (this.mode === 'network_mode' && elementType === 'fuse' && !network)
      ) {
        // this is the color set for the fuses
        target.setStyle({
          color: this.color
        })
      } else if (
        this.mode === 'element_mode' &&
        (this.digitalTwinMap || this.hostingCapacityMap)
      ) {
        target.setStyle({
          weight: isStationElement ? 6 : 3
        })
      } else if (isStationElement && this.hostingCapacityMap) {
        target.setStyle({
          weight: 6
        })
      } else {
        level && this.$emit('leave', level, network)
      }
      this.$store.dispatch('setElement', { path: 'elementHovered', value: '' })
    }
  }
}
