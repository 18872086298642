<template>
  <div class="substations">
    <!--CONNECTION POINTS-->
    <div
      id="connectionPoints"
      class="dashboard-col"
    >
      <div
        class="card dark-theme"
        style="background-color:#1F1F1F!important;padding:10px;"
      >
        <v-tooltip right>
          <template #activator="{ on }">
            <v-img
              src="@/assets/images/dbcgps.png"
              width="50"
              alt="Connections Points"
              v-on="on"
            />
          </template>
          <span>{{ $t('grid_surveyor.connection_points') }}</span>
        </v-tooltip>
        <!-- <img src="@/assets/images/dbcgps.png" alt="Connections Points" width="50" > -->
        <div
          class="row "
          style="margin-left:70px;"
        >
          <div class=" dato-numerico col-12">
            <div class="dato-numerico">
              <div
                id="cp"
                class="numero dark-theme"
              >
                {{ cp }}
              </div>
              <div class="texto dark-theme">
                {{ $t('connection.connection_points') }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="row "
          style="margin-left:70px;"
        >
          <div class=" dato-numerico col-12">
            <div class="dato-numerico">
              <div
                id="cpcp"
                class="numero dark-theme"
              >
                {{ cpcp }}
              </div>
              <div class="texto dark-theme">
                {{ $t('connection.avg_connection_points_per_station') }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="row "
          style="margin-left:70px;"
        >
          <div class=" dato-numerico col-12">
            <div class="dato-numerico">
              <div
                id="mtcp"
                class="numero dark-theme"
              >
                {{ mtcp }}
              </div>
              <div
                class="texto dark-theme"
                style="margin-bottom:50px;"
              >
                {{ $t('connection.avg_meters_per_connection_points') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getData from '@/mixins/dashboardGetData'

export default {
  name: 'Connections',
  mixins: [getData],
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    cp: '0',
    cpcp: '0',
    mtcp: undefined
  }),

  mounted () {
    // count statistics - FUSES
    // console.log(this.info);
    this.cp = this.info.statistics_connection_points.countCGP
    this.cpcp = this.info.statistics_connection_points.meanCGPbyCT.toFixed(2)
    this.mtcp = this.info.statistics_connection_points.meanMeterbyCGP
      ? null
      : 0
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.substations{
  background-color: rgb(53, 49, 49);
  /* height: 63vh; */
}
h1{
  color:#F4C020;
}
</style>
