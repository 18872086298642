import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      contextMenuElement: ({ contextMenuElement }) => contextMenuElement.info,
      DTResults: ({ DTResults }) => DTResults,
      DTProject: ({ DTProject }) => DTProject,
      DTChanges: ({ DTChanges }) => DTChanges
    })
  },
  methods: {
    getLineBuses (lineIndex) {
      // IMPORTANT: Bus = node in the way that we use it. The good term is bus, because in reality bus can has more than one nodes
      const busIdSource = this.DTResults.Results.Con_Src[lineIndex]
      const busIdDestiny = this.DTResults.Results.Con_Dst[lineIndex]

      const busSrcX = this.DTResults.Results.Bus_X[busIdSource]
      const busSrcY = this.DTResults.Results.Bus_Y[busIdSource]

      const busDstX = this.DTResults.Results.Bus_X[busIdDestiny]
      const busDstY = this.DTResults.Results.Bus_Y[busIdDestiny]

      return { busIdSource, busIdDestiny, busSrcX, busSrcY, busDstX, busDstY }
    },
    getLineOccupiedConnectionPoints () {
      const lineIndex = this.DTResults.Results.GetLineIndex(this.contextMenuElement.ID)
      const { busIdSource, busIdDestiny, busSrcX, busSrcY, busDstX, busDstY } = this.getLineBuses(lineIndex)

      // 1. To know the free buses in the line we need the OCCUPIED in this line
      this.lineFull = false
      let lineOccupiedConnectionPoints = []

      this.DTProject.networksInfo.forEach(voltageLevel =>
        voltageLevel.networks.forEach(network => {
          // If distance is less than 1 meter it is because it is the same. Some are rounded
          lineOccupiedConnectionPoints = lineOccupiedConnectionPoints.concat(network.connection_points.filter((cp) => {
            // d=√((x_2-x_1)²+(y_2-y_1)²)
            return (Math.sqrt(Math.pow(busSrcX - cp.X, 2) + Math.pow(busSrcY - cp.Y, 2)) < 1 || Math.sqrt(Math.pow(busDstX - cp.X, 2) + Math.pow(busDstY - cp.Y, 2)) < 1)
          }))
        })
      )

      // 2. To know also the connections points that have been added now
      const coincidentExistingNodes = this.DTChanges.CGP_New.Node.filter(node => {
        return (busIdSource === node || busIdDestiny === node)
      })

      const occupiedNewNodes = []
      coincidentExistingNodes.forEach(node => {
        const existingNode = {
          ID: 'Node' + node,
          X: this.DTResults.Results.Bus_X[node],
          Y: this.DTResults.Results.Bus_Y[node]
        }
        occupiedNewNodes.push(existingNode)
      })
      lineOccupiedConnectionPoints = lineOccupiedConnectionPoints.concat(occupiedNewNodes)

      // 3. Delete repeated connection points (Could be in different networks)
      const hash = {}
      lineOccupiedConnectionPoints = lineOccupiedConnectionPoints.filter(function (current) {
        const exists = !hash[current.ID]
        hash[current.ID] = true
        return exists
      })

      return lineOccupiedConnectionPoints
    }
  }
}
