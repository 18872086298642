<template>
  <div
    class="px-table-root"
  >
    <div
      class="px-table px-table-border-around"
      :style="extraStyles"
    >
      <CMTableChip
        :chips="chips"
        :filters="filters"
        @onUpdateKey="onUpdateKey"
      />
      <div class="px-table-container px-table-enable-cell-selection">
        <div class="px-table-content-wrapper">
          <table
            aria-label="Plexi UI Data Table"
            class="px-table-content px-table-border-x"
          >
            <CMTableHeader
              :show-select="showSelect"
              :multiple-select="multipleSelect"
              :header-keys="headerKeys"
              :is-selected-all="isSelectedAll"
              :header-config="headerConfig"
              :custom-title="customTitle"
              :filters="filters"
              :is-any-item-enabled="isAnyItemEnabled"
              @onToggleCurrentFilter="onToggleCurrentFilter"
              @onToggleSelectAll="onToggleSelectAll"
            />

            <CMTableBody
              :slots="slots"
              :selected-items="selectedItems"
              :filtered-data="filteredData"
              :checkboxes-parent="checkboxes"
              :show-select="showSelect"
              :multiple-select="multipleSelect"
              :page-index="pageIndex"
              :page-size="pageSize"
              :hidden-cols="hiddenCols"
              :is-any-item-enabled="isAnyItemEnabled"
              @onItemSelected="onItemSelected"
            />
          </table>
        </div>
      </div>
    </div>

    <div :class="showSyncBtnClass">
      <CMSyncMap
        v-if="showSyncMapBtn"
      />

      <CMTablePagination
        :pagination="pagination"
        :page-index="pageIndex"
        :page-size="pageSize"
        :filtered-data="filteredData"
        @onUpdateKey="onUpdateKey"
      />
    </div>

    <CMTableFilter
      :current-filter="currentFilter"
      :filters="filters"
      :data="data"
      :dialog="dialog"
      :header-config="headerConfig"
      @onUpdateKey="onUpdateKey"
      @onToggleCurrentFilter="onToggleCurrentFilter"
    />
  </div>
</template>

<script>
import { get } from 'lodash'
import { mapState } from 'vuex'

import breakpointsMixin from '@/mixins/breakpointsMixin'
import eventsMixin from '@/mixins/eventsMixin'
import setObjWithInitValue from '@/utils/setObjWithInitValue'

export default {
  name: 'CMTable',

  components: {
    CMTableFilter: () => import('@/components/Common/CMTableFilter.vue'),
    CMTableChip: () => import('@/components/Common/CMTableChip.vue'),
    CMTableHeader: () => import('@/components/Common/CMTableHeader.vue'),
    CMTableBody: () => import('@/components/Common/CMTableBody.vue'),
    CMTablePagination: () => import('@/components/Common/CMTablePagination.vue'),
    CMSyncMap: () => import('@/components/Common/CMSyncMap.vue')
  },

  mixins: [breakpointsMixin, eventsMixin],

  props: {
    selectedFromParent: {
      type: Array,
      default: () => ([])
    },

    origin: {
      type: String,
      default: ''
    },

    headerConfig: {
      type: Object,
      default: () => ({})
    },

    showSelect: {
      type: Boolean,
      default: false
    },

    pageSize: {
      type: Number,
      default: 10
    },

    extraStyles: {
      type: Object,
      default: () => ({})
    },

    items: {
      type: Array,
      default: () => ([])
    },

    slots: {
      type: Array,
      default: () => ([])
    },

    pagination: {
      type: Boolean,
      default: true
    },

    customTitle: {
      type: Array,
      default: () => ([])
    },

    multipleSelect: {
      type: Boolean,
      default: true
    },

    identifier: {
      type: String,
      default: 'ID'
    },

    disablingCondition: {
      type: Function,
      default: () => (false)
    },

    hiddenCols: {
      type: Array,
      default: () => ([])
    },

    hasDisabling: {
      type: Boolean,
      default: false
    },

    showSyncMapBtn: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isSelectedAll: false,
      selectedItems: [],
      currentFilter: null,
      filters: {},
      menuPosition: {
        left: '0px',
        top: '0px'
      },
      activeFilters: { byInput: {} },
      disabledItemCount: 0,
      checkboxes: [],
      selectedRowKeysCollection: [],
      pageIndex: 1,
      dialog: false
    }
  },

  computed: {
    ...mapState({
      data: function (state) {
        return this.origin ? get(state, this.origin, []) : this.items
      }
    }),

    headerKeys () {
      const [firstAsset] = this.data ?? []
      return firstAsset ? Object.keys(firstAsset) : []
    },

    currentFilterOptions () {
      return [...new Set(this.data.map(item => item[this.currentFilter]))]
    },

    filteredData () {
      const addConditions = root => {
        return Object.entries(root)
          .map(([key, values]) => {
            const keyValues = Object
              .keys(values)
              .filter(key => values[key] !== undefined)

            if (keyValues.includes('greater')) {
              return item => item[key] >= values.greater
            }

            if (keyValues.includes('less')) {
              return item => values.less ? (item[key] <= values.less) : false
            }

            const hasStartDate = keyValues.includes('startDate')
            const hasEndDate = keyValues.includes('endDate')

            if (hasStartDate && hasEndDate) {
              return item => {
                const itemDate = new Date(item[key])
                
                return values.startDate && values.endDate
                ? (
                    itemDate >= new Date(values.startDate) &&
                    itemDate <= new Date(values.endDate)
                  )
                : false
              }
            }

            if (hasStartDate && !hasEndDate) {
              return item => values.startDate
                ? (new Date(item[key]) >= new Date(values.startDate))
                : false
            }

            if (!hasStartDate && hasEndDate) {
              return item => values.endDate
                ? (new Date(item[key]) <= new Date(values.endDate))
                : false
            }

            return keyValues.length
              ? item => keyValues.includes(String(item[key]))
              : null
          })
          .filter(Boolean)
      }

      const conditions = addConditions(this.filters)
      const filteredItems = []

      this.data.forEach(dataItem => {
        const hasPassedAllConditions = conditions.every(condition => condition(dataItem))

        if (hasPassedAllConditions) {
          filteredItems.push({
            ...dataItem,
            ...(this.hasDisabling && ({ isDisabled: this.disablingCondition(dataItem) }))
          })
        }
      })

      return filteredItems
    },

    chips () {
      return Object.entries(this.filters)
        .filter(([_, values]) => Object.values(values).length)
        .map(([key, values]) => [
          key,
          Object
            .entries(values)
            .filter(([_, filterStatus]) => filterStatus)
            .flat()
        ])
        .filter(([_, options]) => options.length)
        .map(([key, values]) =>
           `${key}: ${(values.length > 6
            ? [...values.slice(0, 6), ' and more']
            : values)
          .filter(x => typeof x !== 'boolean')
          .join(', ')}`
        )
    },

    isAnyItemEnabled () {
      return this.filteredData.some(item => !item.isDisabled)
    },

    showSyncBtnClass () {
      return this.showSyncMapBtn ? 'px-table-footer' : ''
    }
  },

  watch: {
    data () {
      this.initialize()
    },

    selectedFromParent (val) {
      if (val !== this.selectedItems) {
        this.selectedItems = val
      }
    },

    filteredData () {
      this.pageIndex = 1
      this.selectedItems = []
      this.checkboxes = []
      this.isSelectedAll = false
    }
  },

  mounted () {
    this.initialize()
  },

  methods: {
    onToggleSelectAll (value) {
      this.isSelectedAll = value

      if (value) {
        this.selectedItems = []
        this.filteredData.forEach((item, index) => {
          this.selectedItems[index] = { ...item, index }
        })
        this.updateCheckedItems()
      } else {
        this.selectedItems = []
        this.checkboxes = []
      }

      this.$emit('onToggleSelectAll', this.selectedItems)
    },

    onItemSelected ({ item, index, checkboxes }) {
      if (this.isSelectedAll) {
        this.isSelectedAll = false
      }
      this.checkboxes = checkboxes

      this.selectedItems = this.checkboxes[index]
        ? [...this.selectedItems, { ...item, index }]
        : this.selectedItems.filter(element => element[this.identifier] !== item[this.identifier])

      this.$emit(
        'onItemSelected',
        this.selectedItems,
        { item, value: this.checkboxes[index] }
      )
    },

    toggleDialog (close) {
      this.dialog = !this.dialog
      document.querySelector('.px-dialog')?.classList?.toggle('px-dialog-opened')
      if (close) this.currentFilter = null
    },

    onToggleCurrentFilter (key) {
      this.currentFilter = (this.currentFilter && key === this.currentFilter) ? null : key
      this.toggleDialog()
    },

    onUpdateKey ({ key, value }) {
      this[key] = value
      if (key === 'pageIndex') {
        this.updateCheckedItems()
      }
    },

    initialize () {
      this.filters = setObjWithInitValue([...this.headerKeys], {})
    },

    removeItem (item) {
      const index = this.data.indexOf(item)
      this.data.splice(index, 1)
    },

    updateCheckedItems () {
      this.checkboxes = []
      const data = this.filteredData
        .slice((this.pageIndex - 1) * this.pageSize, this.pageIndex * this.pageSize)

      data.forEach((el, index) => {
        const isSelected = this.selectedItems
          .find((item) => item[this.identifier] === el[this.identifier])

        this.checkboxes[index] = isSelected !== undefined
      })
    }
  }
}
</script>

<style scoped>
.px-table {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.px-table.px-table-border-around {
 border: 1px solid #000;
}

.px-table-content-wrapper {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
}

.px-table-enable-cell-selection {
  user-select: none;
}

.px-table-container {
  position: relative;
  overflow-y: auto;
  width: 100%;
}

.px-table * {
  box-sizing: border-box;
}

.px-table-content {
  min-width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

::v-deep th,
::v-deep td {
  font-size: 10px !important;
  padding: 0px 4px !important;
}

.px-table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
