<template>
  <v-card>
    <v-tabs
      v-model="tab"
      :height="30"
      dark
      fixed-tabs
      class="yellowPlexi"
    >
      <v-tab
        :height="25"
        :value="0"
        @click="clickTabMap()"
      >
        Map
      </v-tab>
      <v-tab
        :height="25"
        :value="1"
        :disabled="tabTIAMonitoringEnabled"
        @click="clickTabMonitoring()"
      >
        Monitoring
      </v-tab>

      <v-tab-item :value="0">
        <TIAMap />
      </v-tab-item>
      <v-tab-item :value="1">
        <TIAMonitoring />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>

import 'splitpanes/dist/splitpanes.css'
import TIAMap from '@/components/TIA/TIAMap/TIAMap.vue'
import { getStations, getNetwork } from '@/services/TIA'
import TIAMonitoring from '@/components/TIA/TIAMonitoring/TIAMonitoring.vue'
import { mapState } from 'vuex'

export default {
  name: 'TIA',
  components: {
    TIAMap,
    TIAMonitoring
  },

  data: () => ({
    coordinates: [40.40048958037617, -3.6783988212195466],
    allPlants: null,
    stations: [],
    network: [],
    error: false,
    loadingSelf: {
      isVisible: null,
      message: 'Loading TIA Map'
    },
    tab: 0,
    tabTIAMonitoringEnabled: true
  }),
  computed: {
    ...mapState({
      tabTIA: (state) => state.TIA.tabTIA,
      tabTIAMonitoring: (state) => state.TIA.tabTIAMonitoring
    })
  },

  watch: {
    // whenever question changes, this function will run
    tabTIA () {
      this.tab = this.tabTIA
    },
    tabTIAMonitoring () {
      this.tabTIAMonitoringEnabled = this.tabTIAMonitoring
    }
  },
  mounted () {
    // this.$store.dispatch('resetState')
    this.showLoading(true)
    this.getData()
    setTimeout(() => { this.showLoading(false) }, 1000)
  },

  methods: {
    async getData () {
      try {
        this.stations = await getStations()
        this.network = await getNetwork()
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },

    showLoading (show) {
      this.loadingSelf.isVisible = show
    },
    clickTabMap () {
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 0 })
      // this.$store.dispatch('setElement', { path: 'TIA.tabTIAMonitoring', value: true })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.tabTIAMonitoring', value: 0 })
    },
    clickTabMonitoring () {
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 1 })
      this.$store.dispatch('setElement', { path: 'TIA.tabTIAMonitoring', value: false })
    }
  }
}
</script>

<style scoped >

.v-tab {
  min-width: 59px !important;
  max-width: 1059px !important;
  margin-right: -14px !important;
  margin-left: -5px !important;
  min-height: 25px !important;
}

</style>
