<template>
  <div v-if="loadingSelf.isVisible">
    <CMLoader
      :loading="loadingSelf.isVisible"
      :message="loadingSelf.message"
    />
  </div>
  <div v-else-if="loadingSelf.isVisible == false">
    <TIAMonitoringNavBarMap
      :list-elements="stations"
      @toggleLoading="toggleLoading"
      @selectElement="selectElement"
      @toggleNavbar="toggleNavbar"
    />
    <TIAMonitoringLayout
      :monitor-station="monitorStation"
      :monitor-station-plot="monitorStationPlot"
    />
    <TIAMapInformation
      :class-assigned="classAssigned"
    />
  </div>
</template>

<script>

import CMLoader from '@/components/Common/CMLoader.vue'
import { getStations, getMonitorStation, getMonitorTransformerPlot } from '@/services/TIA'
import TIAMonitoringNavBarMap from '@/components/TIA/TIAMonitoring/TIAMonitoringNavBarMap.vue'
import TIAMonitoringLayout from '@/components/TIA/TIAMonitoring/TIAMonitoringLayout.vue'
import TIAMapInformation from '@/components/TIA/TIAMap/TIAMapInformation.vue'
import { mapState } from 'vuex'
import getLocalDateFormated from '@/mixins/TIA/getLocalDateFormated'

export default {
  name: 'TIAMonitoring',
  components: {
    CMLoader,
    TIAMonitoringNavBarMap,
    TIAMonitoringLayout,
    TIAMapInformation
  },
  mixins: [getLocalDateFormated],
  data: () => ({
    stations: [],
    monitorStation: [],
    monitorStationPlot: [],
    error: false,
    loadingSelf: {
      isVisible: null,
      message: 'Loading TIA Monitoring'
    },
    classAssigned: 'monitoring-info'
  }),
  computed: {
    ...mapState({
      stationSelected: (state) => state.TIA.TIAMap.stationSelected,
      periodSelected: (state) => state.TIA.TIAMonitoring.periodSelected
    })
  },

  watch: {
    // whenever question changes, this function will run
    stationSelected () {
      this.showLoading(true)
      this.getDataStation()
      this.getDataMonitorTransformerPlot()
      setTimeout(() => { this.showLoading(false) }, 500)
    },
    periodSelected () {
      this.getDataMonitorTransformerPlot()
    }
  },
  mounted () {
    this.showLoading(true)
    this.getData()
    this.getDataMonitorTransformerPlot()
    setTimeout(() => { this.showLoading(false) }, 1500)
  },

  methods: {
    async getData () {
      try {
        this.stations = await getStations()
        const uuid = this.$store.state.TIA.TIAMap.stationSelected
        this.monitorStation = await getMonitorStation(uuid)
        let uuidCustomer
        if (this.monitorStation[3].lenght > 0) {
          uuidCustomer = this.monitorStation[3].individual_customer_info[0].uuid
        } else {
          uuidCustomer = '-'
        }
        this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.customerSelected', value: uuidCustomer })
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataStation () {
      try {
        const uuid = this.$store.state.TIA.TIAMap.stationSelected
        this.monitorStation = await getMonitorStation(uuid)
        const uuidCustomer = this.monitorStation[3].individual_customer_info[0].uuid
        this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.customerSelected', value: uuidCustomer })
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    async getDataMonitorTransformerPlot () {
      try {
        const uuid = this.$store.state.TIA.TIAMap.stationSelected
        const period = this.$store.state.TIA.TIAMonitoring.periodSelected

        let dateStart
        let dateEnd
        if (period === 'today') {
          dateStart = this.getLocalDateFormated(0)
          dateEnd = this.getLocalDateFormated(0)
        } else if (period === 'tomorrow') {
          dateStart = this.getLocalDateFormated(-1)
          dateEnd = this.getLocalDateFormated(-1)
        } else if (period === 'yesterday') {
          dateStart = this.getLocalDateFormated(1)
          dateEnd = this.getLocalDateFormated(1)
        }

        const timeStart = `${dateStart}T00:00:00Z`
        const timeEnd = `${dateEnd}T23:55:00Z`
        this.monitorStationPlot = await getMonitorTransformerPlot(uuid, timeStart, timeEnd)
      } catch (error) {
        console.log(error)
        this.error = true
      }
    },
    showLoading (show) {
      this.loadingSelf.isVisible = show
    }
  }
}
</script>

<style >
.splitpanes--vertical > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  width: 2px;
  border-right: 2px solid rgba(238, 238, 238, 0.2);
}
.splitpanes--horizontal > .splitpanes__splitter {
  background-color: #333;
  position: relative;
  height:2px;
  border-top:2px solid rgba(238, 238, 238, 0.2);
  margin-top:-1px;
  }
</style>
