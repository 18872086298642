import { VALIDATIONS, TYPE_VALIDATIONS } from '@/store/constants'
import {
  getReservation,
  addReservation,
  removeReservation
} from '@/services/hostingCapacity'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      valid: true
    }
  },

  props: {
    payload: {
      type: Object,
      default: () => ({
        edit: false
      })
    },

    showForm: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      connectionPoint: ({ connectionPoint }) => connectionPoint,
      HCReservations: ({ hostingCapacity }) => hostingCapacity.HCReservations,
      checkReservations: ({ hostingCapacity }) => hostingCapacity.checkReservations
    }),
    VALIDATIONS () {
      const validations = VALIDATIONS
      validations.capacity = [TYPE_VALIDATIONS.required, TYPE_VALIDATIONS.minMax(1, this.availableCapacity)]
      return validations
    },
    infoHCMId () {
      return this.HCManageResultsSolved.find(
        (el) => el.network === this.connectionPoint.NETWORK
      ).infoHCM.id
    },
    availableCapacity () {
      const cpsByStation = this.HCManageResultsSolved.find((el) => el.network === this.connectionPoint.NETWORK).connPoints
      const cp = cpsByStation.find((cp) => cp.connPoint.toString() === this.connectionPoint.ID)
      return cp.value
    }
  },

  methods: {
    validate () {
      return this.$refs.ReservationForm.validate()
    },

    closeForm () {
      this.formData = {
        ph: this.phItems.find((ph) => ph.id === this.payload.phaseIndex).value,
        capacity: this.availableCapacity,
        time: 3,
        description: ''
      }
      if (this.showError) {
        this.errorDescription = ''
        this.showError = false
      }
      this.$emit('closeForm')
    },
    formatDate (date) {
      const year = date.getFullYear()
      const month = `0${date.getMonth() + 1}`.slice(-2)
      const day = `0${date.getDate()}`.slice(-2)
      return `${year}-${month}-${day}`
    },
    async getAllReservations () {
      let reservationId
      let reservationType = 'station'
      if (this.$sessionStorage.zoneId !== null && this.$sessionStorage.zoneId !== 'null') {
        reservationId = this.$sessionStorage.zoneId
        reservationType = 'area'
      }
      const allReservations = []

      const getReservationsByType = async (id, reservationNetwork) => {
        try {
          const reservations = await getReservation(reservationType, id)
          if (reservations) {
            reservations.forEach((reservation) => {
              reservation = setReservationsNetwork(reservation, reservationNetwork)
              allReservations.push(reservation)
            })
          }
        } catch (e) {
          console.warn(e)
        }
      }
      const setReservationsNetwork = (reservation, reservationNetwork) => {
        let network
        if (reservationNetwork) {
          network = reservationNetwork
        } else {
          network = this.HCManageResultsSolved.find((el) => el.infoHCM.station === reservation.station)?.network
        }
        reservation.network = network
        return reservation
      }

      if (reservationType === 'area') {
        getReservationsByType(reservationId)
      } else {
        for await (const result of this.HCManageResultsSolved) {
          const { referencePoint } = result
          await referencePoint

          if (result.HCMResults) {
            await getReservationsByType(result.infoHCM.station, result.network)
          }
        }
      }
      this.setVuexElement({
        path: 'hostingCapacity.HCReservations',
        value: allReservations
      })
    },
    async sendReservation () {
      const validation = this.validate()
      if (validation) {
        const dateNow = new Date(Date.now())
        const startDate = this.formatDate(dateNow)
        const newDate = new Date(
          dateNow.setMonth(dateNow.getMonth() + this.formData.months)
        )
        const expirationDate = this.formatDate(newDate)
        const requestedBy = Date.now()
        const reservationData = {
          requested_by: requestedBy,
          capacity: this.formData.capacity,
          capacity_type: this.payload.capacityType,
          reservation_type: this.payload.reservationType,
          cgp: this.connectionPoint.ID,
          hcm: this.infoHCMId,
          reservation_start_date: startDate,
          reservation_expiration_date: expirationDate,
          description: this.formData.description,
          phase: this.formData.ph
        }
        let newReservationResponse
        let networkName
        try {
          newReservationResponse = await addReservation(reservationData)
          if (newReservationResponse) {
            networkName = this.HCManageResults.find((el) => el.infoHCM.station === newReservationResponse.station)?.network
            const checkNewReservation = this.checkReservations
            checkNewReservation.push({ id: newReservationResponse.id, network: networkName, station: newReservationResponse.station })
            this.setVuexElement({
              path: 'hostingCapacity.checkReservations',
              value: checkNewReservation
            })
          }
          this.closeForm()
        } catch (e) {
          if (e === 500) {
            this.showError = true
            this.errorDescription = e.description
          } else if (e?.code >= 400) {
            this.showError = true
            this.errorDescription = e.title + '. ' + e.text + '.'
          }
        }
        if (newReservationResponse) {
          this.payload.updateMapAfterHCReservation(networkName)
        }
      }
    },
    async updateReservations (networks, lowNetworksIndex, networkIndex) {
      const updatedReservation = await this.checkReservationDone(lowNetworksIndex, networkIndex)
      if (updatedReservation) {
        this.setVuexElement({
          path: 'hostingCapacity.HCReservations',
          value: updatedReservation[0]
        })
        const newNetworks = networks[lowNetworksIndex]
        newNetworks.networks = updatedReservation[1]

        this.networks.splice(lowNetworksIndex, 1, newNetworks)
        this.setVuexElement({ path: 'hostingCapacity.HCNetworksWithLines', value: this.networks })
      }
    },
    checkReservationDone (lowNetworksIndex, networkIndex) {
      return new Promise((resolve) => {
        let interval
        const checkSolved = async () => {
          const { HCReservations } = this
          let { checkReservations } = this
          for await (const checkReservation of this.checkReservations) {
            // DOWNLOAD HOSTING CAPACITY MAP RESERVATIONS INFO FOR NEW RESERVATIONS
            const reservations = await getReservation('id', checkReservation.id)
            let stationInfo
            if (reservations) {
              stationInfo = await this.getInfoHCM(checkReservation.station)
            }
            if (reservations && stationInfo[0]?.is_solved) {
              reservations.forEach((reservation) => {
                reservation.network = checkReservation.network
                HCReservations.push(reservation)
              })
              checkReservations = this.checkReservations.filter((el) => el.id !== checkReservation.id)
              const updatedHCMap = await this.getHCInfoForStation(lowNetworksIndex, networkIndex, checkReservation.station, checkReservation.network, stationInfo[0])
              resolve([HCReservations, updatedHCMap])
            } else if (!stationInfo[0]?.is_solved) {
              this.setNewHCManageResult(stationInfo[0], checkReservation.station)
            }
          }
          this.setVuexElement({
            path: 'hostingCapacity.checkReservations',
            value: checkReservations
          })

          // STOP CHECK STATUS PROCESS
          if (!this.checkReservations.length) {
            clearInterval(interval)
          }
        }
        interval = setInterval(checkSolved, 300000) // Check status each 5 min
        checkSolved()
      })
    },
    checkUpdatedHCMap (lowNetworksIndex, networkIndex, station, network) {
      return new Promise((resolve) => {
        let interval
        const checkSolved = async () => {
          // DOWNLOAD HOSTING CAPACITY MAP INFO FOR NETWORK BY STATION
          const response = await this.getInfoHCM(station)

          if (response[0]?.is_solved) {
            const updatedHCMap = await this.getHCInfoForStation(lowNetworksIndex, networkIndex, response[0].station, network, response[0])
            clearInterval(interval)
            resolve([response[0], updatedHCMap])
          } else if (!response[0]?.is_solved) {
            this.setNewHCManageResult(response[0], station)
          }
        }
        interval = setInterval(checkSolved, 120000) // Check status each 2 min

        checkSolved()
      })
    },
    async removeReservationItem (id) {
      if (id) {
        const response = await removeReservation({ id })
        if (!response) {
          const removedReservation = this.HCReservations.find((reservation) => reservation.id === id)
          const updatedReservation = this.HCReservations.filter((reservation) => reservation.id !== id)
          this.setVuexElement({
            path: 'hostingCapacity.HCReservations',
            value: updatedReservation
          })
          this.setVuexElement({
            path: 'hostingCapacity.checkReservations',
            value: [...this.checkReservations,
              { id, network: removedReservation.network, station: removedReservation.station }]
          })
          this.updateMapAfterRemoveHCReservation(removedReservation)
        }
      }
    },
    setNewHCManageResult (infoHCM, station) {
      let updatedResults = this.HCManageResults
      const index = this.HCManageResults.findIndex((result) => result.infoHCM.station === station)
      if (index !== -1) {
        const newEl = {
          infoHCM,
          network: updatedResults[index].network
        }
        updatedResults = [
          ...this.HCManageResults.slice(0, index),
          newEl,
          ...this.HCManageResults.slice(index + 1)
        ]
      }

      this.setVuexElement({
        path: 'hostingCapacity.HCManageResults',
        value: updatedResults
      })
    }
  }
}
