<template>
  <div>
    <h3 class="title-events mt-16">
      Constraints Log
    </h3>
    <v-col
      cols="3"
      class="py-2"
    >
      <v-btn-toggle
        v-model="toogle"
        rounded
        mandatory
        dense
        @change="changeEvents"
      >
        <v-btn
          :min-width="100"
          value="past"
        >
          Last
        </v-btn>
        <v-btn
          :min-width="100"
          value="future"
        >
          Next
        </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-data-table
      v-model="selected"
      compact
      :headers="headers"
      :items="events"
      :items-per-page="5"
      @click:row="handleClick"
    >
      <template #item.status="{ value }">
        <div
          v-if="value == 'Scheduled' "
          style="color:#F4D03F"
        >
          <strong>
            {{ value }}
          </strong>
        </div>
        <div
          v-if="value == 'Solved' "
          style="color:#2ECC71"
        >
          <strong>
            {{ value }}
          </strong>
        </div>
      </template>
      <template #item.detail="{ value }">
        <v-btn
          outlined
          small
          dense
          color="#c3c3c3"
        >
          {{ value }}
        </v-btn>
      </template>
    </v-data-table>
    <br>
    <h3 class="title-events mt-16">
      Solutions Log
    </h3>
    <v-data-table
      v-model="selectedSolutions"
      :headers="headersSolutions"
      :items="filteredSolutions"
      :items-per-page="5"
      class="mt-16"
      @click:row="handleClickSolutions"
    >
      <template
        v-for="(header, i) in headersSolutions"
        #[`header.${header.value}`]="{ }"
      >
        {{ header.text }}
        <div
          :key="i"
          @click.stop
        >
          <v-text-field
            v-if="header.filter==''"
            :key="i"
            v-model="multiSearch[header.value]"
            dense
            class="pa"
            type="text"
            :placeholder="header.text"
          />
        </div>
      </template>
      <template #item.status="{ value }">
        <div
          v-if="value == 'Offer' "
          style="min-width:200px;"
        >
          <div
            style="display:inline; color:#F4D03F; margin-right:25px"
          >
            <strong>
              {{ value }}
            </strong>
          </div>
          <v-btn
            color="#2ECC71"
            small
            outlined
          >
            Accept
          </v-btn>
        </div>
        <div
          v-else-if="value == 'Bid' "
          style="min-width:200px;"
        >
          <div
            style="display:inline; color:#DC7633; margin-right:36px"
          >
            <strong>
              {{ value }}
            </strong>
          </div>
          <v-btn
            color="#EC7063"
            small
            outlined
          >
            Cancel
          </v-btn>
        </div>
        <div
          v-else-if="value == 'Match' "
          style="min-width:200px;"
        >
          <div
            style="display:inline; color:#2E86C1; margin-right:18px;"
          >
            <strong>
              {{ value }}
            </strong>
          </div>
          <v-btn
            color="#EC7063"
            small
            outlined
          >
            Cancel
          </v-btn>
        </div>
        <div
          v-else-if="value == 'Available' "
          style="min-width:200px;"
        >
          <div
            style="display:inline; color:#2ECC71; margin-right:10px"
          >
            <strong>
              {{ value }}
            </strong>
          </div>
          <v-btn
            color="#2ECC71"
            small
            outlined
          >
            Accept
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getMonitorSolutions } from '@/services/TIA'
export default {
  name: 'TIAMapEvents',

  components: {
  },
  props: {
    events: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    selected: [],
    selectedSolutions: [],
    toogle: 'future',
    headers: [
      { text: 'Start', value: 'interval00', filter: '' },
      { text: 'End', value: 'interval01', filter: '' },
      { text: 'Power (kW)', value: 'Power violation', filter: '' },
      { text: 'Status', value: 'status', filterable: true, filter: '' },
      { text: '', value: 'detail', filter: '' }
    ],
    headersSolutions: [
      { text: 'Start', value: 'interval00', filter: '' },
      { text: 'End', value: 'interval01', filter: '' },
      { text: 'FSP', value: 'fsp', filter: '' },
      { text: 'Provider', value: 'provider', filter: '' },
      { text: 'Price', value: 'price', filter: '' },
      { text: 'Quantity', value: 'quantity', filter: '' },
      { text: 'Status', value: 'status', filter: '' }
    ],
    solutions: [],
    multiSearch: {}
  }),

  computed: {
    filteredSolutions () {
      if (this.multiSearch) {
        return this.solutions.filter((item) => {
          return Object.entries(this.multiSearch).every(([key, value]) => {
            return (item[key] || '').toString().toUpperCase().includes(value.toString().toUpperCase())
          })
        })
      } else {
        return this.rowItems
      }
    }
  },
  methods: {
    getColor (status) {
      if (status > 150) return 'red'
      else if (status > 100) return 'orange'
      else return 'green'
    },
    async handleClick (value) {
      this.solutions = []
      const uuid = this.$store.state.TIA.TIAMap.stationSelected
      const solutionsMarket = await getMonitorSolutions(uuid, 'future', 1, value.id)
      const solutionsBilateral = await getMonitorSolutions(uuid, 'future', 2, value.id)
      const solutionsNonFirm = await getMonitorSolutions(uuid, 'future', 3, value.id)
      const allSolutions = {
        ...solutionsMarket,
        ...solutionsBilateral,
        ...solutionsNonFirm
      }
      this.createSolutions(allSolutions)
    },
    createSolutions (object) {
      const arraySolutions = []
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          object[key].forEach((measur) => {
            arraySolutions.push(measur)
          })
        }
      }
      this.solutions = arraySolutions
    },
    handleClickSolutions () {

    },
    changeEvents (value) {
      this.$emit('getLogs', value)
    },

    filterTable () {
      // Actualiza la tabla cuando se realiza un cambio en los campos de filtro
      // No es necesario hacer nada aquí, ya que los datos filtrados se gestionan en la propiedad computed filteredSolutions
    }
  }
}
</script>

<style scoped>
.title-events{
  padding-top:10px;
  color:#f3f3f3;
}

</style>
