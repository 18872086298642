<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    offset-y
    tile
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        id="cascader-text-filed"
        ref="text"
        hide-details
        append-icon="mdi-chevron-down"
        :value="selectedItems.text"
        class="m-top-10 selector-container"
        readonly
        dense
        v-on="on"
      />
    </template>
    <v-sheet width="100%">
      <v-radio-group
        v-model="radioInput"
        :mandatory="false"
        @change="handleItemSelect"
      >
        <template v-for="item in items">
          <v-col
            v-if="item.id === 3"
            :key="item.key"
            class="label"
          >
            {{ item.text }}
          </v-col>
          <v-radio
            v-if="item.id !== 3"
            :key="item.id"
            :label="item.text"
            :value="item.value"
          />
        </template>
      </v-radio-group>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedTypeViewMap: {
      type: [Array, Number, String],
      default: () => []
    }
  },
  data () {
    return {
      showMenu: false,
      selectedItems: null,
      radioInput: null
    }
  },
  watch: {
    selectedTypeViewMap: {
      handler () {
        this.initValue()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initValue () {
      this.selectedItems = this.items.find(
        (item) => item.value === this.selectedTypeViewMap
      )
      this.radioInput = this.selectedItems.value
    },

    handleItemSelect (value) {
      const item = this.items.find((item) => item.value === value)
      if (item) {
        this.selectedItems = item
      }
      this.$emit('updateTypeViewMap', this.selectedItems)
    }
  }
}
</script>

<style scoped>
.selector-container {
  width: 80%;
}
.v-menu__content {
  box-shadow: none;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.v-sheet {
  padding: 10px;
}
::v-deep .v-radio .v-label {
  padding-left: 8px;
  color: white;
}
.col.label {
  padding: 8px 0 0;
  margin: 8px 0;
  border-top: 1px solid rgba(253, 253, 253, 0.3);
}
.v-radio:nth-child(4),
.v-radio:nth-child(5) {
  margin-left: 16px;
}
</style>
