<template>
  <div class="plotCustomer">
    <Plotly
      :data="getDataPlot"
      :layout="getLayoutPlot"
      :display-mode-bar="false"
      :displaylogo="false"
    />
  </div>
</template>

<script>
import { Plotly } from 'vue-plotly'
export default {
  name: 'TIAMonitoringPlotFSP',

  components: {
    Plotly
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    fsp: {
      type: Number,
      default: 0
    }

  },
  data: () => ({
    heightPlot: null,
    nonFlexValue: [],
    flexValue: [],
    outLimitsValue: []
  }),

  computed: {
    getDataPlot () {
      // Variables for Plot

      const datos = []
      let measurement
      const proxyData = new Proxy(this.data, {
        // Handler del proxyData
        get (target, prop) {
          return target[prop]
        }
      })
      // Obtener las claves del proxyData
      const measurements = Object.keys(proxyData) // También puedes usar Reflect.ownKeys(proxyData)
      // Recorrer las claves y acceder a los valores correspondientes
      // const maxPwr = proxyData.max_pwr.value
      // const nonFlex = proxyData.nonflex_pwr.value
      // const flex = this.minusArrays(proxyData.flex_pwr.value, proxyData.nonflex_pwr.value)
      measurements.forEach((measur) => {
        let colorSerie
        let valueY
        let nameSerie
        let lineColor
        let lineWidth
        let opacityBorder

        if (proxyData[measur].frontend_name === `requested flex fsp${this.fsp}` || proxyData[measur].frontend_name === `matched bids fsp${this.fsp}`) {
          nameSerie = proxyData[measur].frontend_name
          colorSerie = 'rgba(46, 134, 193, 1)'
          valueY = proxyData[measur].value
          lineColor = 'rgba(46, 134, 193, 1)'
          lineWidth = 1
          opacityBorder = 1
        } else if (proxyData[measur].frontend_name === `available flex fsp${this.fsp}` || proxyData[measur].frontend_name === `flex offers fsp${this.fsp}`) {
          nameSerie = proxyData[measur].frontend_name
          colorSerie = 'rgba(244, 208, 63 , 1)'
          valueY = proxyData[measur].value
          lineColor = 'rgba(244, 208, 63 , 1)'
          lineWidth = 1
          opacityBorder = 1
        } else if (proxyData[measur].frontend_name === `activated flex fsp${this.fsp}` || proxyData[measur].frontend_name === `ctivated flex fsp${this.fsp}`) {
          nameSerie = proxyData[measur].frontend_name
          colorSerie = 'rgba(35, 155, 86  , 1)'
          valueY = proxyData[measur].value
          lineColor = 'rgba(35, 155, 86  , 1)'
          lineWidth = 1
          opacityBorder = 1
        }

        measurement = {
          x: proxyData[measur].time,
          y: valueY,
          type: 'bar',
          mode: 'lines',
          name: nameSerie,
          line: {
            shape: 'hvh'
          },

          visible: true,

          marker: {
            color: colorSerie,
            opacity: opacityBorder,
            line: {
              color: lineColor,
              width: lineWidth
            }
          }
        }
        if (proxyData[measur].frontend_name !== `real full baseline fsp${this.fsp}` && proxyData[measur].frontend_name !== `forecasted full baseline fsp${this.fsp}` &&
        proxyData[measur].frontend_name !== `real flex baseline fsp${this.fsp}`) {
          datos.push(measurement)
        }
      })
      // HISTORICAL
      if (proxyData[`real full baseline fsp${this.fsp}`] !== undefined) {
        measurement = {
          x: proxyData[`real full baseline fsp${this.fsp}`].time,
          y: proxyData[`real full baseline fsp${this.fsp}`].value,
          type: 'lines',
          mode: 'lines',
          name: proxyData[`real full baseline fsp${this.fsp}`].frontend_name,
          line: {
            color: 'rgba(171, 235, 198 , 0.2)',
            dash: 'line',
            width: 3
          },
          visible: true
        }
        datos.push(measurement)
      }
      if (proxyData[`forecasted full baseline fsp${this.fsp}`] !== undefined) {
        measurement = {
          x: proxyData[`forecasted full baseline fsp${this.fsp}`].time,
          y: proxyData[`forecasted full baseline fsp${this.fsp}`].value,
          type: 'lines',
          mode: 'lines',
          name: proxyData[`forecasted full baseline fsp${this.fsp}`].frontend_name,
          line: {
            color: 'rgba(171, 235, 198 , 1)',
            dash: 'line',
            width: 3
          },
          visible: true
        }
        datos.push(measurement)
      }
      if (proxyData[`real flex baseline fsp${this.fsp}`] !== undefined) {
        measurement = {
          x: proxyData[`real flex baseline fsp${this.fsp}`].time,
          y: proxyData[`real flex baseline fsp${this.fsp}`].value,
          type: 'lines',
          mode: 'lines',
          name: proxyData[`real flex baseline fsp${this.fsp}`].frontend_name,
          line: {
            color: 'rgba(158,202,225, 0.4)',
            dash: 'line',
            width: 3
          },
          visible: true
        }
        datos.push(measurement)
      }
      return datos
    },

    getLayoutPlot () {
      const layout = {
        title: '',
        legend: {
          orientation: 'h',
          entrywidth: 200,
          valign: 'top',
          yanchor: 'bottom',
          y: 1.02,
          xanchor: 'right',
          x: 1,
          bgcolor: '#272727',
          font: {
            size: '12',
            family: 'Faktum Light'
          }

        },
        barmode: 'bar',
        // barmode: 'stack',
        height: this.heightPlot,
        margin: {
          l: 60,
          r: 10,
          b: 65,
          t: 20,
          pad: 5
        },
        xaxis: {
          title: 'Time (h)',
          linecolor: '#fff',
          nticks: 10,
          gridcolor: '#595959',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 12
          }
        },
        yaxis: {
          title: '(KW)',
          linecolor: '#fff',
          gridcolor: '#595959'
        },
        paper_bgcolor: '#272727',
        plot_bgcolor: '#272727',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }
  },

  watch: {
  },
  mounted () {
    this.heightPlot = window.innerHeight * 0.40
  },
  methods: {
    clickTabEnergy () {

    },
    minusArrays (array1, array2) {
      if (array1.length !== array2.length) {
        throw new Error('Arrays must have the same lenght')
      }
      const resultado = []
      for (let i = 0; i < array1.length; i++) {
        const resta = array1[i] - array2[i]
        resultado.push(resta)
      }

      return resultado
    },
    calculateFlexAndOutLimits (nonFlex, flex, maxPwr) {
      const flexValues = []
      const DOEValues = []
      const outLimitsValues = []

      for (let i = 0; i < nonFlex.length; i++) {
        const totalPower = nonFlex[i] + flex[i]

        if (totalPower <= maxPwr[i]) {
          // If the sum of nonFlex and flex is less than or equal to maxPwr, the value is equal to the value of flex.
          flexValues.push(flex[i])
          DOEValues.push(maxPwr[i] - totalPower)
          outLimitsValues.push(0)
        } else {
          // If the sum of nonFlex and flex is greater than maxPwr, we calculate the value of flexValues and outLimitsValues
          flexValues.push(maxPwr[i] - nonFlex[i])
          DOEValues.push(0)
          outLimitsValues.push(totalPower - maxPwr[i])
        }
      }
      this.outLimitsValue = outLimitsValues
      this.DOEValue = DOEValues
      return flexValues
    }

  }
}
</script>

<style scoped>
.plotCustomer{
  margin-top: 5px;
}

</style>
