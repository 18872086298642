<template>
  <v-app>
    <!-- Barra -->
    <DtPanelToolbar
      :type-element="typeElement"
      :id-element-to-show="idElementToShow.toString()"
      @updateToolbar="updateToolbar"
      :alltransformers="alltransformers"
      @updateStation="updateStation"
    />
    <splitpanes
      horizontal
      @resized="setPanelHeight(62)"
    >
      <pane
        id="kpiPanel"
        size="53"
        class="kpiPanel bg-secondary"
      >
        <!-- KPIs container elements -->
        <v-card
          class="kpisContainer"
        >
          <!-- Header elements -->
          <v-container class="m-0 p-0">
            <v-row
              class="bg-primary header-kpis m-0"
            >
              <v-col class="p-0">
                <v-card-text
                  align="start"
                  class="text-header-kpis"
                >
                  KPIs
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
          <!-- Tabs elements -->
          <DtPanelKpiTabs
            :panel-height="panelHeight"
            :sim-results="simResults"
            :sim-headers="simHeaders"
            :table-height="panelHeight"
            :pv-list="pvList"
            :ev-list="evList"
            :hp-list="hpList"
            :load-scale-list="loadScaleList"
          />
        </v-card>
      </pane>
      <pane
        class="plotPanel bg-secondary"
      >
        <!-- Items for Plots -->
        <v-card
          class="plotContainer"
        >
          <!-- Header elements -->
          <v-container class="p-0 m-0">
            <v-row class="bg-primary header-kpis m-0">
              <v-col
                class="p-0"
              >
                <v-card-text
                  align="start"
                  class="text-header-kpis"
                >
                  {{ $t('plots') }}
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
          <!-- Tabs elements -->
          <DtPanelPlotTabs
            :type-value="typeValue"
            :sampling-period="samplingPeriod"
            :data-plot-v="dataPlotV"
            :data-plot-i="dataPlotI"
            :data-plot-p-s="dataPlotPS"
            :data-plot-p-p="dataPlotPP"
            :data-plot-p-q="dataPlotPQ"
            :data-plot-p-l="dataPlotPL"
            :data-plot-p-u-f-c="dataPlotPUFC"
            :data-plot-p-u-f-v="dataPlotPUFV"
            :data-plot="dataPlot"
            :data-plot-l-c-p="dataPlotLCP"
            :digital-twin-results="digitalTwinResults"
            :id-station="idStation"
            :level-voltage="levelVoltage"
            :type-element="typeElement"
          />
        </v-card>
      </pane>
    </splitpanes>
  </v-app>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
// Components
import DtPanelToolbar from '@/components/DigitalTwin/DtPanelToolbar.vue'
import DtPanelKpiTabs from '@/components/DigitalTwin/DtPanelKpiTabs.vue'
import DtPanelPlotTabs from '@/components/DigitalTwin/DtPanelPlotTabs.vue'
// Icons
import { mdiTableLarge, mdiLightningBoltCircle, mdiFormatListBulleted } from '@mdi/js'

export default {
  name: 'DigitalTwinPanel',
  components: {
    Splitpanes,
    Pane,
    DtPanelToolbar,
    DtPanelKpiTabs,
    DtPanelPlotTabs
  },
  props: {
    idElement: {
      type: String,
      default: null
    },
    idElementToShow: {
      type: String,
      default: null
    },
    idStation: {
      type: Number,
      default: null
    },
    levelVoltage: {
      type: Number,
      default: null
    },
    typeElement: {
      type: String,
      default: null
    },
    station: {
      type: String,
      default: null
    },
    stations: {
      type: Array,
      default: null
    },
    simResults: {
      type: Array,
      default: null
    },
    pvList: {
      type: Array,
      default: null
    },
    evList: {
      type: Array,
      default: null
    },
    hpList: {
      type: Array,
      default: null
    },
    loadScaleList: {
      type: Number,
      default: null
    },
    simHeaders: {
      type: Array,
      default: null
    },
    dataPlot: {
      type: Array,
      default: null
    },
    dataPlotLCP: {
      type: Array,
      default: null
    },
    dataPlotV: {
      type: Array,
      default: null
    },
    dataPlotI: {
      type: Array,
      default: null
    },
    dataPlotPS: {
      type: Array,
      default: null
    },
    dataPlotPP: {
      type: Array,
      default: null
    },
    dataPlotPQ: {
      type: Array,
      default: null
    },
    dataPlotPL: {
      type: Array,
      default: null
    },
    dataPlotPUFC: {
      type: Float32Array,
      default: null
    },
    dataPlotPUFV: {
      type: Float32Array,
      default: null
    },
    typeValue: {
      type: Number,
      default: null
    },
    samplingPeriod: {
      type: Number,
      default: null
    },
    digitalTwinResults: {
      type: Object,
      default: null
    },
    initDate: {
      type: Date,
      default: null
    },
    endDate: {
      type: Date,
      default: null
    },
    alltransformers: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      tableIcon: mdiTableLarge,
      powerIcon: mdiLightningBoltCircle,
      listIcon: mdiFormatListBulleted,
      panelHeight: null
    }
  },
  mounted () {
    this.setPanelHeight(32)
  },
  methods: {
    setPanelHeight (margin) {
      const height = document.getElementById('kpiPanel').clientHeight - margin
      // this.panelHeight = `${document.getElementById('kpiPanel').clientHeight}px`;
      this.panelHeight = `${height}px`
    },
    resizePanel () {
      this.$root.$emit('resizePlotly')
    },
    updateToolbar (var1, var2) {
      this.$emit('updatePanel', var1, var2)
    },
    updateStation (ids) {
      this.$emit('updateStation', ids)
    }
  }
}

</script>

<!-- Scoped was removed here to allor overriding of styles,
specially needed for the elements in the header -->
<style>
/* To limit the padding of the content inside the tabs to take benefit of more space */
div.v-card__text {
  padding: 4px;
}

.choose-date .v-text-field__slot input {
  color: #f4c020 !important;
}
.kpisContainer, .plotContainer {
  margin:5px 2px 0 2px;
  padding-top: 0px;
  flex:1;
}
.text-header-kpis {
  padding:6px 3px 3px 3px;
  font-size:18px;
  font-family:'Faktum Light', sans-serif;
  color:#333738;
  margin-left: 5px; margin-top:1px
}

.plot-panel {
  overflow-y:auto
}

/* .kpisContainer {
   height: calc(calc(100vh - 120px) * 0.5);
  /* overflow-y: auto;
}

.plotContainer {
  height: calc(calc(100vh - 120px) * 0.45);
  /* overflow-y: auto;
}

.panel {
  height: calc(100vh - 60px);
  /* overflow-y: auto;
} */

.tabs {
  overflow-y: auto;
}

.v-tab {
  min-width: 59px !important;
  max-width: 59px !important;
  margin-right: -14px !important;
  margin-left: -5px !important;
}
/* Horizontal line beyond Element dates in the header */
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #f4c020 !important;
}
.theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #f4c020 !important;
}
.splitpanes {
  background-color: #272727;
}

.header-kpis {
  height:36px;
}

.kpiPanel {
  display: flex;
}
</style>
