<template>
  <div>
    <v-container>
      <v-row
        align="center"
        align-content="center"
        justify="center"
      >
        <v-col style="color: #f4c01f">
          {{ title }}
        </v-col>
      </v-row>
    </v-container>
    <v-tabs
      v-model="currentTab"
      class="tabs"
      vertical
      slider-size="6"
      color="#f4c020"
      style="margin-top: 5px"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.value"
      >
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <h1
              left
              v-bind="attrs"
              v-on="on"
            >
              {{ tab.title }}
            </h1>
          </template>
          <span>{{ tab.tooltip }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab-item
        v-for="tab in tabs"
        :key="tab.value"
      >
        <v-card style="margin: 0px; padding: 0px">
          <v-card-text
            v-if="currentTab ? !reservesGeneration.length : !reservesConsumption.length"
          >
            {{ $t('no_data_available') }}
          </v-card-text>
          <v-card-text
            v-else
            style="overflow: auto"
          >
            <CMTable
              :items="currentTab ? reservesGeneration : reservesConsumption"
              :show-select="false"
              :page-size="12"
              :height="cardHeight"
              :extra-styles="{ marginTop: '20px' }"
              :header-config="{
                id: { notFilterable: true },
                capacity: { notFilterable: true },
                reservation_start_date: { notFilterable: true },
                reservation_expiration_date: { notFilterable: true },
                remove: { notFilterable: true }
              }"
              :slots="slots"
              :custom-title="headerTitle"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import HCReservationMixin from '@/mixins/Heatmaps/HCReservationMixin'
import vuexMixin from '@/mixins/vuexMixin'
import CMTable from '@/components/Common/CMTable'
import { mapState } from 'vuex'

export default {
  name: 'GridInspectionPlots',
  components: {
    CMTable
  },
  mixins: [HCReservationMixin, vuexMixin],
  props: {
    reservationNetworkName: {
      type: String,
      required: true
    },
    isConsumption: {
      type: Boolean,
      required: true
    },
    cardHeight: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      tabs: [
        { title: 'C', value: 'Consumption', tooltip: this.$t('consumption') },
        { title: 'G', value: 'Generation', tooltip: this.$t('generation') }
      ],
      currentTab: null,
      reservationNetwork: null,
      filters: {},
      activeFilters: { byInput: {} },
      reservesConsumption: [],
      reservesGeneration: [],
      slots: ['remove'],
      headerTitle: [
        { value: 'id', text: 'ID' },
        { value: 'capacity', text: this.$t('hcm.reserve') + '(kW)' },
        { value: 'phase', text: this.$t('phase') },
        { value: 'cgp', text: this.$t('grid.connection_point') },
        { value: 'feeder', text: this.$t('grid.feeder') },
        { value: 'station', text: this.$t('grid.station') },
        { value: 'network', text: this.$t('grid.network') },
        { value: 'reservation_start_date', text: this.$t('creation_date') },
        { value: 'reservation_expiration_date', text: this.$t('expiration_date') },
        { value: 'remove', text: this.$t('remove') }
      ]
    }
  },
  computed: {
    ...mapState({
      HCManageResults: ({ hostingCapacity }) => hostingCapacity.HCManageResults
    }),
    title () {
      return this.currentTab ? this.$t('hcm.generation_reserves') : this.$t('hcm.consumption_reserves')
    }
  },
  watch: {
    reservationNetworkName: {
      immediate: true,
      deep: true,
      handler () {
        this.reservationNetwork = this.reservationNetworkName
        this.setReserves()
      }
    },
    HCReservations () {
      this.setReserves()
    }
  },
  mounted () {
    this.currentTab = this.isConsumption ? 0 : 1
  },

  methods: {
    setReserves () {
      const removeElement = (id) => ({
        type: 'CMBtnIcon',
        props: {
          icon: 'mdi-delete',
          small: false,
          callback: () => this.removeReservationItem(id)
        },
        handleChange: () => {}
      })
      this.reservesGeneration = this.HCReservations
        .filter((reservation) =>
          reservation.reservation_type === 0 &&
          reservation.network === this.reservationNetwork
        )
        .map((el) => {
          el.remove = removeElement(el.id)
          return (({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }) => ({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }))(el)
        })

      this.reservesConsumption = this.HCReservations
        .filter((reservation) =>
          reservation.reservation_type === 1 &&
          reservation.network === this.reservationNetwork
        )
        .map((el) => {
          el.remove = removeElement(el.id)
          return (({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }) => ({
            id,
            capacity,
            phase,
            cgp,
            feeder,
            station,
            network,
            // eslint-disable-next-line camelcase
            reservation_start_date,
            // eslint-disable-next-line camelcase
            reservation_expiration_date,
            remove
          }))(el)
        })
    },
    updateMapAfterRemoveHCReservation (removedReservation) {
      this.$emit('updateMapAfterRemoveHCReservation', removedReservation)
    }
  }
}
</script>
