<template>
  <VApp
    id="ms-map"
    style="width: 100%;"
  >
    <MapBase
      v-if="networks && !loading.isVisible"
      mode="element_mode"
      functionality="measurements"
      :enabled-context-menu="false"
      :networks="networks"
      :open-fuses="openFuses"
      :station-open-fuses="stationOpenFuses"
      :list-elements="listElements"
      :grid-bounds="gridBounds"
      :area-selector="false"
      :show-control-layers="false"
      :bounds-updated="bounds"
      :show-medium-voltage="false"
      @changeMode="changeMode"
      @click="onElementClick"
    />
    <TimeSlider
      v-if="networks && !loading.isVisible && elementsOnMap !== null && timeStepOnSlider && $store.state.instant !== undefined"
      :steps="timeStepOnSlider"
      :init="timeOnSlider"
      :timesampling="timesamplingOnSlider"
      :init-instant="elementsOnMap?.instant"
      class="ms-time-slider"
      @changeInstant="changeInstant"
    />

    <CMLoader
      v-if="loading.isVisible"
      :loading="loading.isVisible"
      :message="loading.message"
      :overlay="false"
    />
  </VApp>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import 'splitpanes/dist/splitpanes.css'

import { mapState } from 'vuex'
import L from 'leaflet'
import { startCase } from 'lodash'

import MapBase from '@/components/Map/MapBase.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
import TimeSlider from '@/components/Common/CMTimeSlider.vue'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import selectElementFromMap from '@/mixins/map/mapElementSelectMixin'
import vuexMixin from '@/mixins/vuexMixin'
import {
  getAreaGridByZone,
  getGridByElementId,
  getGridByElementName
} from '@/services/areaSelector'
import { getElementDetails } from '@/services/measurements'
import { getNetworksObject } from '@/mixins/map/index'

export default {
  name: 'MSMap',

  components: {
    MapBase,
    TimeSlider,
    CMLoader
  },

  mixins: [unclickElements, selectElementFromMap, getNetworksObject, vuexMixin],

  data () {
    return {
      mode: 'element_mode',
      listElements: null,
      networks: null,
      openFuses: null,
      stationOpenFuses: [],
      host: this.$API_HOST,
      port: this.$API_PORT,
      bounds: undefined,
      gridBounds: undefined,
      loading: {
        isVisible: false,
        message: 'Loading Map'
      },
      isSetShowDetail: false,
      timeOnSlider: null,
      timeStepOnSlider: null,
      timesamplingOptions: {
        '1m': 60,
        '1h': 3600,
        '1d': 24 * 3600,
        '7d': 7 * 24 * 3600
      },
      timesamplingOnSlider: null
    }
  },

  computed: {
    ...mapState({
      area: ({ measurements }) => measurements.area,
      elementSelected: ({ measurements }) =>
        measurements.selected[measurements.currentSelector?.short],
      element: ({ measurements }) => measurements.element,
      elementsOnMap: ({ measurements }) => measurements.drawElementsOnMap[measurements.currentTab.title],
      selectedNetworksNames: ({ measurements }) => measurements.selectedNetworksNames,
      syncMap: (state) => state.syncMap
    })
  },

  watch: {
    area (val) {
      val && this.getAreaGrid()
    },
    element (val) {
      val && this.getElementGrid()
    },

    elementSelected () {
      // this.updateMap()
    },

    mode () {
      this.updateMap()
    },

    elementsOnMap () { // Changes in MSPlotDetails when drawing heatmap
      this.drawCPOnMap()
      if (this.elementsOnMap) {
        const idWithTime = Object.entries(this.elementsOnMap.data).find(([_, val]) => val.time.length)?.[0]
        if (idWithTime && this.elementsOnMap.data[idWithTime].time.length) {
          this.timeStepOnSlider = this.elementsOnMap.data[idWithTime].time.length

          let timeInit = this.elementsOnMap.data[idWithTime].time[0] * 1000
          const dateInit = new Date()
          dateInit.setTime(timeInit)
          const lagInit = dateInit.getTimezoneOffset()
          timeInit += lagInit * 60 * 1000

          this.timeOnSlider = timeInit
          this.timesamplingOnSlider = this.timesamplingOptions[this.elementsOnMap.interval]
        }
      }
    },

    syncMap (val) { // Call on sync of selected items from table with the map
      if (val) {
        this.updateMap()
        this.setVuexElement({ path: 'syncMap', value: false })
      }
    }
  },

  methods: {
    updateMap () {
      this.cleanHighlight()

      const selection = this.getSelectedElements(
        this.elementSelected,
        this.mode
      )

      this.bounds = selection.length
        ? this.getAxisFromSelection(selection)
        : selection

      this.elementSelected?.forEach((item) => {
        const { station, elementId, elementType, origin } = item

        if (elementId && elementType) {
          this.setElementDetails(station, elementId, elementType, origin)
          if (this.$store.state.measurements.currentSelector?.short === 'SU') {
            this.highlighElement(station, station, elementType)
          } else {
            this.highlighElement(station, elementId, elementType)
          }
        }
      })
    },

    drawCPOnMap () {
      this.cleanHighlight()
      if (this.elementsOnMap) {
        const selection = this.getMeasurementsElementsOnMap(
          this.elementsOnMap.elementIds,
          this.mode
        )

        this.bounds = selection.length
          ? this.getAxisFromSelection(selection)
          : selection
        this.isSetShowDetail = true
        selection.forEach((element) => {
          this.highlighElement(element.STATION, element.ID, 'connectionPoint')
        })
        this.isSetShowDetail = false
      }
    },

    changeMode (modeSelected) {
      this.mode = modeSelected
    },

    cleanHighlight () {
      this.unclickElements('polyline-measurements-selected')
      this.unclickElements('circle-measurements-selected')
      this.unclickElements('polyline-measurements-selected-detail')
      this.unclickElements('circle-measurements-selected-detail')
    },

    highlighElement (station, elementId, elementType) {
      if (this.mode === 'element_mode') {
        const [singleElement] = document.getElementsByClassName(
          `${elementType}${elementId}`
        )
        singleElement && this.setHightlightClass(singleElement)
        return singleElement
      }

      const stationElement = document.getElementsByClassName(
        `station${station}`
      )
      stationElement.forEach(this.setHightlightClass)
    },

    setElementsByMode ({ isNetworkMode, elementId, elementType, station }) {
      return isNetworkMode
        ? ['station', station || elementId]
        : [elementType, elementId]
    },

    setHightlightClass (element) {
      const elementClass = element.getAttribute('class')
      const cases = [
        {
          keyWord: 'circle',
          newClass: this.isSetShowDetail
            ? 'circle-measurements-selected-detail'
            : 'circle-measurements-selected'
        },
        {
          keyWord: 'polyline',
          newClass: this.isSetShowDetail
            ? 'polyline-measurements-selected-detail'
            : 'polyline-measurements-selected'
        }
      ]

      cases.forEach(({ keyWord, newClass }) => {
        elementClass.includes(keyWord) && element.classList.add(newClass)
      })
    },

    async setElementDetails (station, elementId, elementType, origin) {
      try {
        const isNetworkMode = this.mode === 'network_mode'
        const [newElementType, newElementId] = this.setElementsByMode({
          isNetworkMode,
          elementId,
          elementType,
          station
        })

        if (!origin) {
          const elementDetails = await getElementDetails(
            newElementType,
            newElementId
          )

          const title = startCase(`${newElementType} ${newElementId}`)

          this.$store.dispatch('setElement', {
            path: 'measurements.elementDetails',
            value: {
              title,
              info: {
                ...elementDetails,
                code: elementId
              },
              origin: 'map'
            }
          })
        }
      } catch (err) {
        console.error(err)
      }
    },

    onElementClick (_, network, elementId, elementType, origin) {
      this.selectElementFromMap(network, elementId, elementType)
      this.unclickElements('polyline-measurements-selected-detail')
      this.unclickElements('circle-measurements-selected-detail')
      this.setElementDetails(network, elementId, elementType, origin)
      this.isSetShowDetail = true
      this.highlighElement(network, elementId, elementType)
      this.isSetShowDetail = false
    },

    getAxisFromSelection (selection = []) {
      if (!selection.length) return undefined
      const latLng =
        this.$store.state.measurements.currentSelector?.short === 'FE'
          ? 'latLngs'
          : 'latLng'

      const verifyType = (latLngs = selection[0][latLng], index) => {
        const isStation = latLngs.some((item) => Array.isArray(item))
        return !isStation ? [latLngs[index]] : latLngs.map((val) => val[index])
      }
      const initialValue = { x: [], y: [] }

      const axis =
        selection.reduce(
          (acc, next) => ({
            x: [...acc.x, ...verifyType(next[latLng], 0)],
            y: [...acc.y, ...verifyType(next[latLng], 1)]
          }),
          initialValue
        ) || initialValue

      return [
        [Math.max(...axis.x), Math.min(...axis.y)],
        [Math.min(...axis.x), Math.max(...axis.y)]
      ]
    },

    getElementsByType ({
      item,
      type,
      isTypeStation,
      networks = [],
      lowVoltageIndex
    }) {
      const isTypeLine = type === 'lines'
      const currentNetwork = networks[lowVoltageIndex]

      if (isTypeLine) {
        const lines = currentNetwork.networks
          .map((network = {}) => network.lines)
          .flat()

        const lineElement = lines.find(
          line => line.ID === item.ID
        )

        return lineElement
      }

      if (isTypeStation) {
        const stationElement = currentNetwork.stations.find(
          station => station.ID === item.station
        )

        return stationElement
      }
    },

    getElementsByException ({
      item,
      type,
      currentNetwork,
      isTypeStation
    }) {
      let selectedNetwork = ''

      if (!isTypeStation) {
        const selectedStation = currentNetwork.stations
          .find(st => st.ID === item.station
          )

        selectedNetwork = selectedStation.NAME
      }

      const networkElement = currentNetwork.networks
        .find(networks => networks?.network?.NAME === selectedNetwork
        )?.[type]

      return networkElement
        ?.find(element => element.ID === item.elementId)
    },

    getSelectedElements (selected = [], mode = '') {
      const isNetworkMode = mode === 'network_mode'

      const selection = selected
        .map(item => {
          const dictionary = {
            line: 'lines',
            station: 'station',
            connectionPoint: 'connection_points',
            fuse: 'station_closed_fuses'
          }

          const type = dictionary[item.elementType]
          const isTypeStation = type === 'station' || isNetworkMode

          if (!type) return undefined

          const lowVoltageIndex = this.networks
            .findIndex(network => network.level === '400')

          const currentNetwork = this.networks[lowVoltageIndex]

          const elementByType = this.getElementsByType({
            item,
            isNetworkMode,
            type,
            lowVoltageIndex,
            networks: this.networks
          })

          if (elementByType) {
            return elementByType
          }

          return this.getElementsByException({
            item,
            type,
            currentNetwork,
            isTypeStation
          })
        })
        .filter(Boolean)

      return isNetworkMode ? selection.flat() : selection
    },

    getMeasurementsElementsOnMap (drawElements, mode) {
      const isNetworkMode = mode === 'network_mode'
      const CP = 'connection_points'
      const selectedElementByNetwork = this.selectedNetworksNames.map(
        (selectNetwork) => this.networks[0].networks.find((network) => network.network.NAME === selectNetwork)[CP]
      )

      const selectedDrawCPElements = []
      drawElements.forEach((element) => {
        selectedElementByNetwork.forEach((el) => {
          const findElement = el.find((item) => item.ID === String(element))
          if (findElement !== undefined) {
            selectedDrawCPElements.push(findElement)
          }
        })
      })

      return isNetworkMode
        ? selectedDrawCPElements.flat()
        : selectedDrawCPElements
    },

    transformPointsToArray (lat, lng) {
      const center = L.latLng(lat, lng)
      const { _northEast, _southWest } = center.toBounds(9)

      return [Object.values(_northEast), Object.values(_southWest)]
    },

    toggleMapReady () {
      this.loading.isVisible = !this.loading.isVisible

      this.$store.dispatch('setElement', {
        path: 'measurements.mapReady',
        value: !this.loading.isVisible
      })
    },

    async getAreaGrid () {
      this.toggleMapReady()
      const response = await getAreaGridByZone(this.area)
      if (!response) {
        this.toggleMapReady()
        return
      }
      this.getGrid(response)
    },

    async getElementGrid () {
      this.toggleMapReady()
      let response
      if (
        this.element.id !== undefined &&
        this.element.id !== '' &&
        this.element.id !== 'null' &&
        this.element.id !== null
      ) {
        response = await getGridByElementId(this.element)
      } else if (
        this.element.name !== undefined &&
        this.element.name !== '' &&
        this.element.name !== 'null' &&
        this.element.name !== null
      ) {
        response = await getGridByElementName(this.element)
      }

      if (!response) return
      this.getGrid(response)
    },

    async getGrid (response) {
      this.gridBounds = {
        Xmax: parseFloat(sessionStorage.Xmax),
        Xmin: parseFloat(sessionStorage.Xmin),
        Ymax: parseFloat(sessionStorage.Ymax),
        Ymin: parseFloat(sessionStorage.Ymin)
      }

      const networkObject = this.getNetworksObject(response)
      this.networks = networkObject.network.filter(network => network.level === '400')
      this.listElements = networkObject.list

      this.$emit('networkReady')

      this.toggleMapReady()
    },

    setLinesByTrafoFuse (lines, isolatedLines, trafoFuse) {
      const getIndex = (arr) =>
        arr.findIndex((linea) => linea.ID === trafoFuse.L)
      const linesIndex = getIndex(lines)

      if (linesIndex !== -1) {
        return {
          index: linesIndex,
          lines
        }
      }

      const isolatedIndex = getIndex(isolatedLines)

      return {
        index: isolatedIndex,
        lines: isolatedLines
      }
    },

    setElementStation ({
      elements,
      station,
      keys: [keyComp, keyList] = [],
      additionalPush = []
    }) {
      const collection = []

      elements.forEach((element) => {
        if (element[keyComp] === station.ID) {
          collection.push(element)

          this.listElements[keyList].push([
            ...additionalPush,
            element[keyComp],
            element.ID
          ])
        }
      })

      return collection
    },

    changeInstant (instant) {
      this.$store.dispatch('setElement', { path: 'instant', value: instant })
    }
  }
}
</script>

<style scoped>
#ms-map {
  min-height: 100% !important;
  height: 100% !important;
}
</style>

<style>
#ms-map .v-application--wrap {
  height: 100% !important;
}

.polyline-measurements-selected,
.circle-measurements-selected {
  stroke: #ed9026;
  stroke-opacity: 0.8;
}

.polyline-measurements-selected {
  stroke-width: 6px;
}

.circle-measurements-selected {
  fill: #ed9026;
  stroke-width: 10px;
}
.polyline-measurements-selected-detail {
  stroke: #f4c020;
  stroke-width: 6px;
}
.circle-measurements-selected-detail {
  stroke: #f4c020;
  fill: #f4c020;
  stroke-width: 10px;
}
#timeSlider.ms-time-slider {
  /* bottom: 18vh; */
  margin: auto;
}
</style>
