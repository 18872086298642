import HostingCapacityCreator from '@/assets/HostingCapacity/HostingCapacityCreator'
import { getHC, getHCForAllStations } from '../../services/hostingCapacity'

export default {
  data: () => ({
    HCMResponse: {},
    connPoints: [],
    elementHCM: {},
    grid: {},
    notAvailableColor: 'rgba(255, 255, 255, 0.4)',
    notAvailableText: 'Not Available',
    recalculatingText: 'Recalculating hosting capacity map...'
  }),
  methods: {
    async openHCM (grid, station) {
      this.connPoints = []
      this.grid = grid
      await this.download(station.ID)
      this.HCMResponse && (await this.prepareLines(grid, station))
    },

    async download (station) {
      const HC = new HostingCapacityCreator(this.$HEADER_HTTP, this.$API_HOST, this.$API_PORT,
        () => {}, console.error, console.warn, this.$DEBUG)
      this.HCMResponse = await HC.openHostingCapacityMap(station)
      this.HCMResponse && this.setVuexElement({
        path: 'hostingCapacity.HCMResults',
        value: this.HCMResponse
      })
    },

    /* For each connection point of the grid we get a list of the lines that are connected to that conn Point */
    async prepareLines (grid, station) {
      const linesAll = grid.Lines
      const lines = linesAll.filter((linesAll) => linesAll.NETWORK === station.NAME)
      const connPointsDetailed = await this.HCMResponse.getGrid().connPoints
      connPointsDetailed.forEach((connPoint) => {
        const connPointsLinesDetailed = lines.filter(line => line.CGP_HC.includes(connPoint.ID))
        const connPointsLines = []
        connPointsLinesDetailed.forEach((line) => {
          connPointsLines.push(line.ID)
        })
        this.connPoints.push({ connPoint: connPoint.ID, lines: connPointsLines })
      })
    },

    async getRawInstantHCM (HCMResponse, instant, phaseIndex, mode, tooltipDate) {
      const HCMResults = await HCMResponse.getHCMFrame(instant, instant, phaseIndex, mode)
      const dates = Array(HCMResults.length).fill(tooltipDate)
      const connPoints = this.addHCMValue(HCMResults, dates)
      return connPoints
    },

    /* Add the value of the hosting capacity for each conn Point and its tooltip with the date in which that worst value is obtain */
    addHCMValue (results, tooltipDates) {
      const connPointsValue = JSON.parse(JSON.stringify(this.connPoints))
      results.forEach((hcm, index) => {
        connPointsValue[index].value = hcm
        connPointsValue[index].date = tooltipDates[index]
      })
      return connPointsValue
    },

    async getInstantHCM (HCMResponse, instant, phaseIndex, mode, tooltipDate) {
      const instantHCM = await this.getRawInstantHCM(HCMResponse, instant, phaseIndex, mode, tooltipDate)
      if (!Object.keys(this.grid).length) {
        this.grid = this.areaGrid.Grid[400]
      }
      const connPointsWorstValue = this.getWorstConnPointValuePerLine(this.grid.Lines, instantHCM)
      return connPointsWorstValue
    },

    /* To assign a HC value to a line in the map, we get the worst value of all the conn Points connected to that line */
    getWorstConnPointValuePerLine (lines, connPoints) {
      lines.forEach((line) => {
        let worstCaseId, worstCaseValue
        line.CGP_HC.forEach((connPoint) => {
          if (!worstCaseId) { worstCaseId = connPoint.ID }
          const foundConnPoint = connPoints.filter((cp) => cp.ID === connPoint.ID)
          if (!worstCaseId || worstCaseValue > foundConnPoint.value) {
            worstCaseId = connPoint.ID
            worstCaseValue = foundConnPoint.value
          }
        })
        line.CGP_HC.forEach((connPoint) => {
          if (connPoint.ID !== worstCaseId) {
            // remove line from connPoint ConnPoints
            const foundConnPoint = connPoints.filter(obj => obj.ID === connPoint.ID)
            const foundConnPointIndex = connPoints.indexOf(obj => obj.ID === connPoint.ID)
            const lineIndex = foundConnPoint.indexOf(line)
            connPoints[foundConnPointIndex].lines.splice(lineIndex, 1)
          }
        })
      })
      return connPoints
    },

    async getElementHCM (network, HCMResponse, elementType, elementID, mode) {
      this.elementHCM.ID = elementID
      this.elementHCM.type = elementType
      if (elementType === 'line') {
        let worstCaseId, worstCaseValue
        // check all conn points in the line
        const networkHCResults = this.HCManageResults.find(el => el.network === network)
        networkHCResults.connPoints.forEach(connPoint => {
          if (connPoint.lines.includes(elementID)) {
            if (!worstCaseId || worstCaseValue > connPoint.value) {
              worstCaseId = connPoint.connPoint
              worstCaseValue = connPoint.value
            }
          }
        })
        elementID = worstCaseId
      }
      for (let i = 0; i < 4; i += 1) {
        this.elementHCM[i] = await HCMResponse.getHCMElement(HCMResponse.getConnPointID(elementID), i, mode)
      }
      return this.elementHCM
    },

    async getInfoHCM (station) {
      return getHC(station)
    },

    async getNetworksInfoHCM (stations, lowNetworks) {
      const stationIds = Object.values(stations)
      const stationsWithHCMInfo = await getHCForAllStations(stationIds)
      if (!stationsWithHCMInfo.length) {
        return []
      }

      for await (const stationWithHC of stationsWithHCMInfo) {
        const { referencePoint } = stationWithHC
        await referencePoint
        const manageResults = this.HCManageResults
        const ntIndex = lowNetworks.networks.findIndex((network) => stations[network.network.NAME] === stationWithHC.station)
        manageResults.push({
          network: lowNetworks.networks[ntIndex].network.NAME,
          infoHCM: stationWithHC
        })
        if (stationWithHC.is_solved) {
          await this.openHCM(this.areaGrid.Grid[400], { ID: stationWithHC.station, NAME: lowNetworks.networks[ntIndex].network.NAME })

          if (this.HCMResults && this.connPoints.length) {
            const manageResultsIndex = this.HCManageResults.findIndex((result) => result.infoHCM.station === stationWithHC.station)
            manageResults[manageResultsIndex].HCMResults = this.HCMResults

            let connPoints
            const worstDay = manageResults[manageResultsIndex].infoHCM[`worst_day_${this.selectedTypeViewMap}`]
            if (this.hcmWorstCaseView) {
              connPoints = await this.getWorstHCM(
                this.phaseIndex,
                this.hcmMode,
                worstDay
              )
            } else {
              const tooltipDate = this.getTimeForTooltip(worstDay, this.instant)
              connPoints = await this.getInstantHCM(
                this.HCMResponse,
                this.instant,
                this.phaseIndex,
                this.hcmMode,
                tooltipDate
              )
            }
            if (connPoints.length) {
              manageResults[manageResultsIndex].connPoints = connPoints
            }
          }
        }
        this.setVuexElement({
          path: 'hostingCapacity.HCManageResults',
          value: manageResults
        })
      }
      return stationsWithHCMInfo
    },

    async getWorstHCM (phaseIndex, mode, worstDay) {
      // Gets for each line and connection point the worst hosting capacity value in the worst day
      let responseWorstHCM
      let worstDates
      for (let instant = 0; instant < 24; instant += 1) {
        const responseInstantHCM = await this.HCMResponse.getHCMFrame(instant, instant, phaseIndex, mode)
        if (!responseWorstHCM) {
          responseWorstHCM = responseInstantHCM
          const worstDate = this.getTimeForTooltip(worstDay, instant)
          worstDates = Array(responseWorstHCM.length).fill(worstDate)
        } else {
          responseInstantHCM.forEach((hcm, index) => {
            if (hcm < responseWorstHCM[index]) {
              responseWorstHCM[index] = hcm
              worstDates[index] = this.getTimeForTooltip(worstDay, instant)
            }
          })
        }
      }
      if (!Object.keys(this.grid).length) {
        this.grid = this.areaGrid.Grid[400]
      }
      const connPoints = this.addHCMValue(responseWorstHCM, worstDates)
      const x = this.getWorstConnPointValuePerLine(this.grid.Lines, connPoints)
      return x
    },

    async updateUnsolvedHCStations () {
      const hcManageResults = this.HCManageResults

      const unsolvedStationIds = hcManageResults
        .filter((result) => !result.infoHCM.is_solved)
        ?.map((result) => {
          return result.infoHCM.station
        })
      if (unsolvedStationIds.length) {
        let stationsWithHCMInfo = await getHCForAllStations(unsolvedStationIds)
        stationsWithHCMInfo = stationsWithHCMInfo.filter((el) => el.is_solved)
        stationsWithHCMInfo?.forEach((stationWithInfo) => {
          const index = hcManageResults.findIndex(
            (result) => result.infoHCM.station === stationWithInfo.station
          )
          hcManageResults[index].infoHCM = stationWithInfo
        })
      }
      return hcManageResults
    },

    // Get information about Hosting Capacity for one station
    async getHCInfoForStation (
      lowNetworksIndex,
      networkIndex,
      stationId,
      networkName,
      stationInfo
    ) {
      const lowNetworks = this.networks[lowNetworksIndex]

      const manageResultIndex = this.HCManageResults.findIndex(
        (result) => result.infoHCM.station === stationId
      )
      const manageResult = this.HCManageResults[manageResultIndex]
      manageResult.infoHCM = stationInfo

      if (stationInfo?.is_solved) {
        await this.openHCMForStation(
          stationId,
          networkName,
          manageResult,
          lowNetworks,
          networkIndex
        )
      }
      const updatedResults = this.HCManageResults
      updatedResults[manageResultIndex] = manageResult
      this.setVuexElement({
        path: 'hostingCapacity.HCManageResults',
        value: updatedResults
      })
      if (this.HCManageResultsSolved.length) {
        this.connPoints = []
        this.setColorScaleLimits()
      }
      this.colorLinesHCMByNetwork(
        manageResult.connPoints,
        lowNetworks.networks[networkIndex],
        networkIndex,
        lowNetworksIndex
      )

      return lowNetworks.networks
    },

    async openHCMForStation (
      stationId,
      networkName,
      manageResult,
      lowNetworks,
      networkIndex
    ) {
      await this.openHCM(this.areaGrid.Grid[400], {
        ID: stationId,
        NAME: networkName
      })
      if (this.HCMResults && this.connPoints.length) {
        manageResult.HCMResults = this.HCMResults
        lowNetworks.networks[networkIndex].color = this.notAvailableColor

        let connPoints
        const worstDay =
          manageResult.infoHCM[`worst_day_${this.selectedTypeViewMap}`]
        if (this.hcmWorstCaseView) {
          connPoints = await this.getWorstHCM(
            this.phaseIndex,
            this.hcmMode,
            worstDay
          )
        } else {
          const tooltipDate = this.getTimeForTooltip(worstDay, this.instant)
          connPoints = await this.getInstantHCM(
            this.HCMResponse,
            this.instant,
            this.phaseIndex,
            this.hcmMode,
            tooltipDate
          )
        }
        if (connPoints.length) {
          manageResult.connPoints = connPoints
        }
      } else {
        lowNetworks.networks[networkIndex].color = this.notAvailableColor
        lowNetworks.networks[networkIndex].tooltipText = this.recalculatingText
        lowNetworks.networks[networkIndex].notAvailableHC = true
      }
    },

    setWhiteColorForStation (lowNetworks, networkIndex) {
      if (this.HCManageResultsSolved.length) {
        this.setColorScaleLimits()
        lowNetworks.networks[networkIndex].color = this.notAvailableColor
        lowNetworks.networks[networkIndex].tooltipText = this.recalculatingText
        lowNetworks.networks[networkIndex].notAvailableHC = true
        lowNetworks.networks[networkIndex].connection_points =
          lowNetworks.networks[networkIndex].connection_points.map((cp) => {
            if (cp.tooltip) {
              cp.tooltip = this.recalculatingText
              cp.notAvailableHC = true
              cp.tooltipAbsolute = ''
              cp.colorHC = this.notAvailableColor
            }
            return cp
          })
        lowNetworks.networks[networkIndex].lines = lowNetworks.networks[
          networkIndex
        ].lines.map((line) => {
          if (line.tooltip) {
            line.tooltip = this.recalculatingText
            line.notAvailableHC = true
            line.tooltipAbsolute = ''
            line.color = this.notAvailableColor
          }
          return line
        })
      }
      return lowNetworks
    }
  }
}
