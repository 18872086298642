import Vue from 'vue'
import { Icon } from 'leaflet'
import store from './store'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import VueMeta from 'vue-meta'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import 'vue-easytable/libs/theme-dark/index.css'
import { VeTable, VePagination } from 'vue-easytable'
import { datadogRum } from '@datadog/browser-rum'

Vue.component('VueSlider', VueSlider)

if (!+process.env.VUE_APP_DEBUG) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.VUE_APP_DATADOG_SITE,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    env: process.env.VUE_APP_DATADOG_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: ['https://flex.plexigrid.app']

  })

  datadogRum.startSessionReplayRecording()
}

Vue.use(VeTable)
Vue.use(VePagination)
Vue.use(VueMeta)

// GLOBAL VARIABLES
Vue.prototype.$API_HOST = process.env.VUE_APP_API_HOST
Vue.prototype.$API_PORT = process.env.VUE_APP_API_PORT
Vue.prototype.$MAPSERVER = !!+process.env.VUE_APP_MAPSERVER
Vue.prototype.$API_MAPSERVERHOST = process.env.VUE_APP_API_MAPSERVERHOST
Vue.prototype.$API_MAPSERVERPORT = process.env.VUE_APP_API_MAPSERVERPORT
Vue.prototype.$UTM = process.env.VUE_APP_UTM
Vue.prototype.$TIME_SPLIT = process.env.VUE_APP_TIME_SPLIT
Vue.prototype.$STEP_TIME = process.env.VUE_APP_STEP_TIME
Vue.prototype.$TIME_INTERVAL = process.env.VUE_APP_TIME_INTERVAL
Vue.prototype.$HEADER_HTTP = process.env.VUE_APP_HEADER_HTTP
Vue.prototype.$HEADER_WS = process.env.VUE_APP_HEADER_WS
Vue.prototype.$DEBUG = !!+process.env.VUE_APP_DEBUG
Vue.prototype.$WGS84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs'

Vue.prototype.$sessionStorage = new Vue({
  data: {
    logged: window.sessionStorage.getItem('logged'),
    zoneId: window.sessionStorage.getItem('zoneId'),
    gridElement: window.sessionStorage.getItem('gridElement'),
    theme: window.sessionStorage.getItem('theme'),
    timeZone: window.sessionStorage.getItem('timeZone'),
    projectCoordSystem: window.sessionStorage.getItem('projectCoordSystem')
  },
  watch: {
    logged(value) {
      window.sessionStorage.setItem('logged', value)
    },
    zoneId(value) {
      value
        ? window.sessionStorage.setItem('zoneId', value)
        : window.sessionStorage.removeItem('zoneId')
    },
    gridElement(value) {
      value
        ? window.sessionStorage.setItem('gridElement', value)
        : window.sessionStorage.removeItem('gridElement')
    },
    theme(value) {
      window.sessionStorage.setItem('theme', value)
    },
    timeZone(value) {
      window.sessionStorage.setItem('timeZone', value)
    },
    projectCoordSystem(value) {
      window.sessionStorage.setItem('projectCoordSystem', value)
    }
  }
})

Vue.config.productionTip = false
Vue.config.silent = true

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app')
