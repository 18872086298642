<template>
  <l-icon
    :icon-anchor="staticAnchor"
    class-name="circle-status"
  >
    <div class="headline">
      {{ emptyText }}
    </div>
  </l-icon>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import { LIcon } from 'vue2-leaflet'
export default {
  name: 'TIAStationIcon',

  components: {
    LIcon
  },
  props: {
    location: {
      type: Array,
      default: () => ([])
    },
    uuid: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    staticAnchor: [16, 37],
    emptyText: ''
  }),

  computed: {
  },
  mounted () {
  },
  methods: {
    clickIcon (plant) {
    //   this.$store.dispatch('setElement', { path: 'plantSelected', value: plant })
    //   this.$store.dispatch('setElement', { path: 'tabPlants', value: 'plant' })
    }
  }
}
</script>

<style >

/* .circle-status {
  background-color: transparent;
  padding: 10px;
  border: 8px solid rgba(57, 81, 97, 0.9);
  border-radius: 20px 20px 20px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
} */
.circle-status {
  width: 34px;
  height: 35px;
  display: grid;
}
.circle-status::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0px;
  right: -27px;
  bottom: -40px;
  border-radius: 50%;
  background-color: rgba(57, 81, 97, 0.5);
  z-index: -1;
  border: 8px solid;
  animation: border-animation-none 6s infinite linear;
}
@keyframes border-animation-none  {
    0% {
      border-color: rgba(57, 81, 97, 0.9)
    }
    25% {
      border-color: rgba(57, 81, 97, 0.9);
    }
    50% {
      border-color: rgba(57, 81, 97, 0.9)
    }
    75% {
      border-color: rgba(57, 81, 97, 0.9);
    }
    100% {
      border-color: rgba(57, 81, 97, 0.9)
    }
  }

</style>
