<template>
  <v-form
    ref="TopologyDataUploadForm"
    v-model="valid"
    lazy-validation
  >
    <v-row
      v-if="!sent"
      align="center"
      align-content="center"
      justify="center"
    >
      <v-col
        cols="12"
        self-align="center"
        style="margin:30px 10px 0px 10px"
        class="form-text"
      >
        You are about to load {{ fileTitle }} file
        <div style="font-size:12px">
          *Make sure it contains topology information
        </div>
      </v-col>
      <v-col
        cols="12"
        self-align="center"
      >
        <v-btn
          color="#f4c020"
          text
          style="margin:20px"
          @click="uploadTopologyFile"
        >
          Load File
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-else
      align="center"
      align-content="center"
      justify="center"
    >
      <v-col
        cols="12"
        self-align="center"
        style="margin:30px 10px 0px 10px"
        class="form-text"
      >
        <v-alert
          :type="responsetype"
          :color="responsecolor"
        >
          {{ responsemessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { post } from '@/assets/queries'

export default {
  name: 'TopologyDataUpload',
  props: {

    fileData: {
      type: File,
      default: null
    },
    fileTitle: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      valid: true,
      sent: false,
      responsetype: null,
      responsecolor: null,
      responsemessage: null
    }
  },
  methods: {
    uploadFile () {
      this.$emit('uploadFile')
    },

    async uploadTopologyFile () {
      const myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/zip')

      const file = this.fileData

      const response = await post(`https://labs.plexigrid.app/importer/csv/${this.fileTitle}`, myHeaders, file)

      this.sent = true

      if (response === 'success') {
        this.responsetype = 'success'
        this.responsecolor = 'green'
        this.responsemessage = `file ${this.fileTitle} has been sent successfully`
      } else {
        this.responsetype = 'error'
        this.responsecolor = 'red'
        this.responsemessage = `file ${this.fileTitle} could not be loaded: ${response}`
      }
    }
  }
}
</script>
<style scoped>
.form-text{
  color:#cccccc; font-family:'Faktum Light', sans-serif;
}
</style>
