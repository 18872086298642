<template>
  <div class="substations">
    <!--STATIONS-->
    <div
      id="CT"
      class="dashboard-col"
    >
      <div
        id="CTs_card"
        class="card dark-theme"
        style="background-color:#1F1F1F!important;padding:10px;"
      >
        <v-tooltip right>
          <template #activator="{ on }">
            <v-img
              src="@/assets/images/dbstations.png"
              width="50"
              alt="Stations"
              v-on="on"
            />
          </template>
          <span>{{ $t('grid.stations') }}</span>
        </v-tooltip>
        <!-- <img src="@/assets/images/dbstations.png" alt="Stations" width="50" > -->
        <div class="row ">
          <div class="dato-numerico col-xl-6 col-sm-6 col-12">
            <div
              id="station_count"
              class="numero dark-theme"
            >
              {{ station_count }}
            </div>
            <div class="texto dark-theme">
              {{ $t('substations.station_count') }}
            </div>
          </div>
          <div class="dato-numerico col-xl-6 col-sm-6 col-12">
            <div
              id="station_trafo_counts"
              class="numero dark-theme"
            >
              {{ station_trafo_counts }}
            </div>
            <div class="texto dark-theme">
              {{ $t('substations.transformer_count') }}
            </div>
          </div>
        </div>
        <!-- End First row -->
        <div
          class="row  align-item-end"
          style="margin-right:20px"
        >
          <div class="dato-piechart col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.station_count_per_power') }}
            </div>
            <div id="station_power" />
          </div>
          <div class="dato-piechart col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.station_count_per_feeder_count') }}
            </div>
            <div id="station_fuses" />
          </div>
          <div class="dato-piechart col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.transformer_count_per_feeder_count') }}
            </div>
            <div id="station_lines" />
          </div>
          <div class="dato-piechart col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.transformer_count_per_power') }}
            </div>
            <div id="trafo_power" />
          </div>
        </div>
        <div class="row  align-item-end">
          <div class="dato-histograma col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.installed_stations_per_year') }}
            </div>
            <div id="station_installDate" />
          </div>
          <div class="dato-histograma col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.connection_per_station') }}
            </div>
            <div id="station_CGP" />
          </div>
          <div class="dato-histograma col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.clients_per_station') }}
            </div>
            <div id="station_clients" />
          </div>
          <div class="dato-histograma col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="texto dark-theme">
              {{ $t('substations.km_per_station') }}
            </div>
            <div id="station_lineLength" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getData from '@/mixins/dashboardGetData'
import sortData from '@/mixins/dashboardSortData'
import barChart from '@/mixins/dashboardBarChart'
import pieChart from '@/mixins/dashboardPieChart'

export default {
  name: 'SubStations',

  mixins: [barChart, pieChart, sortData, getData],
  props: {
    info: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    station_count: '0',
    station_supervisors_count: '0',
    station_concentrators_count: '0',
    station_lineSupervisor_count: '0',
    station_trafo_counts: '0'
  }),

  mounted () {
    // count statistics - FUSES
    // console.log(this.info);

    this.station_count = this.info.statistics_station.countCT
    this.station_trafo_counts = this.info.statistics_station.countTransformer

    const CTPowerData = this.info.statistics_station.pieCTPower
    const CTFusesData = this.info.statistics_station.pieCTFuse
    const CTLinesData = this.info.statistics_station.pieCTLines
    const TrafoPowerData = this.info.statistics_station.pieTrafoPower

    this.pieChart('#station_power', CTPowerData)
    this.pieChart('#station_fuses', CTFusesData)
    this.pieChart('#station_lines', CTLinesData)
    this.pieChart('#trafo_power', TrafoPowerData)

    // Bar Chart statistics
    const InstallDateData = this.info.statistics_station.histogramDate
    const CGPCTData = this.info.statistics_station.histogramCGPbyStation
    const clientsCTData = this.info.statistics_station.histogramClients
    const lineLengthCTData = this.info.statistics_station.histogramLength

    this.barChart('#station_installDate', InstallDateData)
    this.barChart('#station_CGP', CGPCTData)
    this.barChart('#station_clients', clientsCTData)
    this.barChart('#station_lineLength', lineLengthCTData)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.substations{
  background-color: rgb(53, 49, 49);
  /* height: 63vh; */
}
h1{
  color:#F4C020;
}
</style>
