/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import Vuex from 'vuex'
import {
  set, get, unset
} from 'lodash'
import {
  APPEND_CASE,
  TOGGLE_ELEMENT,
  SET_ELEMENT,
  DTCHANGES_EMPTY,
  DELETE_ELEMENT,
  INITIAL_STATE,
  RESET_STATE
} from './constants'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: INITIAL_STATE(),

  mutations: {
    TOGGLE_ELEMENT (state, payload) {
      payload.value
        ? (state[payload.path].isVisible = payload.value)
        : (state[payload].isVisible = !state[payload].isVisible)
    },

    SET_ELEMENT (state, { path, value, root }) {
      set(state, path, value)
      root && set(state, root, { ...state[root] })
    },

    RESET_STATE (state) {
      Object.assign(state, INITIAL_STATE())
    },

    DELETE_ELEMENT (state, { path, force }) {
      force
        ? Vue.delete(state, path)
        : unset(state, path)
    },

    APPEND_CASE (state, { path, value }) {
      const prevElements = get(state, path)

      set(state, path, {
        ...prevElements,
        [value.name]: { mod: value.mod }
      })
    }
  },

  actions: {
    toggleElement ({ commit }, payload) {
      commit(TOGGLE_ELEMENT, payload)
    },

    setNewPosition ({ commit }, { x, y, toggle }) {
      commit(SET_ELEMENT, {
        path: 'contextMenu.position',
        value: { x, y }
      })

      commit(TOGGLE_ELEMENT, toggle)
    },

    setClick ({ commit }, { lat, lng, idElement }) {
      commit(SET_ELEMENT, {
        path: 'click',
        value: { lat, lng, idElement }
      })
    },

    setElement ({ commit }, payload) {
      commit(SET_ELEMENT, payload)
    },

    resetState ({ commit }) {
      commit(RESET_STATE)
    },

    deleteElement ({ commit }, payload) {
      commit(DELETE_ELEMENT, payload)
    },

    appendCase ({ commit }, payload) {
      commit(APPEND_CASE, payload)
    },

    setDTChange ({ commit }, {
      path, values, append, remove, customIndex
    }) {
      const device = get(this, `state.DTChanges.${path}`)
      const markerId = get(this, 'state.currentMarker.id')
      const entries = Object.entries(values)
      let deviceChangeIndex

      customIndex !== undefined && (deviceChangeIndex = customIndex)

      if (markerId && !deviceChangeIndex) {
        const [cpId, changeIndex] = markerId.split('-').map(Number)
        let counter = -1

        device.CGP
          .forEach((id, index) => {
            id === cpId && (counter += 1)

            counter === changeIndex &&
              !Number.isInteger(deviceChangeIndex) &&
              (deviceChangeIndex = index)
          })
      }

      entries.forEach(([key, value]) => {
        if (remove) {
          const removeItem = (_, index) => index !== deviceChangeIndex
          device[key] = device[key].filter(removeItem)
        } else {
          append
            ? device[key] = [...device[key], value]
            : device[key][deviceChangeIndex] = value
        }
      })

      commit(SET_ELEMENT, {
        path: `DTChanges.${path}`,
        value: device,
        root: 'DTChanges'
      })
    }
  },

  getters: {
    isContextMenu: ({ contextMenu }) => get(contextMenu, 'isVisible', false),

    lines: ({ DTProject }) => {
      const lines = []
      const networks = get(DTProject, 'networksInfo')
      networks.forEach(level => {
        level.networks.forEach(network => {
          lines.push(network.lines)
        })
      })
      return lines.flat(1)
    },

    connectionPoints: ({ DTProject }) => {
      const connectionPoints = []
      const networks = get(DTProject, 'networksInfo')
      networks.forEach(level => {
        level.networks.forEach(network => {
          connectionPoints.push(network.connection_points)
        })
      })
      return connectionPoints.flat(1)
    },

    fuses: ({ DTProject }) => {
      const networks = get(DTProject, 'networksInfo')
      const closedFuses = []
      networks.forEach(level => {
        level.networks.forEach(network => {
          closedFuses.push(network.closed_fuses)
        })
      })
      const openFuses = get(DTProject, 'openFuses')
      return [...closedFuses.flat(1), ...(openFuses ?? [])]
    },

    stations: ({ DTProject }) => {
      const stations = []
      const networks = get(DTProject, 'networksInfo')
      networks.forEach(level => {
        stations.push(level.stations)
      })
      return stations.flat(1)
    },

    cases: ({ DTResults }) => {
      const casesAsObj = get(DTResults, 'cases', {})
      const casesAsArr = Object.entries(casesAsObj)
      return casesAsArr.map(
        ([name, data]) => {
          const hasChanges = data.mod && Object.keys(data.mod).length

          return ({
            ...data,
            name,
            mod: hasChanges ? data.mod : DTCHANGES_EMPTY()
          })
        }
      )
    },

    currentMod: ({ DTResults, currentCase }) => {
      if (currentCase) {
        const { cases } = DTResults
        const caseWithMod = cases[currentCase]

        return caseWithMod && caseWithMod.mod
      }

      return undefined
    },

    HCManageResultsSolved: state => {
      return state.hostingCapacity.HCManageResults.filter((el) => el.infoHCM.is_solved)
    }
  }
})

export default store
