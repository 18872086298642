var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',{attrs:{"align":"center","align-content":"center","justify":"center"}},[_c('v-col',{staticStyle:{"color":"#f4c01f"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)],1),_c('v-tabs',{staticClass:"tabs",staticStyle:{"margin-top":"5px"},attrs:{"vertical":"","slider-size":"6","color":"#f4c020"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.value},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('h1',_vm._g(_vm._b({attrs:{"left":""}},'h1',attrs,false),on),[_vm._v(" "+_vm._s(tab.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(tab.tooltip))])])],1)}),_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.value},[_c('v-card',{staticStyle:{"margin":"0px","padding":"0px"}},[(_vm.currentTab ? !_vm.reservesGeneration.length : !_vm.reservesConsumption.length)?_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('no_data_available'))+" ")]):_c('v-card-text',{staticStyle:{"overflow":"auto"}},[_c('CMTable',{attrs:{"items":_vm.currentTab ? _vm.reservesGeneration : _vm.reservesConsumption,"show-select":false,"page-size":12,"height":_vm.cardHeight,"extra-styles":{ marginTop: '20px' },"header-config":{
              id: { notFilterable: true },
              capacity: { notFilterable: true },
              reservation_start_date: { notFilterable: true },
              reservation_expiration_date: { notFilterable: true },
              remove: { notFilterable: true }
            },"slots":_vm.slots,"custom-title":_vm.headerTitle}})],1)],1)],1)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }