<template>
  <v-form
    v-if="DDBB"
    ref="EventsDataLoadForm"
    v-model="valid"
    lazy-validation
  >
    <div v-if="!sent">
      <v-row
        align="center"
        align-content="center"
        justify="center"
        style="padding: 0px 20px"
      >
        <v-col
          cols="12"
          self-align="center"
          class="form-text"
          style="margin-top:30px"
        >
          <v-autocomplete
            v-model="dbValue"
            dark
            dense
            :items="DDBB"
            item-text="text"
            item-value="text"
            label="Database"
          />
        </v-col>
        <v-col
          v-if="dbFields"
          cols="12"
          self-align="center"
        >
          <v-row>
            <v-col
              cols="12"
              self-align="center"
              style="margin-top:30px"
              class="form-text"
            >
              <v-autocomplete
                v-model="refValue"
                dark
                dense
                :items="refHeaders"
                item-text="text"
                item-value="text"
                label="Ref"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="timeValue"
                dark
                dense
                :items="timeHeaders"
                item-text="text"
                item-value="text"
                label="Time"
              />
            </v-col>
          </v-row>
          <v-row
            v-for="(field,index) in dbFields"
            :key="index"
            justify="center"
          >
            <v-col
              cols="12"
              self-align="center"
              class="form-text"
            >
              <v-autocomplete
                v-model="eventsValues[index]"
                dark
                dense
                :items="eventsHeaders[index]"
                :label="field"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          self-align="center"
        >
          <v-btn
            color="#f4c020"
            text
            style="margin:20px"
            :disabled="!formReady"
            @click="uploadEventsFile"
          >
            {{ $t('importer.load_file') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="uploading || set">
      <v-row
        align="center"
        align-content="center"
        justify="center"
      >
        <v-col
          cols="12"
          self-align="center"
          style="margin:30px 10px 0px 10px"
          class="form-text"
        >
          <CMLoader
            v-if="uploading"
            :loading="uploading"
            :message="$t('importer.parsing_file')"
            :overlay="false"
          />
          <v-alert
            v-else
            :type="responseType"
            :color="responseColor"
          >
            {{ responseMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>

import { getDDBB, getDBFields } from '@/services/dataImporter'
import { post } from '@/assets/queries'

export default {
  name: 'EventsDataUpload',

  components: {
    CMLoader: () => import('@/components/Common/CMLoader.vue')
  },

  props: {
    headers: {
      type: Array,
      default: null
    },
    content: {
      type: Object,
      default: null
    },
    parsed: {
      type: Boolean,
      default: false
    },
    fileData: {
      type: File,
      default: null
    },
    fileTitle: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      valid: true,
      sent: false,
      uploading: false,
      DDBB: null,
      DDBBReady: false,
      dbValue: null,
      dbFields: null,
      fieldsValues: null,
      refValue: null,
      timeValue: null,
      eventsValues: null,
      formReady: false,
      responseMessage: null,
      responseType: null,
      responseColor: null
    }
  },

  computed: {
    refHeaders () {
      return this.headers.filter(header => header !== this.timeValue && !this.eventsValues.includes(header))
    },
    timeHeaders () {
      return this.headers.filter(header => header !== this.refValue && !this.eventsValues.includes(header))
    },
    eventsHeaders () {
      const events = new Array(this.dbFields.length)
      for (let i = 0; i < this.dbFields.length; i += 1) {
        events[i] = this.headers.filter(header => header !== this.refValue && header !== this.timeValue)
      }
      this.eventsValues.forEach((value, vindex) => {
        if (value !== null) {
          for (let i = 0; i < this.dbFields.length; i += 1) {
            if (vindex !== i) {
              events[i] = events[i].filter(header => header !== value)
            }
          }
        }
      })
      return events
    }
  },

  watch: {
    async dbValue () {
      this.dbFields = await getDBFields(this.dbValue)
      this.eventsValues = [this.dbFields.length]
      this.fieldsValues = [this.dbFields.length]
    },
    refValue () {
      this.checkFormReady()
    },
    timeValue () {
      this.checkFormReady()
    },
    eventsValues () {
      this.checkFormReady()
    }
  },

  async mounted () {
    this.DDBB = await getDDBB()
    this.signalsReady = true
  },

  methods: {
    checkFormReady () {
      if (this.eventsValues.length === this.dbFields.length && this.refValue !== null && this.timeValue !== null) {
        this.formReady = true
      } else {
        this.formReady = false
      }
    },
    async uploadEventsFile () {
      this.uploading = true
      this.sent = true
      const formdata = new FormData()
      formdata.append('file', this.fileData, this.fileTitle)
      formdata.append('reference', this.refValue)
      formdata.append('timestamp', this.timeValue)
      formdata.append('dbname', this.dbValue)
      formdata.append('field_col', this.eventsValues.toString())

      const myHeaders = new Headers()

      const response = await post('https://labs.plexigrid.app/importer/event', myHeaders, formdata)
      this.loading = false
      if (response === 'success') {
        this.responseType = 'success'
        this.responseColor = 'green'
        this.responseMessage = `file ${this.fileTitle} has been sent successfully`
      } else {
        this.responseType = 'error'
        this.responseColor = 'red'
        this.responseMessage = `file ${this.fileTitle} could not be loaded: ${response}`
      }
    }
  }

}
</script>
<style scoped>
.form-text{
   color:#cccccc; font-family:'Faktum Light', sans-serif;
}
</style>
