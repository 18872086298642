<template>
  <div v-if="switchBoxFusesData">
    >
    <l-polyline
      v-for="(fuse,index) in switchBoxFusesData"
      :key="index"
      :lat-lngs="fuse.latLngs"
      :color="fuse.color"
      :fill="'true'"
      :fill-color="fuse.color"
      :fill-opacity="'0.9'"
      :class-name="'networkNone' + ' fuse' + fuse.ID + ' polyline'"
      @mouseover="mouseover($event.target, fuse.ID, 'fuse', level, undefined)"
      @mouseleave="mouseleave($event.target, fuse.ID, 'fuse', level, undefined)"
      @click="click(level, undefined, fuse.ID, 'fuse', false, hostingCapacityMap, multiselect)"
      @contextmenu="handleContextmenu($event, fuse)"
    >
      <l-tooltip v-if="hostingCapacityMap && tooltipText">
        <strong>{{ tooltipText }}</strong>
      </l-tooltip>
    </l-polyline>
  </div>
</template>

<script>
import { LTooltip, LPolyline } from 'vue2-leaflet'
import proj4 from 'proj4'
import * as turf from '@turf/turf'
import click from '@/mixins/map/mapElementClickMixin'
import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import mouseover from '@/mixins/map/mapElementMouseoverMixin'
import mouseleave from '@/mixins/map/mapElementMouseleaveMixin'
import mapElementOpacityMixin from '@/mixins/map/mapElementOpacityMixin'
import mapElementColorMixin from '@/mixins/map/mapElementColorMixin'

export default {
  name: 'SwitchBoxFuse',
  components: {
    LPolyline,
    LTooltip
  },
  mixins: [click, unclickElements, clickElements, mouseover, mouseleave, mapElementOpacityMixin, mapElementColorMixin],
  props: {
    switchBoxFusesData: {
      type: Array,
      required: true
    },
    colorStation: {
      type: String,
      default: '#00d400'
    },
    mode: {
      type: String,
      default: ''
    },
    multiselect: {
      type: Boolean,
      default: false
    },
    contextMenu: {
      type: Boolean,
      default: false
    },
    level: {
      type: String,
      default: ''
    },
    hostingCapacityMap: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      fuseColor: this.color,
      blackColor: '#000000',
      whiteColor: '#ffffff'
    }
  },
  computed: {
    fuses () {
      const FusesUpdated = this.fusesData

      // Initializing proj4 parameters
      if (typeof this.fusesData !== 'undefined') {
        this.fusesData.forEach((element, index) => {
          const punto1 = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [element.X1, element.Y1])
          const punto2 = proj4(this.$sessionStorage.projectCoordSystem, this.$WGS84, [element.X2, element.Y2])
          const punto1Turf = turf.point([punto1[0], punto1[1]])
          const punto2Turf = turf.point([punto2[0], punto2[1]])
          const bearing = turf.bearing(punto1Turf, punto2Turf)
          const perpendicular = bearing + 90
          const distance = 0.4
          const distanceNegative = -0.4
          // const long = -1
          const point1 = turf.destination(punto1, distanceNegative, perpendicular, { units: 'meters' })
          const point2 = turf.destination(punto1, distance, perpendicular, { units: 'meters' })
          // const point3 = turf.destination(point2, long, bearing, { units: 'meters' })
          // const point4 = turf.destination(point1, long, bearing, { units: 'meters' })
          const point3 = turf.destination(punto2, distanceNegative, perpendicular, { units: 'meters' })
          const point4 = turf.destination(punto2, distance, perpendicular, { units: 'meters' })
          // const point5 = turf.destination(point4, distance, perpendicular, { units: 'meters' })
          const latLngs = [[punto1[1], punto1[0]],
            [punto2[1], punto2[0]],
            [point3.geometry.coordinates[1], point3.geometry.coordinates[0]],
            [point1.geometry.coordinates[1], point1.geometry.coordinates[0]],
            [point2.geometry.coordinates[1], point2.geometry.coordinates[0]],
            [point4.geometry.coordinates[1], point4.geometry.coordinates[0]],

            [punto2[1], punto2[0]]

          ]
          FusesUpdated[index].latLngs = latLngs
        })
      }
      return FusesUpdated
    }
  },
  watch: {
    colorStation (newColor) {
      if (newColor === '#f4c020') {
        this.fuseColor = '#f4c020'
      } else {
        this.fuseColor = this.color
      }
    }
  },
  methods: {
    handleContextmenu (e, fuse) {
      if (this.contextMenu) {
        const contextMenuPayload = {
          x: e.containerPoint.x + 75,
          y: e.containerPoint.y + 75,
          toggle: 'contextMenu'
        }

        const fusePayload = {
          path: 'contextMenuElement',
          value: { type: 'contextMenuFuse', info: fuse }
        }

        this.$store.dispatch('setNewPosition', contextMenuPayload)
        this.$store.dispatch('setElement', fusePayload)
      }
    }
  }
}
</script>
