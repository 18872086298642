<template>
  <div v-if="showForm">
    <ASDialog
      :source="source"
      @getArea="getArea"
      @getElement="getElement"
    />
  </div>
  <div
    v-else
  >
    <div class="upload">
      <form
        action
        :class="`form ${customProfile ? 'upload-cp-form' : ''}`"
        @submit.prevent="upload"
      >
        <h2
          v-if="showTitle"
          id="upload-title"
        >
          {{ $t('importer.title') }}
        </h2>
        <v-card
          dark
          elevation="5"
          style="margin-bottom:20px"
        >
          <v-row
            align="center"
            align-content="center"
            justify="center"
            class="upload-row"
          >
            <v-col
              class="upload-col"
            >
              <v-file-input
                id="file"
                v-model="dataFile"
                :placeholder="acceptedExtensions"
                chips
                color="#f4c020"
                dark
                :accept="acceptedExtensions"
                :disabled="disabledUpload"
                :label="disabledUpload ? titleEditFile : ''"
                @change="readFile()"
              >            
                <template v-slot:append-outer v-if="customProfile">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">{{ mdiInformation }}</v-icon>
                    </template>
                    <div class="text-left">
                      <div>{{ $t('importer.only_csv_files_allowed') }}</div>
                      <div>{{ $t('importer.at_least_two_columns') }}</div>
                      <div>kW {{ $t('importer.unit_expected') }}</div>
                    </div>
                  </v-tooltip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
        </v-card>
        <CMLoader
          v-if="parsing"
          :loading="parsing"
          :message="$t('importer.parsing_file')"
          :overlay="false"
        />
        <v-card
          v-else-if="csv || zip"
          dark
          elevation="5"
        >
          <v-row
            v-if="!customProfile"
            align="center"
            align-content="center"
            justify="center"
            style="margin-top:20px"
          >
            <v-col
              self-align="center"
            >
              <v-btn-toggle
                v-if="zip"
                v-model="toggleTypeOfzip"
                dense
                mark
                mandatory
              >
                <v-btn
                  v-if="zip"
                  rounded
                >
                  {{ $t('importer.topology') }}
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle
                v-if="csv"
                v-model="toggleTypeOfcsv"
                dense
                dark
                mandatory
              >
                <v-btn
                  rounded
                >
                  {{ $t('measurements') }}
                </v-btn>
                <v-btn
                  rounded
                >
                  {{ $t('events') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>

          <TopologyDataUpload
            v-if="zip && !customProfile"
            :key-form="keyFormOpen"
            :file-data="dataFile"
            :file-title="fileTitle"
          />
          <MeasurementsDataUpload
            v-if="showMeasurements && csv && parsed && !customProfile"
            :key-form="keyFormOpen"
            :file-data="dataFile"
            :file-title="fileTitle"
            :headers="headers"
            :content="content"
            :parsed="parsed"
          />
          <EventsDataUpload
            v-if="showEvents && csv && parsed && !customProfile"
            :key-form="keyFormOpen"
            :file-data="dataFile"
            :file-title="fileTitle"
            :headers="headers"
            :content="content"
            :parsed="parsed"
          />
          <CustomProfileUpload
            v-if="customProfile"
            :key-form="keyFormOpen"
            :file-data="dataFile"
            :file-title="fileTitle"
            :headers="headers"
            :content="content"
            :parsed="parsed"
            @change="change"
          />
        </v-card>
      </form>
    </div>
  </div>
</template>

<script>

import Papa from 'papaparse'
import MeasurementsDataUpload from '@/components/DataUpload/MeasurementsDataUploadForm.vue'
import TopologyDataUpload from '@/components/DataUpload/TopologyDataUploadForm.vue'
import EventsDataUpload from '@/components/DataUpload/EventsDataUploadForm.vue'
import CustomProfileUpload from '@/components/DataUpload/CustomProfileUploadForm.vue'
import { mapState } from 'vuex'
import { getAreaMap } from '@/services/areaSelector'
import getAreaGridMixin from '@/mixins/getAreaGridMixin'
import { mdiInformation } from '@mdi/js'

export default {
  name: 'DataUpload',
  components: {
    MeasurementsDataUpload,
    TopologyDataUpload,
    EventsDataUpload,
    CustomProfileUpload,
    ASDialog: () => import('@/components/AreaSelector/ASDialog.vue'),
    CMLoader: () => import('@/components/Common/CMLoader.vue')
  },
  mixins: [getAreaGridMixin],
  props: {
    customProfile: {
      type: Boolean,
      default: false
    },
    acceptedExtensions: {
      type: String,
      default: '.csv, .zip'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    disabledUpload: {
      type: Boolean,
      default: false
    },
    titleEditFile: {
      type: String,
      default: ''
    }
  },
  metaInfo: {
    title: 'Data Upload'
  },

  data () {
    return {
      mdiInformation,
      toggleTypeOfzip: this.formDefaultAction,
      toggleTypeOfcsv: this.formDefaultAction,
      showMeasurements: false,
      showEvents: false,
      keyFormOpen: 0,
      show: this.showForm,
      dataFile: null,
      csv: false,
      zip: false,
      content: {},
      headers: null,
      parsed: false,
      fileTitle: null,
      fileSent: null,
      source: null,
      parsing: false
    }
  },
  computed: {
    ...mapState({
      showForm: (state) => state.showSetAreaForm
    })
  },
  watch: {
    keyForm () {
      this.keyFormOpen += 1
    },
    formDefaultAction () {
      this.toggleTypeOfcsv = this.formDefaultAction
    },
    toggleTypeOfcsv (typeOfData) {
      if (typeOfData === 0) {
        this.showTopology = false
        this.showMeasurements = true
        this.showEvents = false
      } else {
        this.showTopology = false
        this.showMeasurements = false
        this.showEvents = true
      }
    }
  },

  async mounted () {
    this.$store.dispatch('setElement', {
      path: 'showSetAreaForm',
      value: false
    })
    this.source = await getAreaMap()
  },

  methods: {
    getArea () {
      this.getAreaGrid()
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: false
      })
    },
    getElement () {
      this.getElementGrid()
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: false
      })
    },
    readFile () {
      this.fileSent = false
      if (this.dataFile === null) {
        this.content = null
        this.headers = null
        this.parsed = false
        this.fileTitle = null
        this.csv = false
        this.zip = false
      } else if (this.dataFile.name.substr(this.dataFile.name.indexOf('.') + 1) === 'csv') {
        this.parsing = true
        this.csv = true
        this.zip = false
        this.fileTitle = this.dataFile.name
        Papa.parse(this.dataFile, {
          header: false,
          skipEmptyLines: true,
          chunk: true,
          chunkSize: 10000000,
          worker: true,
          complete: (results) => {
            this.content = results
            this.headers = results.data[0]
            this.parsing = false
            this.parsed = true
          }
        })
      } else if (this.dataFile.name.substr(this.dataFile.name.indexOf('.') + 1) === 'zip') {
        this.fileTitle = this.dataFile.name
        this.zip = true
        this.csv = false
      }
    },
    change (value) {
      this.$emit('change', value)
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  color: #f4c020;
  font-family: 'Faktum Light' !important;
}
.v-btn.v-item--active{
  background-color: #f4c020 !important;
  color:#000 !important;
}

.title {
  text-align: center;
  font-size: 24px;
  color: #f4c020;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 500px;
  max-width: 100%;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  align-self: flex-start;
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
  &:first-of-type {
    margin-top: 0rem;
  }
}
.form-input {
  padding: 10px 15px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
  &:focus {
    outline: 0;
    border-color: #f4c020;
  }
}
.form-submit {
  background: #272727 !important;
  border: none;
  color: #f4c020 !important;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  text-align: center;
  transition: background 0.2s;
  border-radius: 3px;
  &:hover {
    background: #f4c020 !important;
    color: #000 !important;
  }
}
.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}

.upload-col {
  padding: 0
}
.upload-row {
  padding: 0px 0px 0px 20px
}

.upload-col .v-text-field {
  padding-top: 0;
}

#upload-title {
  padding-bottom: 12px
}

.upload-cp-form {
  width: 95%;
}

.text-left {
  text-align: left;
}
</style>
