<template>
  <v-alert
    color="#f4c020"
  >
    <h3 class="text-h5">
      {{item}}
    </h3>
    <div>Are you sure you want to delete this project?</div>
    <v-row
      align="center"
      no-gutters
    >
      <v-col cols="6">
        <v-btn
          @click="deleteConfirmation()"
          data-cy="dt-delete-project-confirm"
          color="#000"
          text
        >
          Delete
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          color="#000"
          text
          @click="deleteCancel()"
        >
          Cancel
        </v-btn>
      </v-col>
    </v-row>
  </v-alert>

</template>

<script>

export default {
  name: 'ConfirmDelete',
  props: {
    item: {
      type: String,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  methods: {
    deleteConfirmation() {
      this.$emit('deleteConfirmation');
    },
    deleteCancel() {
      this.$emit('deleteCancel');
    },
  },
};
</script>
