import { mapState } from 'vuex'

export default {
  data: () => ({
    currentChanges: {}
  }),

  computed: {
    ...mapState(['DTChanges', 'connectionPoint', 'markers', 'creatingChange'])
  },

  watch: {
    DTChanges: {
      handler (val) {
        // 'CGP_New' doen't have an existant connection point, it comes from a line. The id is generated from the greatest connection point id's
        const cpId = (this.creatingChange.type === 'CGP_New') ? this.creatingChange.idElement : this.connectionPoint?.ID
        const changesEntries = Object.entries(val)
        const results = {}

        changesEntries.forEach(([type, data]) => {
          if (typeof data === 'object' && type !== 'Switch' && type !== 'Disable_Element' && type !== 'ESS') { //  && type !== 'CGP_New'
            const getIndexes = (cp, index) => (cp === cpId ? index : [])
            const getChanges = (changes) => (_, index) => changes.includes(index)
            const setEntries = (indexes) => (key) => [key, data[key].filter(getChanges(indexes))]
            const indexes = data?.CGP?.flatMap(getIndexes)
            const keys = Object.keys(data)
            const entries = keys.map(setEntries(indexes))

            results[type] = Object.fromEntries(entries)
          }
        })
        this.currentChanges = results
      },
      deep: true,
      immediate: true
    }
  }
}
