<template>
  <div>
    <span class="title yellowPlexi"> <b>Power set-up and real-time power</b></span>
    <v-divider />
    <splitpanes
      horizontal
      :push-other-panes="false"
    >
      <pane
        min-size="40"
        max-size="40"
        size="40"
      >
        <splitpanes :push-other-panes="false">
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              title="Actual power (KW)"
              :value="monitorStation[0].total_station_pwr.total_measured_pwr + ' / ' + monitorStation[0].total_station_pwr.total_aggregated_pwr"
              subtitle="Measured / Aggregated"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMonitoringKpisCardPower
              v-if="monitorStation.length>0"
              title="Maximum power (KW)"
              :value="monitorStation[0].total_station_pwr.total_max_pwr"
              :color-locked="locked.color"
              :size-locked="locked.size"
              subtitle="Locked"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMonitoringKpisCardPower
              v-if="monitorStation.length>0"
              title="Minimum power (KW)"
              :value="monitorStation[0].total_station_pwr.total_min_pwr"
              :color-locked="locked.color"
              :size-locked="locked.size"
              subtitle="Locked"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>

    <span class="title yellowPlexi"> <b>Customers log</b></span>
    <v-divider />
    <splitpanes
      horizontal
      :push-other-panes="false"
    >
      <pane
        min-size="40"
        max-size="40"
        size="40"
      >
        <splitpanes :push-other-panes="false">
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              title="Total Customers"
              :value="monitorStation[1].customers_log.total + ' / ' +
                monitorStation[1].customers_log.total_connected"
              subtitle="Registered / Connected"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              title="Non - active"
              :value="monitorStation[1].customers_log.total_na + ' / ' +
                monitorStation[1].customers_log.total_na_connected"
              subtitle="Registered / Connected"
            />
          </pane>
          <pane
            min-size="33"
            max-size="33"
            size="33"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              title="Active"
              :value="monitorStation[1].customers_log.total_a + ' / ' +
                monitorStation[1].customers_log.total_a_connected + ' / '"
              :value-real-time="monitorStation[1].customers_log.total_a_activated"
              subtitle="Reg. / Conn. / Activated"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>

    <span class="title yellowPlexi"> <b>Historical Flex</b></span>
    <v-divider />
    <splitpanes
      horizontal
      :push-other-panes="false"
    >
      <pane
        min-size="40"
        max-size="40"
        size="40"
      >
        <splitpanes :push-other-panes="false">
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[2].past_flex_kpis[0].avg_flex_activation.frontend_name+ ' (' + monitorStation[2].past_flex_kpis[0].avg_flex_activation.unit + ')'"
              :value="monitorStation[2].past_flex_kpis[0].avg_flex_activation.value "
              :subtitle="monitorStation[2].past_flex_kpis[0].avg_flex_activation.description"
            />
          </pane>
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[2].past_flex_kpis[1].max_flex_activation.frontend_name+ ' (' + monitorStation[2].past_flex_kpis[1].max_flex_activation.unit + ')'"
              :value="monitorStation[2].past_flex_kpis[1].max_flex_activation.value "
              :subtitle="monitorStation[2].past_flex_kpis[1].max_flex_activation.description"
            />
          </pane>
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[2].past_flex_kpis[2].flex_activation_duration.frontend_name+ ' (' + monitorStation[2].past_flex_kpis[2].flex_activation_duration.unit + ')'"
              :value="monitorStation[2].past_flex_kpis[2].flex_activation_duration.value "
              subtitle="Hours with flex activation "
            />
          </pane>
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[2].past_flex_kpis[3].real_violation_duration.frontend_name+ ' (' + monitorStation[2].past_flex_kpis[3].real_violation_duration.unit + ')'"
              :value="monitorStation[2].past_flex_kpis[3].real_violation_duration.value "
              subtitle="Hours with real power violation"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>

    <span class="title yellowPlexi"> <b>Forecast Flex</b></span>
    <v-divider />
    <splitpanes
      horizontal
      :push-other-panes="false"
      style=""
    >
      <pane
        min-size="40"
        max-size="40"
        size="40"
      >
        <splitpanes :push-other-panes="false">
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[3].future_flex_kpis[0].avg_violation.frontend_name+ ' (' + monitorStation[3].future_flex_kpis[0].avg_violation.unit + ')'"
              :value="monitorStation[3].future_flex_kpis[0].avg_violation.value "
              :subtitle="monitorStation[3].future_flex_kpis[0].avg_violation.description"
            />
          </pane>
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[3].future_flex_kpis[1].max_violation.frontend_name+ ' (' + monitorStation[3].future_flex_kpis[1].max_violation.unit + ')'"
              :value="monitorStation[3].future_flex_kpis[1].max_violation.value "
              :subtitle="monitorStation[3].future_flex_kpis[1].max_violation.description"
            />
          </pane>
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[3].future_flex_kpis[2].flex_requests_duration.frontend_name+ ' (' + monitorStation[3].future_flex_kpis[2].flex_requests_duration.unit + ')'"
              :value="monitorStation[3].future_flex_kpis[2].flex_requests_duration.value "
              :subtitle="monitorStation[3].future_flex_kpis[2].flex_requests_duration.description"
            />
          </pane>
          <pane
            min-size="25"
            max-size="25"
            size="25"
          >
            <TIAMonitoringKpisCard
              v-if="monitorStation.length>0"
              :title="monitorStation[3].future_flex_kpis[3].flex_deficit_duration.frontend_name+ ' (' + monitorStation[3].future_flex_kpis[3].flex_deficit_duration.unit + ')'"
              :value="monitorStation[3].future_flex_kpis[3].flex_deficit_duration.value "
              :subtitle="monitorStation[3].future_flex_kpis[3].flex_deficit_duration.description"
            />
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>
  </div>
</template>

<script>

import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import TIAMonitoringKpisCard from '@/components/TIA/TIAMonitoring/TIAMonitoringKpisCard.vue'
import TIAMonitoringKpisCardPower from '@/components/TIA/TIAMonitoring/TIAMonitoringKpisCardPower.vue'
// import TIAMonitoringKpisCardCustomer from '@/components/TIA/TIAMonitoring/TIAMonitoringKpisCardCustomer.vue'

export default {
  name: 'TIAMonitoringKpis',
  components: {
    Splitpanes,
    Pane,
    TIAMonitoringKpisCard,
    TIAMonitoringKpisCardPower
    // TIAMonitoringKpisCardCustomer

  },
  props: {
    monitorStation: {
      type: Array,
      default: () => []
    },
    customerInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    customersDetails: {
      is_active: ['x-large', 'mdi-circle'],
      activated_stations: ['Activated Stations', '#1E1E1E', '-', 'circle-status-all']
    },
    locked: {
      size: 'x-large',
      color: '#EC7063'
    }

  }),
  mounted () {
    console.log(this.monitorStation)
  }

}
</script>

<style scoped>

.title {
  text-align: center;
  font-size: 27px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 25px;
  color: rgb(195,195,195)
}
.subtitle {
  text-align: center;
  font-size: 16px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 4px;
}
.splitpanes__pane {
  display: flex;
  justify-content: center;

  color: rgba(255, 255, 255, 0.6);
  font-size: 1em;
}

</style>
