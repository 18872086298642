export default {
  methods: {
    getLocalDateFormated (offsetInDays) {
      const dateObject = new Date()
      dateObject.setDate(dateObject.getDate() - offsetInDays)
      const year = dateObject.getFullYear()
      const month = String(dateObject.getMonth() + 1).padStart(2, '0')
      const day = String(dateObject.getDate()).padStart(2, '0')

      const isoString = `${year}-${month}-${day}`

      return isoString
    }

  }
}
