import i18n from '@/plugins/i18n'

export default {
  methods: {
    async cgpKpis(dt, cases, Frame1, Frame2, idElement) {
      // op1 = 0 for actual values; op1 = 256 for pu values
      const op1 = 0
      let BusIndex
      // -----
      const KPISCGPavgPF = []
      const KPISCGPavgUnV = []
      const KPISCGPVunbRate = []
      const KPISCGPVqualFactor = []
      const KPISCGPVoverRate = []
      const KPISCGPVunderRate = []
      const KPISCGPVmin = []
      const KPISCGPVmax = []
      const KPISCGPVavg = []
      const KPISCGPSavg = []
      const KPISCGPSavgUnb = []
      const dataForKPItable = []
      function KPIsCGPavgPF(Index) {
        KPISCGPavgPF[Index] = {}
        KPISCGPavgPF[Index].KPI = i18n.t('kpi.power_factor_avg')
        KPISCGPavgPF[Index].unit = ''
        KPISCGPavgPF[Index].caseName = cases[Index].name
        KPISCGPavgPF[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCGPavgPF[Index].phase.threePhase = {}
        KPISCGPavgPF[Index].phase.threePhase.value = dt.Results.GetCgpMeanPF(cases[Index].idx, Frame1,
          Frame2, BusIndex)
        KPISCGPavgPF[Index].phase.threePhase.vioInstants = null
        KPISCGPavgPF[Index].phase.threePhase.vioCount = null
        // console.log('KPISCGPavgPF', KPISCGPavgPF);
      }
      function KPIsCGPavgUnV(Index) {
        KPISCGPavgUnV[Index] = {}
        KPISCGPavgUnV[Index].KPI = i18n.t('kpi.voltage_unbalance_avg')
        KPISCGPavgUnV[Index].unit = '%'
        KPISCGPavgUnV[Index].caseName = cases[Index].name
        KPISCGPavgUnV[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCGPavgUnV[Index].phase.threePhase = {}
        KPISCGPavgUnV[Index].phase.threePhase.value = dt.Results.GetMeanBusVu(cases[Index].idx,
          Frame1, Frame2, BusIndex)
        KPISCGPavgUnV[Index].phase.threePhase.vioInstants = null
        KPISCGPavgUnV[Index].phase.threePhase.vioCount = null
        // console.log('KPISCGPavgUnV', KPISCGPavgUnV);
      }
      function KPIsCGPVunbRate(Index) {
        KPISCGPVunbRate[Index] = {}
        KPISCGPVunbRate[Index].KPI = i18n.t('kpi.voltage_unbalance_rate')
        KPISCGPVunbRate[Index].unit = '%'
        KPISCGPVunbRate[Index].caseName = cases[Index].name
        KPISCGPVunbRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        const vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVunbRate[Index].phase.threePhase = {}
        KPISCGPVunbRate[Index].phase.threePhase.value = dt.Results.GetRateBusVu(cases[Index].idx,
          Frame1, Frame2, BusIndex, vioArray)[0]
        KPISCGPVunbRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCGPVunbRate[Index].phase.threePhase.vioCount = dt.Results.GetRateBusVu(cases[Index].idx,
          Frame1, Frame2, BusIndex, vioArray)[1]
        // console.log('KPISCGPVunbRate', KPISCGPVunbRate);
      }
      function KPIsCGPVqualFactor(Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISCGPVqualFactor[Index] = {}
        KPISCGPVqualFactor[Index].KPI = i18n.t('kpi.voltage_qualified_rate')
        KPISCGPVqualFactor[Index].unit = '%'
        KPISCGPVqualFactor[Index].caseName = cases[Index].name
        KPISCGPVqualFactor[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCGPVqualFactor[Index].phase.threePhase = {}
        KPISCGPVqualFactor[Index].phase.threePhase.value = dt.Results.GetBusQualV(cases[Index].idx,
          Frame1, Frame2, BusIndex, op)
        KPISCGPVqualFactor[Index].phase.threePhase.vioCount = null
        KPISCGPVqualFactor[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCGPVqualFactor[Index].phase.A = {}
        KPISCGPVqualFactor[Index].phase.A.value = dt.Results.GetBusPhaseQualV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 0)
        KPISCGPVqualFactor[Index].phase.A.vioCount = null
        KPISCGPVqualFactor[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCGPVqualFactor[Index].phase.B = {}
        KPISCGPVqualFactor[Index].phase.B.value = dt.Results.GetBusPhaseQualV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1)
        KPISCGPVqualFactor[Index].phase.B.vioCount = null
        KPISCGPVqualFactor[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCGPVqualFactor[Index].phase.C = {}
        KPISCGPVqualFactor[Index].phase.C.value = dt.Results.GetBusPhaseQualV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2)
        KPISCGPVqualFactor[Index].phase.C.vioCount = null
        KPISCGPVqualFactor[Index].phase.C.vioInstants = null
        // console.log('KPISCGPVqualFactor', KPISCGPVqualFactor);
      }
      function KPIsCGPVoverRate(Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISCGPVoverRate[Index] = {}
        KPISCGPVoverRate[Index].KPI = i18n.t('kpi.voltage_overvoltage')
        KPISCGPVoverRate[Index].unit = '%'
        KPISCGPVoverRate[Index].caseName = cases[Index].name
        KPISCGPVoverRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        let vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVoverRate[Index].phase.threePhase = {}
        KPISCGPVoverRate[Index].phase.threePhase.value = dt.Results.GetBusOverV(cases[Index].idx,
          Frame1, Frame2, BusIndex, op, vioArray)[0]
        KPISCGPVoverRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCGPVoverRate[Index].phase.threePhase.vioCount = dt.Results.GetBusOverV(cases[Index].idx,
          Frame1, Frame2, BusIndex, op, vioArray)[1]
        // --For phase A ---------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVoverRate[Index].phase.A = {}
        KPISCGPVoverRate[Index].phase.A.value = dt.Results.GetBusPhaseOverV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 0, vioArray)[0]
        KPISCGPVoverRate[Index].phase.A.vioInstants = vioArray
        KPISCGPVoverRate[Index].phase.A.vioCount = dt.Results
          .GetBusPhaseOverV(cases[Index].idx, Frame1, Frame2, BusIndex, 0, vioArray)[1]
        // --For phase B---------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVoverRate[Index].phase.B = {}
        KPISCGPVoverRate[Index].phase.B.value = dt.Results.GetBusPhaseOverV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1, vioArray)[0]
        KPISCGPVoverRate[Index].phase.B.vioInstants = vioArray
        KPISCGPVoverRate[Index].phase.B.vioCount = dt.Results.GetBusPhaseOverV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1, vioArray)[1]
        // ---For phase C-----------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVoverRate[Index].phase.C = {}
        KPISCGPVoverRate[Index].phase.C.value = dt.Results.GetBusPhaseOverV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2, vioArray)[0]
        KPISCGPVoverRate[Index].phase.C.vioInstants = vioArray
        KPISCGPVoverRate[Index].phase.C.vioCount = dt.Results.GetBusPhaseOverV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2, vioArray)[1]
        // console.log('KPISCGPVoverRate', KPISCGPVoverRate);
      }
      function KPIsCGPVunderRate(Index, option1) {
        const op2 = 2 // for average between 3 phases
        const op = option1 + op2
        KPISCGPVunderRate[Index] = {}
        KPISCGPVunderRate[Index].KPI = i18n.t('kpi.voltage_undervoltage')
        KPISCGPVunderRate[Index].unit = '%'
        KPISCGPVunderRate[Index].caseName = cases[Index].name
        KPISCGPVunderRate[Index].phase = {}
        // For the three phase --------------------------------------------------------
        let vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVunderRate[Index].phase.threePhase = {}
        KPISCGPVunderRate[Index].phase.threePhase.value = dt.Results.GetBusUnderV(cases[Index].idx,
          Frame1, Frame2, BusIndex, op, vioArray)[0]
        KPISCGPVunderRate[Index].phase.threePhase.vioInstants = vioArray
        KPISCGPVunderRate[Index].phase.threePhase.vioCount = dt.Results
          .GetBusUnderV(cases[Index].idx, Frame1, Frame2, BusIndex, op, vioArray)[1]
        // --For phase A ---------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVunderRate[Index].phase.A = {}
        KPISCGPVunderRate[Index].phase.A.value = dt.Results.GetBusPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 0, vioArray)[0]
        KPISCGPVunderRate[Index].phase.A.vioInstants = vioArray
        KPISCGPVunderRate[Index].phase.A.vioCount = dt.Results.GetBusPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 0, vioArray)[1]
        // --For phase B---------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVunderRate[Index].phase.B = {}
        KPISCGPVunderRate[Index].phase.B.value = dt.Results.GetBusPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1, vioArray)[0]
        KPISCGPVunderRate[Index].phase.B.vioInstants = vioArray
        KPISCGPVunderRate[Index].phase.B.vioCount = dt.Results.GetBusPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1, vioArray)[1]
        // ---For phase C-----------------------------------------------------------------------
        vioArray = new Uint32Array(Frame2 - Frame1 + 1)
        KPISCGPVunderRate[Index].phase.C = {}
        KPISCGPVunderRate[Index].phase.C.value = dt.Results.GetBusPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2, vioArray)[0]
        KPISCGPVunderRate[Index].phase.C.vioInstants = vioArray
        KPISCGPVunderRate[Index].phase.C.vioCount = dt.Results.GetBusPhaseUnderV(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2, vioArray)[1]
        // console.log('KPISCGPVunderRate', KPISCGPVunderRate);
      }
      function KPIsCGPVmin(Index, option1) {
        const op2 = 0 // for minimum between 3 phases
        const op = option1 + op2
        KPISCGPVmin[Index] = {}
        KPISCGPVmin[Index].KPI = i18n.t('kpi.voltage_min')
        KPISCGPVmin[Index].unit = 'V'
        KPISCGPVmin[Index].caseName = cases[Index].name
        KPISCGPVmin[Index].phase = {}
        // For the three  phase --------------------------------------------------------
        KPISCGPVmin[Index].phase.threePhase = {}
        KPISCGPVmin[Index].phase.threePhase.value = dt.Results.GetBusVmin(cases[Index].idx,
          Frame1, Frame2, BusIndex, op)
        KPISCGPVmin[Index].phase.threePhase.vioCount = null
        KPISCGPVmin[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCGPVmin[Index].phase.A = {}
        KPISCGPVmin[Index].phase.A.value = dt.Results.GetBusPhaseVmin(cases[Index].idx,
          Frame1, Frame2, BusIndex, 0, op)
        KPISCGPVmin[Index].phase.A.vioCount = null
        KPISCGPVmin[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCGPVmin[Index].phase.B = {}
        KPISCGPVmin[Index].phase.B.value = dt.Results.GetBusPhaseVmin(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1, op)
        KPISCGPVmin[Index].phase.B.vioCount = null
        KPISCGPVmin[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCGPVmin[Index].phase.C = {}
        KPISCGPVmin[Index].phase.C.value = dt.Results.GetBusPhaseVmin(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2, op)
        KPISCGPVmin[Index].phase.C.vioCount = null
        KPISCGPVmin[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCGPVmin[Index].phase.N = {}
        KPISCGPVmin[Index].phase.N.value = dt.Results.GetBusPhaseVmin(cases[Index].idx,
          Frame1, Frame2, BusIndex, 3, op)
        KPISCGPVmin[Index].phase.N.vioCount = null
        KPISCGPVmin[Index].phase.N.vioInstants = null
        // console.log('KPISCGPVmin', KPISCGPVmin);
      }
      function KPIsCGPVmax(Index, option1) {
        const op2 = 1 // for max between 3 phases
        const op = option1 + op2
        KPISCGPVmax[Index] = {}
        KPISCGPVmax[Index].KPI = i18n.t('kpi.voltage_max')
        KPISCGPVmax[Index].unit = 'V'
        KPISCGPVmax[Index].caseName = cases[Index].name
        KPISCGPVmax[Index].phase = {}
        // For the three  phase --------------------------------------------------------
        KPISCGPVmax[Index].phase.threePhase = {}
        KPISCGPVmax[Index].phase.threePhase.value = dt.Results.GetBusVmax(cases[Index].idx,
          Frame1, Frame2, BusIndex, op)
        KPISCGPVmax[Index].phase.threePhase.vioCount = null
        KPISCGPVmax[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCGPVmax[Index].phase.A = {}
        KPISCGPVmax[Index].phase.A.value = dt.Results.GetBusPhaseVmax(cases[Index].idx,
          Frame1, Frame2, BusIndex, 0, op)
        KPISCGPVmax[Index].phase.A.vioCount = null
        KPISCGPVmax[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCGPVmax[Index].phase.B = {}
        KPISCGPVmax[Index].phase.B.value = dt.Results.GetBusPhaseVmax(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1, op)
        KPISCGPVmax[Index].phase.B.vioCount = null
        KPISCGPVmax[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCGPVmax[Index].phase.C = {}
        KPISCGPVmax[Index].phase.C.value = dt.Results.GetBusPhaseVmax(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2, op)
        KPISCGPVmax[Index].phase.C.vioCount = null
        KPISCGPVmax[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCGPVmax[Index].phase.N = {}
        KPISCGPVmax[Index].phase.N.value = dt.Results.GetBusPhaseVmax(cases[Index].idx,
          Frame1, Frame2, BusIndex, 3, op)
        KPISCGPVmax[Index].phase.N.vioCount = null
        KPISCGPVmax[Index].phase.N.vioInstants = null
        // console.log('KPISCGPVmax', KPISCGPVmax);
      }
      function KPIsCGPVavg(Index, option1) {
        const op2 = 2 // for avg between 3 phases
        const op = option1 + op2
        KPISCGPVavg[Index] = {}
        KPISCGPVavg[Index].KPI = i18n.t('kpi.voltage_avg')
        KPISCGPVavg[Index].unit = 'V'
        KPISCGPVavg[Index].caseName = cases[Index].name
        KPISCGPVavg[Index].phase = {}
        // For the three  phase --------------------------------------------------------
        KPISCGPVavg[Index].phase.threePhase = {}
        KPISCGPVavg[Index].phase.threePhase.value = dt.Results.GetBusPhaseVavg(cases[Index].idx,
          Frame1, Frame2, BusIndex, op)
        KPISCGPVavg[Index].phase.threePhase.vioCount = null
        KPISCGPVavg[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCGPVavg[Index].phase.A = {}
        KPISCGPVavg[Index].phase.A.value = dt.Results.GetBusPhaseVavg(cases[Index].idx,
          Frame1, Frame2, BusIndex, 0, op)
        KPISCGPVavg[Index].phase.A.vioCount = null
        KPISCGPVavg[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCGPVavg[Index].phase.B = {}
        KPISCGPVavg[Index].phase.B.value = dt.Results.GetBusPhaseVavg(cases[Index].idx,
          Frame1, Frame2, BusIndex, 1, op)
        KPISCGPVavg[Index].phase.B.vioCount = null
        KPISCGPVavg[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCGPVavg[Index].phase.C = {}
        KPISCGPVavg[Index].phase.C.value = dt.Results.GetBusPhaseVavg(cases[Index].idx,
          Frame1, Frame2, BusIndex, 2, op)
        KPISCGPVavg[Index].phase.C.vioCount = null
        KPISCGPVavg[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCGPVavg[Index].phase.N = {}
        KPISCGPVavg[Index].phase.N.value = dt.Results.GetBusPhaseVavg(cases[Index].idx,
          Frame1, Frame2, BusIndex, 3, op)
        KPISCGPVavg[Index].phase.N.vioCount = null
        KPISCGPVavg[Index].phase.N.vioInstants = null
        // console.log('KPISCGPVavg', KPISCGPVavg);
      }
      function KPIsCGPSavg(Index) {
        KPISCGPSavg[Index] = {}
        KPISCGPSavg[Index].KPI = i18n.t('kpi.apparent_power_avg')
        KPISCGPSavg[Index].unit = 'kVA'
        KPISCGPSavg[Index].caseName = cases[Index].name
        KPISCGPSavg[Index].phase = {}
        // For the three phase --------------------------------------------------------
        KPISCGPSavg[Index].phase.threePhase = {}
        KPISCGPSavg[Index].phase.threePhase.value = dt.Results.GetCgpSavg(cases[Index].idx, Frame1,
          Frame2, BusIndex)
        KPISCGPSavg[Index].phase.threePhase.vioCount = null
        KPISCGPSavg[Index].phase.threePhase.vioInstants = null
        // --For phase A ---------------------------------------------------------
        KPISCGPSavg[Index].phase.A = {}
        KPISCGPSavg[Index].phase.A.value = dt.Results.GetCgpPhaseSavg(cases[Index].idx, Frame1,
          Frame2, BusIndex, 0)
        KPISCGPSavg[Index].phase.A.vioCount = null
        KPISCGPSavg[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCGPSavg[Index].phase.B = {}
        KPISCGPSavg[Index].phase.B.value = dt.Results.GetCgpPhaseSavg(cases[Index].idx, Frame1,
          Frame2, BusIndex, 1)
        KPISCGPSavg[Index].phase.B.vioCount = null
        KPISCGPSavg[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCGPSavg[Index].phase.C = {}
        KPISCGPSavg[Index].phase.C.value = dt.Results.GetCgpPhaseSavg(cases[Index].idx, Frame1,
          Frame2, BusIndex, 2)
        KPISCGPSavg[Index].phase.C.vioCount = null
        KPISCGPSavg[Index].phase.C.vioInstants = null
        // ---For the neutral ------------------------------------------------------------------
        KPISCGPSavg[Index].phase.N = {}
        KPISCGPSavg[Index].phase.N.value = dt.Results.GetCgpPhaseSavg(cases[Index].idx, Frame1,
          Frame2, BusIndex, 3)
        KPISCGPSavg[Index].phase.N.vioCount = null
        KPISCGPSavg[Index].phase.N.vioInstants = null
        // console.log('KPISCGPSavg', KPISCGPSavg);
      }
      function KPIsCGPSavgUnb(Index) {
        KPISCGPSavgUnb[Index] = {}
        KPISCGPSavgUnb[Index].KPI = i18n.t('kpi.apparent_power_phase_share')
        KPISCGPSavgUnb[Index].unit = '%'
        KPISCGPSavgUnb[Index].caseName = cases[Index].name
        KPISCGPSavgUnb[Index].phase = {}
        // --For phase A ---------------------------------------------------------
        KPISCGPSavgUnb[Index].phase.A = {}
        KPISCGPSavgUnb[Index].phase.A.value = dt.Results.GetCgpMeanPhaseUnbal(cases[Index].idx, Frame1,
          Frame2, BusIndex, 0)[1]
        KPISCGPSavgUnb[Index].phase.A.vioCount = null
        KPISCGPSavgUnb[Index].phase.A.vioInstants = null
        // --For phase B---------------------------------------------------------------------
        KPISCGPSavgUnb[Index].phase.B = {}
        KPISCGPSavgUnb[Index].phase.B.value = dt.Results.GetCgpMeanPhaseUnbal(cases[Index].idx, Frame1,
          Frame2, BusIndex, 1)[1]
        KPISCGPSavgUnb[Index].phase.B.vioCount = null
        KPISCGPSavgUnb[Index].phase.B.vioInstants = null
        // ---For phase C-----------------------------------------------------------------------
        KPISCGPSavgUnb[Index].phase.C = {}
        KPISCGPSavgUnb[Index].phase.C.value = dt.Results.GetCgpMeanPhaseUnbal(cases[Index].idx, Frame1,
          Frame2, BusIndex, 2)[1]
        KPISCGPSavgUnb[Index].phase.C.vioCount = null
        KPISCGPSavgUnb[Index].phase.C.vioInstants = null
        // console.log('KPISCGPSavgUnb', KPISCGPSavgUnb);
      }
      function orderDataForTable() {
        let temp3P = {}; let tempA = {}; let tempB = {}; let tempC = {}; let tempN = {}
        let flag3P = false; let flagA = false; let flagB = false
        let flagC = false; let flagN = false
        KPISCGPavgPF.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPavgUnV.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              // temp3P.Base *= 100;
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              // tempA.Base *= 100;
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              // tempB.Base *= 100;
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              // tempC.Base *= 100;
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              // tempN.Base *= 100;
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              // temp3P[element.caseName] *= 100;
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              // tempA[element.caseName] *= 100;
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              // tempB[element.caseName] *= 100;
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              // tempC[element.caseName] *= 100;
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              // tempN[element.caseName] *= 100;
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPVunbRate.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPVqualFactor.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPVoverRate.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPVunderRate.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPVmin.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPVmax.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPVavg.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPSavg.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 100) / 100
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 100) / 100
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
        temp3P = {}; tempA = {}; tempB = {}; tempC = {}; tempN = {}
        flag3P = false; flagA = false; flagB = false; flagC = false; flagN = false
        KPISCGPSavgUnb.forEach((element, index) => {
          if (index === 0) {
            if (element.phase.threePhase) {
              temp3P.KPI = element.KPI
              temp3P.Unit = element.unit
              temp3P.Phase = '3P'
              flag3P = true
              temp3P.Base = element.phase.threePhase.value
              temp3P.Base = Math.round(temp3P.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (element.phase.A) {
              tempA.KPI = element.KPI
              tempA.Unit = element.unit
              tempA.Phase = 'A'
              flagA = true
              tempA.Base = element.phase.A.value
              tempA.Base = Math.round(tempA.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (element.phase.B) {
              tempB.KPI = element.KPI
              tempB.Unit = element.unit
              tempB.Phase = 'B'
              flagB = true
              tempB.Base = element.phase.B.value
              tempB.Base = Math.round(tempB.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (element.phase.C) {
              tempC.KPI = element.KPI
              tempC.Unit = element.unit
              tempC.Phase = 'C'
              flagC = true
              tempC.Base = element.phase.C.value
              tempC.Base = Math.round(tempC.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (element.phase.N) {
              tempN.KPI = element.KPI
              tempN.Unit = element.unit
              tempN.Phase = 'N'
              flagN = true
              tempN.Base = element.phase.N.value
              tempN.Base = Math.round(tempN.Base * 10) / 10
              if (cases.length === 1) {
                dataForKPItable.push(tempN)
              }
            }
          } else if (index !== 0) {
            if (flag3P === true) {
              temp3P[element.caseName] = element.phase.threePhase.value
              temp3P[element.caseName] = Math.round(temp3P[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(temp3P)
              }
            }
            if (flagA === true) {
              tempA[element.caseName] = element.phase.A.value
              tempA[element.caseName] = Math.round(tempA[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempA)
              }
            }
            if (flagB === true) {
              tempB[element.caseName] = element.phase.B.value
              tempB[element.caseName] = Math.round(tempB[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempB)
              }
            }
            if (flagC === true) {
              tempC[element.caseName] = element.phase.C.value
              tempC[element.caseName] = Math.round(tempC[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempC)
              }
            }
            if (flagN === true) {
              tempN[element.caseName] = element.phase.N.value
              tempN[element.caseName] = Math.round(tempN[element.caseName] * 10) / 10
              if (index === cases.length - 1) {
                dataForKPItable.push(tempN)
              }
            }
          }
        })
      }
      for (let iter = 0; iter < cases.length; iter += 1) {
        // Initialize overall KPI function
        // eslint-disable-next-line no-await-in-loop
        // await dt.Results.KPI_init(cases[iter].idx, Frame1, Frame2)
        // this.tempdt = dt
        BusIndex = dt.Results.GetCGP2BusIndex(Number(idElement))
        // console.log('CGP Index ', BusIndex);
        // console.log('dtInitialized', this.tempdt);
        KPIsCGPavgPF(iter)
        KPIsCGPavgUnV(iter)
        KPIsCGPVunbRate(iter)
        KPIsCGPVqualFactor(iter, op1)
        KPIsCGPVoverRate(iter, op1)
        KPIsCGPVunderRate(iter, op1)
        KPIsCGPVmin(iter, op1)
        KPIsCGPVmax(iter, op1)
        KPIsCGPVavg(iter, op1)
        KPIsCGPSavg(iter)
        KPIsCGPSavgUnb(iter)
      }
      orderDataForTable()
      // console.log('dataForKPItable', dataForKPItable);
      return dataForKPItable
    }
  }
}
